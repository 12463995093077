
  <div class="row">
    <div class="medium-8 medium-offset-2 columns">
      <h2 class="subheader"></h2>
      
      <p-table #dt [value]="asyncCrisisLogs | async"
        [rows]="25" autoLayout="true"
          scrollable="true" >
        
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
              <td [attr.colspan]="12"> No records found </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>
              {{ '&nbsp;Actions' }}
            </th>
            <th>Traveler email</th>
            <th>{{ 'Last attempt to send' }}</th>
            <th>{{ 'Country of departure'}}</th>
            <th>{{ 'Country of arrival'}}</th>
            <th>{{ 'Date of departure'}}</th>
            <th>{{ 'Date of arrival' }}</th>
            <th>{{ 'Airport of departure' }}</th>
            <th>{{ 'Airport of arrival' }}</th>
            <th>{{ 'Booking number' }}</th>
            <th>{{ 'Flight number' }}</th>
            <th>{{ 'Arrival address' }}</th>
            <th>{{ 'Note' }}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">
            <td>
              <ng-template pHeader>Actions</ng-template>
              <button mat-icon-button pTooltip="Sent again" (click)="sendTravelToCrisis(rowData.travel)">
                <i class="fa fa-link" style="font-size: 24px;"></i>
              </button>
            </td>
            <td>
              <ng-template pHeader>Traveler email</ng-template>
              {{ rowData.travel.traveler_email}}
            </td>
            <td>
              <ng-template pHeader>Last attempt to send</ng-template>
              {{ rowData.travel.last_attempt_to_send}}
            </td>
            <td>
              <ng-template pHeader>Country of departure</ng-template>
              {{ rowData.departs_country?.name }}
              <!-- <a (click)="onDepartsCountryClick(rowData)" class="link">
                {{ rowData.departs_country.name }}
              </a> -->
            </td>
            <td>
              <ng-template pHeader>Country of arrival</ng-template>
              {{ rowData.arrives_country?.name }}
              <!-- <a (click)="onArrivesCountryClick(rowData)" class="link">
                {{ rowData.arrives_country.name }}
              </a> -->
            </td>
            <td>
              <ng-template pHeader>Date of departure</ng-template>
              {{ rowData.travel.departs_at.substring(0,10) }}
            </td>
            <td>
                <ng-template pHeader>Date of arrival</ng-template>
              {{ rowData.travel.arrives_at.substring(0,10) }}
            </td>
            <td>
              <ng-template pHeader>Airport of departure</ng-template>
              <a (click)="onDepartsAirportClick(rowData)" class="link">
                {{ rowData.departs_airport.id }} - {{rowData.departs_airport.name}}
              </a>
            </td>
            <td>
              <ng-template pHeader>Airport of arrival</ng-template>
              <a (click)="onArrivesAirportClick(rowData)" class="link">
                {{ rowData.arrives_airport.id }} - {{rowData.arrives_airport.name}}
              </a>
            </td>

            <td>
              <ng-template pHeader>Booking number</ng-template>
                {{ rowData.travel.booking_number }}
            </td>
            <td>
              <ng-template pHeader>Flight number</ng-template>
                {{ rowData.travel.flight_number }}
            </td>
            <td>
              <ng-template pHeader>Arrival address</ng-template>
                {{ rowData.travel.arrival_address }}
            </td>
            <td>
              <ng-template pHeader>Note</ng-template>
                {{ rowData.travel.note }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

