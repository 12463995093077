import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Company } from '../models/company';

@Injectable({providedIn: 'root'})
export class CompanyService {
    constructor(private httpClient: HttpClient, private config: AppConfig) { }

    getAllWithParams(params: any) {
        let httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get<any>(this.config.apiUrl + '/companies', {
            params: httpParams,
            observe: 'body'
        });
    }

    // Get by ID
    getCompanyById(_id: number) {
        return this.httpClient.get<any>(this.config.apiUrl + '/companies/' + _id);
    }
    
    // DELETE
    deleteCompany(_id: number) {
        return this.httpClient.delete(this.config.apiUrl + '/companies/' + _id);
    }

    // UPDATE
    updateCompany(company: Company) {
        return this.httpClient.put(this.config.apiUrl + '/companies/' + company.id, company);
    }
    
    // CREATE
    createCompany(company: Company) {
        return this.httpClient.post(this.config.apiUrl + '/companies', company);
    }

    // Company collections
    getCompanyPoles() {
        return this.httpClient.get<any>(this.config.apiUrl + '/companies/poles');
    }

    getCompanyGroups() {
        return this.httpClient.get<any>(this.config.apiUrl + '/companies/groups');
    }

    getCompanyDivisions() {
        return this.httpClient.get<any>(this.config.apiUrl + '/companies/divisions');
    }
}