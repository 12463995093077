import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-how-are-you',
    templateUrl: './how-are-you.component.html',
    styleUrls: ['./how-are-you.component.css'],
    standalone: false
})
export class HowAreYouComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
