import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import dayjs from 'dayjs';  // Correct
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
dayjs().utc();


declare var require: any;
// var MarkerWithLabel = require('markerwithlabel')(google.maps);
// import MarkerWithLabel from 'markerwithlabel';
// import * as MarkerWithLabel from 'markerwithlabel';
import { MarkerWithLabel, MarkerWithLabelOptions } from "@googlemaps/markerwithlabel";
import MarkerClusterer from '@google/markerclusterer';
import { VigiRegion } from '../models/vigiregion';
import { LocationI } from '../models/interface/locationI';
import { BasicPobInfo } from '../models/bpi';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MapService {
  clusterStyles: any[];
  bpiIndex: number = 0;
  centerLat: number;
  centerLng: number;

  private markers: any[] = [];
  setMarkers(value: any) { this.markers = value; }
  getMarkers() { return this.markers; }

  private drawnShapes: any[] = [];
  setDrawnShapes(value: any) { this.drawnShapes = value; }
  getDrawnShapes() { return this.drawnShapes; }

  private polygonDrawingEndSubject: Subject<any> = new Subject<any>();

  nullMapService() {
    this.setMarkers([]);
    this.setDrawnShapes([]);
  }

  // Drawing manager
  drawingManager: any;

  constructor(private httpClient: HttpClient) {
    this.clusterStyles = [{  //1
      url: 'assets/img/zone_circle_icons/zone35.png',
      height: 35,
      width: 35,
      opt_anchor: [16, 0],
      opt_textColor: '#888888',
      textColor: '#FFFFFF'
    },
    {
      url: 'assets/img/zone_circle_icons/zone45.png',
      height: 45,
      width: 45,
      opt_anchor: [24, 0],
      opt_textColor: '#888888',
      textColor: '#FFFFFF'
    },
    {
      url: 'assets/img/zone_circle_icons/zone55.png',
      height: 55,
      width: 55,
      opt_anchor: [32, 0],
      opt_textColor: '#888888',
      textColor: '#FFFFFF'
    }];
  }

  populateMapWithPath(map, markersInfoMap, overlays, markerCluster, bpiList, handleOverlayClick, showPobsEmails) {
    console.log(map);
    let bounds = new google.maps.LatLngBounds();
    let coords = [];
    overlays.length = 0;

    this.addBPIsMarkerAsOverlay(bpiList, coords, overlays, markersInfoMap, showPobsEmails, handleOverlayClick);
    if (overlays && overlays.length > 0) {
      // this.extendBoundsAccordingMarkers(overlays, bounds);
      this.extendBoundsWithOverlays(map, bounds);
      setTimeout(() => {
        map.fitBounds(bounds);
        map.setZoom(2);

        // Restrict zoom to a maximum of 9 if it gets too close
        // google.maps.event.addListenerOnce(map, 'zoom_changed', () => {
        //   if (map.getZoom() > 9) {
        //     map.setZoom(9);
        //   }
        // });
      }, 1000);
    }
    // this.drawPolyLineBetweenMarkers(overlays, coords);
    markerCluster = this.addOverlaysToCluster(map, overlays, markerCluster, handleOverlayClick);
    return markerCluster;
  }

  addOverlaysToCluster(map, overlays, markerCluster, handleOverlayClick) {
    if (markerCluster) {
      markerCluster.clearMarkers();
    }
    let markerOverlays = overlays.filter(x => x instanceof MarkerWithLabel);
    markerCluster = new MarkerClusterer(map, markerOverlays, {
      styles: this.clusterStyles,
      maxZoom: 13
    });

    // Add click event listener for clusters
    google.maps.event.addListener(markerCluster, 'clusterclick', (cluster) => {
      let markers = cluster.getMarkers(); // Get markers inside the clicked cluster
      console.log("Cluster clicked, containing markers:", markers);

      // Optional: Zoom in on cluster when clicked
      if (map.getZoom() < 13) {
        map.fitBounds(cluster.getBounds());
      } else {
        // If already zoomed in, trigger a click on the first marker inside the cluster
        if (markers.length > 0) {
          google.maps.event.trigger(markers[0], 'click');
        }
      }
    });

    return markerCluster;
  }

  drawPolyLineBetweenMarkers(overlays, coords) {
    // create polyline between all markers
    let polyline: google.maps.Polygon = new google.maps.Polygon({
      paths: coords,
      strokeOpacity: 0.7,
      strokeWeight: 5,
      fillColor: '#FF0000',
      fillOpacity: 0.35
    })
    overlays.push(polyline);
  }

  createCircleOverlay(region: VigiRegion): google.maps.Circle {
    let circleOptions: google.maps.CircleOptions = {
      center: new google.maps.LatLng({ lat: + region.center_lat, lng: + region.center_lon }),
      strokeColor: '#116199',
      fillColor: '#2271cc',
      fillOpacity: 0.7,
      radius: region.km * 1000
    }
    let circle: google.maps.Circle = new google.maps.Circle();
    circle.setOptions(circleOptions);
    return circle;
  }

  // extendBoundsAccordingMarkers(overlays, bounds) {
  //   // set bounds, to have all markers in the visible part of the map
  //   overlays.forEach(overlay => {
  //     if (overlay instanceof google.maps.Marker) {
  //       bounds.extend(overlay.getPosition());
  //     }
  //   });
  // }

  // extendBoundsAccordingMarkers(overlays, bounds) {
  //   overlays.forEach(overlay => {
  //     if (overlay instanceof google.maps.Marker) {
  //       bounds.extend(overlay.getPosition());
  //     } else if (overlay instanceof google.maps.Circle) {
  //       bounds.extend(overlay.getCenter());
  //     }
  //   });
  // }
  extendBoundsWithOverlays(map: google.maps.Map, bounds) {

    map.data.forEach((feature) => {
      const geometry = feature.getGeometry();
      if (geometry instanceof google.maps.Data.Point) {
        bounds.extend(geometry.get());
      }
    });

    map.overlayMapTypes.forEach((overlay) => {
      if (overlay instanceof google.maps.Marker && overlay.getPosition()) {
        bounds.extend(overlay.getPosition()!);
      } else if (overlay instanceof google.maps.marker.AdvancedMarkerElement && overlay.position) {
        bounds.extend(overlay.position);
      } else if (overlay instanceof google.maps.Circle && overlay.getCenter()) {
        bounds.extend(overlay.getCenter());
      } else if (overlay instanceof google.maps.Polygon || overlay instanceof google.maps.Polyline) {
        overlay.getPath().forEach((latLng: google.maps.LatLng) => {
          bounds.extend(latLng);
        });
      } else if (overlay instanceof google.maps.Rectangle) {
        bounds.extend(overlay.getBounds().getNorthEast());
        bounds.extend(overlay.getBounds().getSouthWest());
      }
    });

    // if (!bounds.isEmpty()) {
    //   map.fitBounds(bounds);
    // }
  }


  addVigiRegionsMarkerAsOverlay(vigiRegions: VigiRegion[], coords, oms, overlays, markersInfoMap) {
    let count = 1;
    if (!vigiRegions || vigiRegions.length == 0) {
      return;
    }
    vigiRegions.forEach(i => {
      var image = '';
      // var image = null;
      // if(i.deviceStatus) {
      //   switch (i.deviceStatus) {
      //     case DeviceStatus.ACTIVATED_STARTED_NORMAL: {
      //       if (i.memberCategory == 'EXPATRIATE') { 
      //         image = "../../../../assets/img/poi_icons/POIgreenE.png";
      //       } else {
      //         image = "../../../../assets/img/poi_icons/POIgreenT.png";
      //       }
      //       break;
      //     }
      //     case DeviceStatus.ACTIVATED_STOPED: {
      //       if (i.memberCategory == 'EXPATRIATE') { 
      //         image = "../../../../assets/img/poi_icons/POIredE.png";
      //       } else {
      //         image = "../../../../assets/img/poi_icons/POIredT.png";
      //       }
      //       break;
      //     }
      //     case DeviceStatus.DEVICES_NO_RECENT_LOCATIONS: {
      //       if (i.memberCategory == 'EXPATRIATE') {
      //         image = "../../../../assets/img/poi_icons/POIyellowE.png";
      //       } else {
      //         image = "../../../../assets/img/poi_icons/POIyellowT.png";
      //       }
      //       break;
      //     }
      //     case DeviceStatus.DEVICES_DEACTIVATED: {
      //       if (i.memberCategory == 'EXPATRIATE') {
      //         image = "../../../../assets/img/poi_icons/POIgreyE.png";
      //       } else {
      //         image = "../../../../assets/img/poi_icons/POIgreyT.png";
      //       }
      //       break;
      //     }
      //     case DeviceStatus.ACTIVATED_STARTED_QUICK: {
      //       if (i.memberCategory == 'EXPATRIATE') {
      //         image = "../../../../assets/img/poi_icons/POIorangeE.png";
      //       } else {
      //         image = "../../../../assets/img/poi_icons/POIorangeT.png";
      //       }
      //       break;
      //     }
      //     case DeviceStatus.ACTIVATED_STARTED_REAL_TIME: {
      //       if (i.memberCategory == 'EXPATRIATE') {
      //         image = "../../../../assets/img/poi_icons/POIblueE.png";
      //       } else {
      //         image = "../../../../assets/img/poi_icons/POIblueT.png";
      //       }
      //       break;
      //     }
      //     default:  "";
      //   }

      // } else {
      //   if(i.memberCategory == 'EXPATRIATE') {
      //     image = '../../../../assets/img/poi_icons/POIwhiteE.png';
      //   } else {
      //     image = '../../../../assets/img/poi_icons/POIwhiteT.png';
      //   }
      // }
      let coord: google.maps.LatLng = new google.maps.LatLng({ lat: +i.center_lat, lng: +i.center_lon });
      // keep the coords to create polyline later
      coords.push(coord);
      // This is how we would create basic google marker
      // let marker: google.maps.Marker = new google.maps.Marker({
      //   icon: image,
      //   position: {lat: +i.latitude, lng: +i.longitude},
      //   title: count + '.' + time,
      //   draggable: false,
      //   label: count + ''
      // });

      let marker = new MarkerWithLabel({
        position: { lat: +i.center_lat, lng: +i.center_lon },
        draggable: false,
        icon: image,
        labelContent: count + "." + i.name,
        labelAnchor: new google.maps.Point(22, 0),
        labelClass: "labels", // the CSS class for the label
        labelStyle: { opacity: 0.9 },
        title: "",
      } as MarkerWithLabelOptions);

      const circle = this.createCircleOverlay(i);
      overlays.push(circle);

      // add marker
      setTimeout(() => {
        oms.addMarker(marker);
      }, 500);
      overlays.push(marker);

      markersInfoMap.set(count, i);
      count++;
    });
  }

  addBPIsMarkerAsOverlay(bpiList: BasicPobInfo[], coords, overlays, markersInfoMap, showPobsEmails, handleOverlayClick) {
    let count = 1;
    if (!bpiList || bpiList.length == 0) {
      return;
    }
    bpiList.forEach(i => {
      // console.log('BPI', i);
      // console.log('Type', i.type);
      //var image = 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png';
      var image = null;
      var color = null;
      var imageTypeName = null;
      const now = Date.now();
      const timeDifference = now - i.location_time;
      const hours6InMilliSeconds = 3600000 * 6;
      const hours12InMilliSeconds = 3600000 * 12;
      const hours48InMilliSeconds = 3600000 * 48;
      if (timeDifference <= hours6InMilliSeconds) {
        color = 'green';
      } else if (timeDifference <= hours12InMilliSeconds) {
        color = 'orange';
      } else if (timeDifference <= hours48InMilliSeconds) {
        color = 'red';
      } else if (timeDifference > hours48InMilliSeconds) {
        color = 'gray';
      }
      switch (i.type) {
        case 'I_AM_FINE':
          imageTypeName = 'mapiamfineicon';
          break;
        case 'SOS_GEOLOCATION':
          imageTypeName = 'mapsosicon';
          break;
        case 'PANIC_GEOLOCATION':
          imageTypeName = 'mappanicicon';
          break;
        case 'MANUAL_CHECK_IN':
          imageTypeName = 'manual';
          break;
        case 'BACKGROUND_CHECK_IN':
          imageTypeName = 'mapbackgroundlocationicon';
          break;
        case 'COUNTRY_GEOFENCING_CHECKIN':
          imageTypeName = 'mapbackgroundlocationicon';
          break;
        case 'TRAVEL_ARRIVAL_CHECK_IN':
          imageTypeName = 'mapcheckinicon';
          break;
        case 'COUNTRY_CHANGE_DETECTION':
          imageTypeName = 'mapcheckinicon';
          break;
        default:
          imageTypeName = '';
          break;
      }

      if (!color || !imageTypeName) {
        image = null;
      } else {
        image = 'assets/img/poi_base32/' + imageTypeName + '32' + color + '.png';
      }
      let time = dayjs.utc(i.location_time).format("DD/MM/YYYY HH:mm:ss")
      let coord: google.maps.LatLng = new google.maps.LatLng({ lat: +i.latitude, lng: +i.longitude });
      // keep the coords to create polyline later
      coords.push(coord);
      // This is how we would create basic google marker
      // let marker: google.maps.Marker = new google.maps.Marker({
      //   icon: image,
      //   position: {lat: +i.latitude, lng: +i.longitude},
      //   title: count + '.' + time,
      //   draggable: false,
      //   label: count + ''
      // });

      let marker = new MarkerWithLabel({
        position: { lat: +i.latitude, lng: +i.longitude },
        draggable: false,
        icon: image,
        labelContent: count + (showPobsEmails ? ('.' + i.email) : '') + '.' + time,
        labelAnchor: new google.maps.Point(22, 0),
        labelClass: "labels", // the CSS class for the label
        labelStyle: { opacity: 0.9 },
        title: "",
      } as MarkerWithLabelOptions);

      // add 
      this.markers.push(marker);
      // Attach listener to the marker (NOT overlay)
      
      overlays.push(marker);
      markersInfoMap.set(count, i);
      count++;
      // marker.addListener('click', (event) => {
      //   handleOverlayClick.call(this, marker, event);
      // });
      google.maps.event.addListener(marker, 'click', (event) => {
        console.log("Marker clicked:", marker, event);
        handleOverlayClick(marker, event);
    });
    });
  }

  // move between markers
  moveNextLocation(vigiRegions: any[]): LocationI {
    console.log("Move next");
    if (vigiRegions != null && vigiRegions.length > 1) {
      if (this.bpiIndex < (vigiRegions.length - 1)) {
        this.bpiIndex++;
      }
      return this.setMapCenter(vigiRegions);
    }
  }

  movePrevLocation(vigiRegions: any[]): LocationI {
    console.log("Move prev");
    if (vigiRegions != null && vigiRegions.length > 1) {
      if (this.bpiIndex > 0) {
        this.bpiIndex--;
      }
      return this.setMapCenter(vigiRegions);
    }
  }

  moveToLastLocation(vigiRegions: any[]): LocationI {
    console.log("Move to Last");
    if (vigiRegions != null && vigiRegions.length > 0) {
      this.bpiIndex = vigiRegions.length - 1;
      return this.setMapCenter(vigiRegions);
    }
  }

  moveToFirstLocation(vigiRegions: any[]): LocationI {
    console.log("Move to First");
    if (vigiRegions != null && vigiRegions.length > 0) {
      this.bpiIndex = 0;
      return this.setMapCenter(vigiRegions);
    }
  }

  setMapCenter(vigiRegions: any[]): LocationI {
    this.centerLat = +vigiRegions[this.bpiIndex].center_lat;
    this.centerLng = +vigiRegions[this.bpiIndex].center_lon;
    console.log("  Lat:" + this.centerLat + " Lng:" + this.centerLng + "\n");
    return { lat: this.centerLat, lng: this.centerLng }

    //addGlobalMessage(FacesMessage.SEVERITY_INFO, "Location __" + (bpiList.length - this.currentCenterIdx) + "__" 
    //+ " Date:" + df.format(new Date(bpiList.get(this.currentCenterIdx).getLocationTime())));
  }

  // ========================= draw poligon =======================
  initDrawingManager(map: any): void {
    // Initialize drawing manager
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON]
      }
    });
    this.drawingManager.setMap(map);
    google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {
      this.drawnShapes.push(event.overlay);
      if (
        event.type == google.maps.drawing.OverlayType.POLYGON
        ||
        event.type == google.maps.drawing.OverlayType.RECTANGLE
      ) {
        const polygon = event.overlay;
        const polygonBounds = polygon.getPath().getArray();

        // Filter markers within polygon bounds
        const markersInsidePolygon = this.markers.filter(marker => google.maps.geometry.poly.containsLocation(marker.getPosition(), polygon));
        this.polygonDrawingEndSubject.next(markersInsidePolygon); // Emit the result
      }
    });
  }

  deactivateDrawingManager() {
    this.drawingManager.setDrawingMode(null);
  }

  clearDrawingManager() {
    this.drawingManager.setMap(null); // Remove the drawing manager
    for (let i = 0; i < this.drawnShapes.length; i++) {
      this.drawnShapes[i].setMap(null); // Remove each drawn shape from the map
    }
    this.drawnShapes = []; // Clear the array
  }

  getPolygonDrawingEndObservable(): Observable<any> {
    return this.polygonDrawingEndSubject.asObservable();
  }

}
