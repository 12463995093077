<p-tabs value="0">
  <p-tablist>
    <p-tab value="0">Groups</p-tab>
    <p-tab value="1">Countries</p-tab>
    <p-tab value="2">Devices</p-tab>
  </p-tablist>
  <p-tabpanels>
    <p-tabpanel value="0">
      <!-- fxLayout.lt-md="column" -->
      <div fxFlex="100" fxFlexFill fxLayout="row">
        <div fxFlex="98" fx></div>
        <div fxFlex="2" fx>
          <button mat-icon-button (click)="exportGroupStatsCSV()">
            <fa-icon [icon]="faFileCsv" style="font-size: 40px;"></fa-icon>
          </button>
        </div>
      </div>
      
      <div fxFlex="100" fxFlexFill fxLayout.lt-md="column" fxLayout="row">
        <div fxFlex="33" fx>
          <h3 style="text-align: center;">Groups</h3>
          <p-chart type="pie" [data]="groupsData" (onDataSelect)="groupChartDataSelect($event)" 
            [options]="getOptions(groupsData)"> 
          </p-chart>
        </div>
        <div fxFlex="33">
          <h3 style="text-align: center;">Poles</h3>
          <p-chart type="pie" [data]="polesData" (onDataSelect)="polesChartDataSelect($event)"
              [options]="getOptions(polesData)"> 
            </p-chart>
        </div>
        <div fxFlex="33">
          <h3 style="text-align: center;">Divisions</h3>
          <p-chart type="pie" [data]="divisionsData" (onDataSelect)="divisionsChartDataSelect($event)"
              [options]="getOptions(divisionsData)"> 
            </p-chart>
        </div>
      </div>
    </p-tabpanel>
    <p-tabpanel value="1">
      <p-dropdown [options]="countriesSI"
        [(ngModel)]="selectedCountryId" (onChange)="onCountryChange()" 
        placeholder="Select country">
      </p-dropdown>
  
      <p-tabs value="0">
        <p-tablist>
          <p-tab value="0">
            Country subscribed users
            <button mat-icon-button pTooltip="Show info" 
              (click)="showCountrySubscribedUsersInfo()">
              <i class="fa fa-info-circle" style="font-size: 24px;"></i>
            </button>
          </p-tab>
          <p-tab value="1">
            Country alerted users
            <button mat-icon-button pTooltip="Show info" 
              (click)="showCountryAlertedUsersInfo()">
              <i class="fa fa-info-circle" style="font-size: 24px;"></i>
            </button>
          </p-tab>
        </p-tablist>
        <p-tabpanels>
          <p-tabpanel value="0" >
            <p-table #dt id="country_subscribed_users" 
            [value]="countrySubscribedUsers" 
            autoLayout="true" [columns]="cols"
            dataKey="id"   scrollable="true" >
            <ng-template pTemplate="caption" style=" text-align: right;">
              <button mat-icon-button (click)="exportAllCountrySubscribedUsersCSV()"
                pTooltip="All countries user subscriptions" >
                <fa-icon [icon]="faFileCsv" style="font-size: 40px;"></fa-icon>
              </button>
              <button mat-icon-button (click)="exportCountrySubscribedUsersCSV()"
                pTooltip="Selected country user subscriptions" >
                <fa-icon [icon]="faFileCsv" style="font-size: 40px;"></fa-icon>
              </button>
              <button mat-icon-button (click)="exportCountrySubscribedUsersPdf()"
                pTooltip="Selected country user subscriptions" >
                <fa-icon [icon]="faFilePdf" style="font-size: 40px;"></fa-icon>
              </button>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns">
                    {{col.header}}
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td *ngFor="let col of columns">
                  <span *ngIf="col.has_relation && rowData[col.field]">
                    {{rowData[col.field][col.field2]}}
                  </span>
                  <span *ngIf="!col.has_relation">{{rowData[col.field]}}</span>
                  <span *ngIf="!rowData[col.field]">
                    <span *ngIf="col.has_alternative_field">
                      {{rowData[col.alternative_field][col.field2]}}
                    </span>
                  </span>
                </td>
              </tr>
            </ng-template>
            </p-table>
          </p-tabpanel>
          <p-tabpanel value="1" >
            <p-table #dt id="users_table" 
            [value]="countryAlertedUsers | async" 
            [paginator]="true" 
            [rowsPerPageOptions]="[25, 50, 100, 250, 500]"
            paginatorPosition="both" [rows]="25" autoLayout="true" 
            [lazy]="true" currentPageReportTemplate="Showing {count} entries" 
            (onLazyLoad)="loadCountryAlertedUsersLazy($event)"
            dataKey="id" scrollable="true"
            [totalRecords]="count"
            >
              <ng-template pTemplate="caption">
                <button mat-icon-button (click)="downloadCountryAlertedUsersCSV()"
                  pTooltip="Selected country alerted user" >
                  <fa-icon [icon]="faFileCsv" style="font-size: 40px;"></fa-icon>
                </button>
                <button mat-icon-button (click)="exportCountryAlertedUsersPdf()"
                  pTooltip="Selected country alerted user" >
                <fa-icon [icon]="faFilePdf" style="font-size: 40px;"></fa-icon>
              </button>
              </ng-template>
              
              <ng-template pTemplate="header">
                <tr>
                  <th [pSortableColumn]="'date_created'">{{ 'Date created (GMT)'}}<p-sortIcon [field]="'date_created'"></p-sortIcon></th>
                  <th [pSortableColumn]="'phone'">{{ 'Cell number'}}<p-sortIcon [field]="'phone'"></p-sortIcon></th>
                  <th [pSortableColumn]="'email'">{{ 'Email'}}<p-sortIcon [field]="'email'"></p-sortIcon></th>
                  <th>{{ 'Id. Pr. Email'}}</th>
                  <th>{{ 'Group'}}</th>
                  <th>{{ 'Pole'}}</th>
                  <th>{{ 'Division'}}</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData" [ngClass]="{'deleted_flag': rowData.deleted === true}">
                  <td>
                    <ng-template pHeader>Date created (GMT)</ng-template>
                    {{ rowData.date_created }}
                  </td>
                  <td>
                    <ng-template pHeader>Cell number</ng-template>
                    {{ rowData.phone }}</td>
                  <td>
                    <ng-template pHeader>Email</ng-template>
                    {{ rowData.email }}
                  </td>
                  <td>
                    <ng-template pHeader>Id. Pr. Email</ng-template>
                    {{ rowData.email_identity_provider }}
                  </td>
                  <td>
                    <ng-template pHeader>Group</ng-template>
                    <span>{{ rowData.group?.name_en }}</span>
                    <span>{{ rowData.sponsor_group?.name_en }}</span>
                  </td>
                  <td>
                    <ng-template pHeader>Pole</ng-template>
                    <span>{{ rowData.pole?.name_en }}</span>
                    <span>{{ rowData.sponsor_pole?.name_en }}</span>
                  </td>
                  <td>
                    <ng-template pHeader>Division</ng-template>
                    {{ rowData.division?.name_en}}
                  </td>
                </tr>
              </ng-template>
            </p-table>
    
          </p-tabpanel>
        </p-tabpanels>
        
      </p-tabs>
    </p-tabpanel>
    <p-tabpanel value="2">
      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="10px">
        <!-- OS, OS version, count - stats table -->
        <p-table 
        #osversion_dt [columns]="osversion_dt_columns"
        [value]="os_v_stats" responsiveLayout="scroll">
          <ng-template pTemplate="header">
            <tr>
              <th colspan="3">
                OS versions 
                <button mat-icon-button (click)="osversion_dt.exportCSV()">
                  <fa-icon [icon]="faFileCsv" style="font-size: 30px;"></fa-icon>
                </button>
              </th>
            </tr>
            <tr>
                <th>OS</th>
                <th>OS version</th>
                <th>Count</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row>
              <tr>
                  <td>{{row.os}}</td>
                  <td>{{row.os_v}}</td>
                  <td>{{row.count}}</td>
              </tr>
          </ng-template>
        </p-table>
        
        <!-- OS, device type, count - stats table -->
        <p-table 
        #data_types_dt [columns]="data_types_dt_columns"
        [value]="d_t_stats" responsiveLayout="scroll">
          <ng-template pTemplate="header">
            <tr>
              <th colspan="3">
                Device types
              <button mat-icon-button (click)="data_types_dt.exportCSV()">
                <fa-icon [icon]="faFileCsv" style="font-size: 30px;"></fa-icon>
              </button>
              </th>
            </tr>
            <tr>
                <th>OS</th>
                <th>Device type</th>
                <th>Count</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row>
              <tr>
                  <td>{{row.os}}</td>
                  <td>{{row.d_t}}</td>
                  <td>{{row.count}}</td>
              </tr>
          </ng-template>
        </p-table>
  
        <!-- OS, app version, count - stats table -->
        <!-- <p-table 
        #os_app_v_dt [columns]="os_app_v_dt_columns"
        [value]="os_app_v_stats" responsiveLayout="scroll">
          <ng-template pTemplate="header">
            <tr>
              <th colspan="3">
                OS / app version
              <button mat-icon-button (click)="os_app_v_dt.exportCSV()">
                <fa-icon [icon]="faFileCsv" style="font-size: 30px;"></fa-icon>
              </button>
              </th>
            </tr>
            <tr>
                <th>OS</th>
                <th>App version</th>
                <th>Count</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row>
              <tr>
                  <td>{{row.os}}</td>
                  <td>{{row.app_v}}</td>
                  <td>{{row.count}}</td>
              </tr>
          </ng-template>
        </p-table> -->
  
        <!-- OS version, app version, count - stats table -->
        <!-- <p-table 
        #os_version_app_v_dt [columns]="os_version_app_v_dt_columns"
        [value]="os_v_app_v_stats" responsiveLayout="scroll">
          <ng-template pTemplate="header">
            <tr>
              <th colspan="3">
                OS version / app version
              <button mat-icon-button (click)="os_version_app_v_dt.exportCSV()">
                <fa-icon [icon]="faFileCsv" style="font-size: 30px;"></fa-icon>
              </button>
              </th>
            </tr>
            <tr>
                <th>OS Version</th>
                <th>App version</th>
                <th>Count</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row>
              <tr>
                  <td>{{row.os_v}}</td>
                  <td>{{row.app_v}}</td>
                  <td>{{row.count}}</td>
              </tr>
          </ng-template>
        </p-table> -->
  
        <p-table 
        #os_v_app_v_dt [columns]="os_v_app_v_stats_columns"
        [value]="os_osv_app_v_stats" responsiveLayout="scroll">
          <ng-template pTemplate="header">
            <tr>
              <th colspan="4">
                OS version / app version
              <button mat-icon-button (click)="os_v_app_v_dt.exportCSV()">
                <fa-icon [icon]="faFileCsv" style="font-size: 30px;"></fa-icon>
              </button>
              </th>
            </tr>
            <tr>
                <th>OS</th>
                <th>OS Version</th>
                <th>App version</th>
                <th>Count</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row>
              <tr>
                  <td>{{row.os}}</td>
                  <td>{{row.os_v}}</td>
                  <td>{{row.app_v}}</td>
                  <td>{{row.count}}</td>
              </tr>
          </ng-template>
        </p-table>
  
        <!-- App version, count - stats table -->
        <p-table 
        #app_v_dt [columns]="app_v_dt_columns"
        [value]="app_v_stats" responsiveLayout="scroll">
          <ng-template pTemplate="header">
            <tr>
              <th colspan="2">
                App versions count
                <button mat-icon-button (click)="app_v_dt.exportCSV()">
                  <fa-icon [icon]="faFileCsv" style="font-size: 30px;"></fa-icon>
                </button>
              </th>
            </tr>
            <tr>
                <th>App version</th>
                <th>Count</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row>
              <tr>
                  <td>{{row.app_v}}</td>
                  <td>{{row.count}}</td>
              </tr>
          </ng-template>
        </p-table>
      </div>
    </p-tabpanel>
  </p-tabpanels>

</p-tabs>
