
  <button pButton type="button" label="Corporate" *ngIf="!itIsSingleUserPage"
    [ngClass]="{'p-button-secondary': ((showCorporateUsersTable == 0)? true: false)}"
    (click)="showCorporateUsers()">
  </button>
  <button pButton type="button" label="Sponsored" *ngIf="!itIsSingleUserPage"
    [ngClass]="{'p-button-secondary': ((showCorporateUsersTable == 1)? true: false)}"
    (click)="showSponsoredUsers()">
  </button>


  <div class="row">
    <div class="medium-8 medium-offset-2 columns">
      <h2 class="subheader"></h2>

      <p-card header="User info" [style]="{width: '360px'}" [hidden]="!itIsSingleUserPage">
        <div>
          <label><b>Given name:</b></label>
           {{ user?.firstname }}
          <br />
          <label><b>Surname:</b></label>
           {{ user?.lastname }}
          <br />
          <label><b>Email:</b></label>
          {{ user?.email }}
        </div>
      </p-card>
      
      <p-table #dt [value]="asyncTravels | async" [paginator]="true" [rowsPerPageOptions]="[10, 25, 50]"
        paginatorPosition="both" [rows]="10" autoLayout="true" [lazy]="true" currentPageReportTemplate="Showing {count} entries" 
        (onLazyLoad)="loadTransactionsLazy($event)"   scrollable="true" 
        [totalRecords]="count">
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
              <td [attr.colspan]="6"> No records found </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="caption" style="text-align: right;">
          Global travel list
          <button mat-icon-button (click)="downloadTravelsCSV()" >
            <fa-icon [icon]="faFileCsv" style="font-size: 40px;"></fa-icon>
          </button>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th [hidden]="itIsSingleUserPage" [pSortableColumn]="'firstname'">{{ 'Given name'}}
              <p-sortIcon [field]="'firstname'"></p-sortIcon>
            </th>
            <th [hidden]="itIsSingleUserPage" [pSortableColumn]="'lastname'">{{ 'Surname'}}
              <p-sortIcon [field]="'lastname'"></p-sortIcon>
            </th>
            <th [hidden]="itIsSingleUserPage" [pSortableColumn]="'email'">{{ 'Email'}}
              <p-sortIcon [field]="'email'"></p-sortIcon>
            </th>
            <th>{{ 'Country of departure'}}</th>
            <th>{{ 'Country of arrival'}}</th>
            <th [pSortableColumn]="'departs_at'">{{ 'Date of departure'}}<p-sortIcon [field]="'departs_at'"></p-sortIcon></th>
            <th>{{ 'Date of arrival' }}</th>
            <th>{{ 'Airport of departure' }}</th>
            <th>{{ 'Stop1' }}</th>
            <th>{{ 'Stop2' }}</th>
            <th>{{ 'Stop3' }}</th>
            <th>{{ 'Stop4' }}</th>
            <th>{{ 'Airport of arrival' }}</th>
            <th>{{ 'Booking number' }}</th>
            <th>{{ 'Flight number' }}</th>
            <th>{{ 'Arrival address' }}</th>
            <th>{{ 'Note' }}</th>
            <th>{{ 'Sent to Crisis' }}</th>
          </tr>
          <tr>
            <th [hidden]="itIsSingleUserPage">
              <input pInputText type="text" (input)="dt.filter($event.target.value, 'firstname', 'equals')">
            </th>
            <th [hidden]="itIsSingleUserPage">
              <input pInputText type="text" (input)="dt.filter($event.target.value, 'lastname', 'equals')">
            </th>
            <th [hidden]="itIsSingleUserPage">
              <input pInputText type="text" (input)="dt.filter($event.target.value, 'email', 'equals')">
            </th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">
            <td [hidden]="itIsSingleUserPage">
              <ng-template pHeader>Given name</ng-template>
              {{ rowData?.mobile_user?.firstname }}
            </td>
            <td [hidden]="itIsSingleUserPage">
              <ng-template pHeader>Surname</ng-template>
              {{ rowData?.mobile_user?.lastname }}
            </td>
            <td [hidden]="itIsSingleUserPage">
              <ng-template pHeader>VTS Email</ng-template>
              {{ rowData?.mobile_user?.email }}
            </td>
            <td>
              <ng-template pHeader>Country of departure</ng-template>
              {{ rowData.departs_country.name }}
              <!-- <a (click)="onDepartsCountryClick(rowData)" class="link">
                {{ rowData.departs_country.name }}
              </a> -->
            </td>
            <td>
              <ng-template pHeader>Country of arrival</ng-template>
              {{ rowData.arrives_country.name }}
              <!-- <a (click)="onArrivesCountryClick(rowData)" class="link">
                {{ rowData.arrives_country.name }}
              </a> -->
            </td>
            <td>
              <ng-template pHeader>Date of departure</ng-template>
              {{ rowData.travel.departs_at.substring(0,10) }}
            </td>
            <td>
                <ng-template pHeader>Date of arrival</ng-template>
              {{ rowData.travel.arrives_at.substring(0,10) }}
            </td>
            <td>
              <ng-template pHeader>Airport of departure</ng-template>
              <a (click)="onAirportClick(rowData.departs_airport, rowData)" 
              class="link">
                {{ rowData.departs_airport.id }} - {{rowData.departs_airport.name}}
              </a>
            </td>
            <td>
              <ng-template pHeader>Stop1</ng-template>
              <a (click)="onAirportClick(rowData.stop_1_airport, rowData)" 
              class="link" *ngIf="rowData.stop_1_airport.id">
                {{ rowData.stop_1_airport.id }} - {{rowData.stop_1_airport.name}}
              </a>
              <span *ngIf="!rowData.stop_1_airport.id">N/A</span>
            </td>
            <td>
              <ng-template pHeader>Stop2</ng-template>
              <a (click)="onAirportClick(rowData.stop_2_airport, rowData)" 
              class="link" *ngIf="rowData.stop_2_airport.id">
                {{ rowData.stop_2_airport.id }} - {{rowData.stop_2_airport.name}}
              </a>
              <span *ngIf="!rowData.stop_2_airport.id">N/A</span>
            </td>
            <td>
              <ng-template pHeader>Stop3</ng-template>
              <a (click)="onAirportClick(rowData.stop_3_airport, rowData)" 
              class="link" *ngIf="rowData.stop_3_airport.id">
                {{ rowData.stop_3_airport.id }} - {{rowData.stop_3_airport.name}}
              </a>
              <span *ngIf="!rowData.stop_3_airport.id">N/A</span>
            </td>
            <td>
              <ng-template pHeader>Stop4</ng-template>
              <a (click)="onAirportClick(rowData.stop_4_airport, rowData)" 
              class="link" *ngIf="rowData.stop_4_airport.id">
                {{ rowData.stop_4_airport.id }} - {{rowData.stop_4_airport.name}}
              </a>
              <span *ngIf="!rowData.stop_4_airport.id">N/A</span> 
            </td>
            <td>
              <ng-template pHeader>Airport of arrival</ng-template>
              <a (click)="onAirportClick(rowData.arrives_airport, rowData)" class="link">
                {{ rowData.arrives_airport.id }} - {{rowData.arrives_airport.name}}
              </a>
            </td>
            <td>
              <ng-template pHeader>Booking number</ng-template>
                {{ rowData.travel.booking_number }}
            </td>
            <td>
              <ng-template pHeader>Flight number</ng-template>
                {{ rowData.travel.flight_number }}
            </td>
            <td>
              <ng-template pHeader>Arrival address</ng-template>
                {{ rowData.travel.arrival_address }}
            </td>
            <td>
              <ng-template pHeader>Note</ng-template>
                {{ rowData.travel.note }}
            </td>
            <td>
              <ng-template pHeader>Sent to Crisis</ng-template>
                {{ (rowData.travel.is_sent_to_crisis)? 'Yes': 'No' }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>


<p-dialog header="Location" [(visible)]="displayDialog"
  [style]="{width: '600px', height: '400px'}">
  <div id="googleMap" style="width: 600px; height: 400px;"></div>
</p-dialog>
