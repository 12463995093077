import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Group } from '../models/group';

@Injectable({providedIn: 'root'})
export class GroupService {
    constructor(private httpClient: HttpClient, private config: AppConfig) { }

    getAllGroups() {
        return this.httpClient.get<any>(this.config.apiUrl + '/groups/all');
    }

    getAllAccessibleGroups() {
        return this.httpClient.get<any>(this.config.apiUrl + '/groups/all_accessible');
    }

    getAllAccessibleGroupsWithInner() {
        return this.httpClient.get<any>(this.config.apiUrl + '/groups/all_accessible/with_inner');
    }

    // Get by ID
    getGroupById(_id: number) {
        return this.httpClient.get<any>(this.config.apiUrl + '/groups/' + _id);
    }

    getCountOfUsersForGroups(groupIds: number[]) {
        return this.httpClient.post<any>(this.config.apiUrl + '/groups/stats_for_users', {'group_ids': groupIds});
    }
    
    // DELETE
    deleteGroup(_id: number) {
        return this.httpClient.delete(this.config.apiUrl + '/groups/' + _id);
    }

    // UPDATE
    updateGroup(group: Group) {
        return this.httpClient.put(this.config.apiUrl + '/groups/' + group.id, group);
    }
    
    // CREATE
    createGroup(group: Group) {
        return this.httpClient.post(this.config.apiUrl + '/groups', group);
    }

    // Download all groups, division and pole names into csv
    downloadUsersCSV() {
        return this.httpClient.get(this.config.apiUrl + '/groups/csv', {
            headers: new HttpHeaders().set('Accept', 'text/csv' ),
            observe: 'response',
            responseType: 'blob'
        });
    }

    exportAccessibleGroupsCSV(){
        return this.httpClient.get(this.config.apiUrl 
            + '/groups/all_accessible/with_inner/csv' 
            ,{
                headers: new HttpHeaders().set('Accept', 'text/csv' ),
                observe: 'response',
                responseType: 'blob'
            }
        );
    }
}
