import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { BackendUserService } from '../../../models_services/backend_user.service';
import { BackendUser } from '../../../models/backend-user';
import { Subscription } from 'rxjs';
import { FormArray, UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder, AbstractControl } from '@angular/forms';
import { GrowlService } from '../../../_module_dialog/growl_dialog/growl.service';
import { PhoneWithPlusAndSpaceValidator } from '../../../_helpers/validators/phone-number-with-plus-and-space';
import { SelectItem } from 'primeng/api';
import { GroupService } from '../../../models_services/group.service';
import { PoleService } from '../../../models_services/pole.service';
import { DivisionService } from '../../../models_services/division.service';

@Component({
    selector: 'app-backend-user-edit',
    templateUrl: 'backend-user-edit.component.html',
    styles: [],
    standalone: false
})
export class BackendUserEditComponent implements OnInit, OnDestroy {
  currentUser: BackendUser;
  user: BackendUser = new BackendUser();
  subscription: Subscription;
  userForm: UntypedFormGroup = new UntypedFormGroup({});
  isNew = true;
  passwordDivision: AbstractControl;
  roles = [
  ]
  groupsSelectItems: SelectItem[] = [];
  polesSelectItems: SelectItem[] = [];
  divisionSelectItems: SelectItem[] = [];
  renderGroupsDropdown = false;
  renderPolesDropdown = false;
  renderDivisionDropdown = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: BackendUserService,
    private fromBuilder: UntypedFormBuilder,
    private growlService: GrowlService,
    private groupService: GroupService,
    private poleService: PoleService,
    private divisionService: DivisionService) {
      this.currentUser = JSON.parse(localStorage.getItem('current_user'));
    }

  ngOnInit() {
    this.initForm();
    this.loadTheUser();
    this.loadRoles();
    this.loadCollectionsAccordingUserType();
  }

  onRoleChange() {
    this.loadCollectionsAccordingUserType();
  }

  onGroupChange(){
    this.userForm.get('pole_id').patchValue(null);
    this.userForm.get('division_id').patchValue(null);
    this.loadAllAccessiblePoles(true, null);
  }

  onPoleChange(){
    this.userForm.get('division_id').patchValue(null);
    this.loadAllAccessibleDivisions();
  }

  onDivisionChange(){
  }

  loadCollectionsAccordingUserType() {
    this.renderGroupsDropdown = false;
    this.renderPolesDropdown = false;
    this.renderDivisionDropdown = false;
    let role = this.userForm.get('role').value;
    if (this.currentUser.role == 'MASTER_ADMIN') {
      switch (role) {
        case 'GROUP_ADMIN':
          this.renderGroupsDropdown = true;
          this.loadGroups(true);
          break;
        case 'POLE_ADMIN':
          this.renderGroupsDropdown = true;
          this.renderPolesDropdown = true;
          this.loadAllAccessiblePoles(true, null);
          break;
        case 'DIVISION_ADMIN':
          this.renderGroupsDropdown = true;
          this.renderPolesDropdown = true;
          this.renderDivisionDropdown = true;
          if (this.userForm.get('pole_id').value) {
            this.loadAllAccessibleDivisions();
          } else {
            this.loadAllAccessiblePoles(true, null);
          }
          break;
        default:
          break;
      }
    } else {
      switch (role) {
        case 'POLE_ADMIN':
          this.renderPolesDropdown = true;
          this.loadAllAccessiblePoles(true, null);
          break;
        case 'DIVISION_ADMIN':
          this.renderDivisionDropdown = true;
          this.loadAllAccessibleDivisions();
          break;
        default:
          break;
      }
    }
    this.updateFormValidationForDropdowns(role);
  }

  updateFormValidationForDropdowns(role: string) {
    const groupIdControl = this.userForm.get('group_id');
    const poleIdControl = this.userForm.get('pole_id');
    const divisionIdControl = this.userForm.get('division_id');
    groupIdControl.setValidators(null);
    poleIdControl.setValidators(null);
    divisionIdControl.setValidators(null);
    if (this.renderGroupsDropdown) {
      groupIdControl.setValidators([Validators.required]);
    }
    if (this.renderPolesDropdown) {
      poleIdControl.setValidators([Validators.required]);
    }
    if (this.renderDivisionDropdown) {
      divisionIdControl.setValidators([Validators.required]);
    }
    groupIdControl.updateValueAndValidity();
    poleIdControl.updateValueAndValidity();
    divisionIdControl.updateValueAndValidity();
  }

  loadTheUser() {
    this.subscription = this.route.params.subscribe(
      (params: Params) => {
        if (params.hasOwnProperty('id')) {
          this.isNew = false;
          this.userService.getById(params['id']).subscribe(data => {
            console.log(data.user);
            this.user = data.user;
            if (this.user != null) {
              this.initForm();
              let role = this.userForm.get('role').value;
              switch (role) {
                case 'DIVISION_ADMIN':
                  this.renderDivisionDropdown = true;
                  this.renderPolesDropdown = false;
                  this.renderGroupsDropdown = false;
                  this.loadAllAccessibleDivisions();
                  break;
                case 'POLE_ADMIN':
                  this.renderDivisionDropdown = false;
                  this.renderPolesDropdown = true;
                  this.renderGroupsDropdown = false;
                  this.loadAllAccessiblePoles(false, this.user.id);
                  break;
                case 'GROUP_ADMIN':
                  this.renderDivisionDropdown = false;
                  this.renderPolesDropdown = false;
                  this.renderGroupsDropdown = true;
                  this.loadGroups(false);
                  break;
              }
            }
          });
        }
      }
    );
  }

  loadRoles(){
    const currentUserRole = this.currentUser.role;
    switch (currentUserRole) {
      case 'MASTER_ADMIN':
        // only master admin can add the three types
        this.roles = [
          {label: 'Group admin' , value: 'GROUP_ADMIN'},
          {label: 'Pole admin' , value: 'POLE_ADMIN'},
          {label: 'Division admin' , value: 'DIVISION_ADMIN'},
        ]
        break;
      case 'GROUP_ADMIN':
        // group admin can create only 
        this.roles = [
          {label: 'Pole admin' , value: 'POLE_ADMIN'},
        ]
        break;
      case 'POLE_ADMIN':
        this.roles = [
          {label: 'Division admin' , value: 'DIVISION_ADMIN'},
        ]
        break;
      case 'DIVISION_ADMIN':
        // division admin cannot add new admins.
        break;
      default:
        break;
    }
  }

  loadGroups(clearChildDropdowns){
    // Only master admin can create group admins
    // Group admins cannot create other group admins.
    if (clearChildDropdowns) {
      this.userForm.get('pole_id').patchValue(null);
      this.userForm.get('division_id').patchValue(null);
    }

    this.groupService.getAllAccessibleGroups().subscribe(
      (data) => {
        this.groupsSelectItems = [];
        if (data.groups) {
          this.groupsSelectItems.push({label:'Select group...', value: null});
          data.groups.forEach(group => {
            this.groupsSelectItems.push({label:group.name_en, value: group.id});
          });
        }
      }
    );
  }

  loadAllAccessiblePoles(clearChildDropdowns, editedUserId){
    // Only group admins and master admin can create pole admins
    if (clearChildDropdowns) {
      this.userForm.get('division_id').patchValue(null);
    }
    
    let groupId = this.userForm.get('group_id').value;
    if (!groupId) {
      groupId = 0;
    }
    this.poleService.getAllAccessiblePoles(groupId, editedUserId).subscribe(
      (data) => {
        this.polesSelectItems = [];
        if (data.poles) {
          this.polesSelectItems.push({label:'Select pole...', value: null});
          data.poles.forEach(pole => {
            this.polesSelectItems.push({label: pole.name_en, value: pole.id});
          });
        }
      }
    );
  }

  loadAllAccessibleDivisions(){
    // Only division admins cannot create other division admins
    let poleId = this.userForm.get('pole_id').value;
    if (!poleId) {
      poleId = 0;
    }
    this.divisionService.getAllAccessibleDivisions(poleId, 0).subscribe(
      (data) => {
        this.divisionSelectItems = [];
        if (data.divisions) {
          this.divisionSelectItems.push({label:'Select division...', value: null});
          data.divisions.forEach(division => {
            this.divisionSelectItems.push({label: division.name_en, value: division.id});
          });
        }
      }
    );
  }

  private initForm() {
    let role = null;
    if (this.currentUser.role == 'MASTER_ADMIN'){
      role = this.user.role ? this.user.role: 'GROUP_ADMIN';
    } else {
      // Group admin can create pole admin
      // and pole admin can create division admin
      if (this.currentUser.role == 'GROUP_ADMIN') {
        role = this.user.role ? this.user.role: 'POLE_ADMIN';
      } else if (this.currentUser.role == 'POLE_ADMIN') {
        role = this.user.role ? this.user.role: 'DIVISION_ADMIN';
      }
    }

    let divisionObject = <any>{};
    divisionObject = {
      firstname: new UntypedFormControl(this.user.firstname, Validators.required),
      lastname: new UntypedFormControl(this.user.lastname, Validators.required),
      phone: new UntypedFormControl(this.user.phone, [Validators.required, PhoneWithPlusAndSpaceValidator()]),
      email: new UntypedFormControl(this.user.email, [Validators.required, Validators.email]),
      role: new UntypedFormControl(role, [Validators.required]),
      group_id: new UntypedFormControl(this.user.group_id, []),
      pole_id: new UntypedFormControl(this.user.pole_id, []),
      division_id: new UntypedFormControl(this.user.division_id, []),
      passwords: new UntypedFormGroup({
        password: new UntypedFormControl('', (this.isNew) ? Validators.required : []),
        confirm: new UntypedFormControl('', (this.isNew) ? Validators.required : []),
      }, this.passwordMatcher)
    };
    this.userForm = new UntypedFormGroup(divisionObject);
  }

  // FormGroups extend AbstractControl
  passwordMatcher = (divisionControl: AbstractControl): { [key: string]: boolean } => {
    // let password = division.controls.password;
    // let confirm = division.controls.confirm;
    const password = divisionControl.get('password');
    const confirm = divisionControl.get('confirm');
    if (!password || !confirm) {
      return null;
    }
    return password.value === confirm.value ? null : { nomatch: true };
  }

  onSubmit() {
    const editedUser = this.userForm.value;
    editedUser.password = editedUser.passwords.password;
    console.log(editedUser);
    if (this.isNew) {
      this.userService.create(editedUser).subscribe(
        (data) => {
          this.growlService.showInfo('Backend user is created');
          this.navigateBack();
        },
        (error_data: any) => {
          console.log(error_data.error);
          this.growlService.showError(error_data.error.msg_const);
        }
      );
    } else {
      editedUser.id = this.user.id;
      this.userService.update(editedUser).subscribe(
        data => {
          this.growlService.showInfo('Backend user is updated');
          this.navigateBack();
        },
        (error_data: any) => {
          console.log(error_data.error);
          this.growlService.showError(error_data.error.msg_const);
        }
      );
    }
  }

  private navigateBack() {
    this.router.navigate(['/home/backend_users']);
  }

  ngOnDestroy() {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
