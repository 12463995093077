import { Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { GrowlService } from '../../../_module_dialog/growl_dialog/growl.service';
import { BackendUserService } from '../../../models_services/backend_user.service';
import { BasicPobInfo } from '../../../models/bpi';
import { BackendUser } from '../../../models/backend-user';
import { BPIService } from '../../../models_services/bpi.service';
import dayjs from 'dayjs';  // Correct

import { LocationService } from '../../../models_services/location.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TimeFilterInterface } from '../time-filter/time-filter.component';
import { MobileUserService } from 'src/app/models_services/mobile-users.service';

@Component({
    selector: 'app-map-panic-mode',
    templateUrl: './map-panic-mode.component.html',
    styleUrls: ['./map-panic-mode.component.css'],
    standalone: false
})
export class MapPanicModeComponent implements OnInit {
  itIsSingleUserPage = false;
  userId = 0;
  startDateInput = null;
  endDateInput = null;
  displayMarkerInfoDialog = false;
  displayTimeFilterForm = false;
  displayLegend = false;
  showTimeFilterButtons = false;
  deviceId: string;
  mobileUserId: number;
  selectedPob: BackendUser;
  bpiList: BasicPobInfo[];
  selectedBPI: BasicPobInfo;
  markersInfoMap: Map<number, BasicPobInfo>;
  selectedPobLocation: Location;
  timeFilterForm: UntypedFormGroup = new UntypedFormGroup({});
  currentYear: number;
  minYear: number;
  userType = null;
  startTime = null;
  endTime = null;
  
  minDate: Date;
  maxDate: Date;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private growlService: GrowlService,
    private backendUserService: BackendUserService,
    private bpiService: BPIService,
    private locationService: LocationService,
    private mobileUserService: MobileUserService,
  ) {
    Date.prototype.toJSON = function () { return dayjs(this).format(); };
    this.currentYear = dayjs().year();
    this.minYear = this.currentYear - 60;

    this.minDate = new Date();
    let prevYear = dayjs().year() - 1;
    this.minDate.setFullYear(prevYear);
    this.minDate.setHours(0);
    this.minDate.setMinutes(0);

    this.maxDate = new Date();
    let nextYear = dayjs().year();
    this.maxDate.setFullYear(nextYear);
    this.maxDate.setHours(0);
    this.maxDate.setMinutes(0);
    this.maxDate.setDate((new Date()).getDate() + 1);


    var date = new Date();
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    lastDay.setHours(23);
    lastDay.setMinutes(59);
    this.startDateInput = firstDay;
    this.endDateInput = lastDay;
    this.startTime = this.startDateInput;
    this.endTime = this.endDateInput;
  }

  ngOnInit() {
    try {
      this.itIsSingleUserPage = !!parseInt(this.route.snapshot.paramMap.get('id'));
      if(this.itIsSingleUserPage) {
        this.userId = parseInt(this.route.snapshot.paramMap.get('id'));
        this.mobileUserService.getById(this.userId).subscribe(
          (data) => {
            this.selectedPob = data.user;
          }
        );
      }
    } catch (error) {
      console.log('Users locations page');
    }

    this.showTimeFilterButtons = true;
    this.initForm();

    this.getBasicPobInfoPanicLocations(null , 'desc', false );
  }

  private initForm() {
    let groupObject = {};
    groupObject = {
      startDate: new UntypedFormControl(null, Validators.required),
      endDate: new UntypedFormControl(null, Validators.required),
    };
    this.timeFilterForm = new UntypedFormGroup(groupObject);
  }

  onSubmitTimeFilterForm() {
    const filterValue = this.timeFilterForm.value;
    console.log(filterValue);
    this.startTime = (filterValue.startDate) ? filterValue.startDate.getTime() : null;
    this.endTime = (filterValue.endDate) ? filterValue.endDate.getTime() : null;
    // metters step is not used to filter on the database, 
    // it used to filter the POIs client side.
    this.getBasicPobInfoPanicLocations(
      null, 
      'desc',
      false
    );
  }

  getBasicPobInfoPanicLocations(limit, timeSortOrder, maxOverTime){
    this.bpiService.getBasicPobPanicLocationsWithMaxOverLocationTime(
      this.userType,
      this.startTime, 
      this.endTime, 
      limit, 
      timeSortOrder, 
      maxOverTime, this.userId).subscribe(
        (data: any) => {
          this.bpiList = data.bpis;
          if (! this.bpiList || this.bpiList.length == 0) {
            this.growlService.showInfo("No locations are found for the selected criteria");
          }
          this.displayTimeFilterForm = false;
        },
        (error_data) => {
          this.growlService.showError(error_data.error.msg_const);
        }
      );
  }

  // handleOverlayClick(event) if it comes from primeng with (onOverlayClick)="handleOverlayClick($event)"
  // event.overlay.labelContent
  // But here the event comes from the spiderfier oms and receives the marker as parameter
  handleOverlayClick(marker) {
    const label: string = marker.labelContent;
    this.selectedBPI = this.markersInfoMap.get(+(label.split('.')[0]));
    this.displayMarkerInfoDialog = true;
  }

  onTimeFilterButtonClick() {
    this.displayTimeFilterForm = !this.displayTimeFilterForm;
  }

  onLegendQuestionClick() {
    this.displayLegend = !this.displayLegend;
  }

  onFilter(event: TimeFilterInterface) {
    this.displayTimeFilterForm = false;
    this.startTime = event.startTime;
    this.endTime = event.endTime;
    this.getBasicPobInfoPanicLocations(
      null, 
      'desc', 
      false
    );
  }

  navigateToUserEditPage(mobileUserId: number){
    this.router.navigate(['/home/register', this.mobileUserId, 'edit']);
  }

  showCorporateUsers() {
    if (this.userType != 'CORPORATE') {
      this.userType = 'CORPORATE';
    } else {
      this.userType = null;
    }
    // fetch the corporate users
    this.getBasicPobInfoPanicLocations(null , 'desc', false );
  }

  showSponsoredUsers() {
    if (this.userType != 'SPONSORED') {
      this.userType = 'SPONSORED';
    } else {
      this.userType = null;
    }
    // fetch the sponsored users
    this.getBasicPobInfoPanicLocations(null , 'desc', false );
  }

}
