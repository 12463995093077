import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CountryService } from '../models_services/country.service';
import { Country } from '../models/country';

@Injectable()
export class CountriesResolve  {

    constructor(private countryService: CountryService) {}

    resolve(route: ActivatedRouteSnapshot) {
        return this.countryService.getAll();
    }
}
