import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { GrowlService } from '../../../../_module_dialog/growl_dialog/growl.service';
import { VigiRegionService } from '../../../../models_services/vigiregion.service';
import { VigiRegion } from '../../../../models/vigiregion';
import { VigiCountry } from '../../../../models/vigicountry';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { slideInOutAnimation } from '../../../../_animations/slide-in-out.animation';
import { fadeInAnimation } from '../../../../_animations/fade-in.animation';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { VIGISelectedCountryService } from '../../selected-country.service';
import { HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { UploadDialogsService } from '../../../../_module_dialog/upload_dialog/upload-dialog.service';
import { Country } from '../../../../models/country';
import { CountryService } from '../../../../models_services/country.service';
import { MatDialogRef } from '@angular/material/dialog';
import { UploadFileDialogComponent } from '../../../../_module_dialog/upload_dialog/upload-dialog.component';

@Component({
    selector: 'app-vigi-region-descr',
    templateUrl: './vigi-region-descr.component.html',
    styleUrls: ['./vigi-region-descr.component.css'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('slideIn', [
            state('inactive', style({
                transform: 'translateY(10%)',
                opacity: 0
            })),
            state('active', style({
                transform: 'translateY(0%)',
                opacity: 1
            })),
            transition('active => inactive', animate('.6s ease')),
            transition('inactive => active', animate('.6s ease'))
        ])
    ],
    standalone: false
})
export class VigiRegionDescrComponent implements OnInit, OnDestroy {
  selectedCountryChangedSubscription: Subscription;
  selectedCountryId;
  selectedVigiRegion: VigiRegion; // Selected vigi region in the dropdown

  // region create/edit properties
  areaDisplayDialog: boolean;
  area: VigiRegion;
  selectedRegion: VigiRegion = new VigiRegion(0); // selected Vigi region from the table
  newRegion: boolean;
  regions: VigiRegion[];
  cities: VigiRegion[];
  riskLevelsSI: SelectItem[];
  selectedRiskLevel: string;
  selectedCountry: Country;
  uploadDialogRef: MatDialogRef<UploadFileDialogComponent>;
  areaTypes = [
    {label:'Select area type', value:null},
    {label:'Region', value: 'REGION'},
    {label:'City', value: 'CITY'},
  ];

  constructor(
    private vigiSelectedCountryService: VIGISelectedCountryService,
    private growlService: GrowlService,
    private vigiRegionService: VigiRegionService,
    private uploadDialogService: UploadDialogsService,
    private countryService: CountryService,
  ) {
    this.selectedCountryId = this.vigiSelectedCountryService.selectedCountryId;
    console.log('SelectedCountryId:' + this.selectedCountryId);
    this.getCountryById(this.selectedCountryId);
    this.getVigiRegionsForCountryId(this.selectedCountryId);
    this.getVigiCitiesForCountryId(this.selectedCountryId);
    
    this.riskLevelsSI = [
      { value: 'LOW_RISK', label: 'Low Risk' },
      { value: 'MODERATE_RISK', label: 'Moderate Risk' },
      { value: 'HIGH_RISK', label: 'High Risk' },
      { value: 'VERY_HIGH_RISK', label: 'Very High Risk' },
      { value: 'WAR_ZONE', label: 'Extreme risk' },
    ];
  }

  ngOnInit() {
    this.selectedCountryChangedSubscription = this.vigiSelectedCountryService.countryIdUpdatedEvent.subscribe(
      (val: number) => {
        this.selectedCountryId = val;
        this.getVigiRegionsForCountryId(val);
        this.getVigiCitiesForCountryId(val);
        this.getCountryById(val);
      }
    )
  }

  getCountryById(countryId: number){
    this.countryService.getById(countryId).subscribe(
      (data) => {
        this.selectedCountry = data.country;
      },
      (error_data) => {
        this.growlService.showError(error_data.error.msg_const);
      }
    );
  }

  getVigiRegionsForCountryId(countryId){
    // get the regions for the selected country
    this.vigiRegionService.getVigiAreasRegionsForCountryId(countryId).subscribe(
      (data: any) => { 
        this.regions = (data.vigiregions) ? data.vigiregions : []; 
      }
    )
  }

  getVigiCitiesForCountryId(countryId){
    // get the regions for the selected country
    this.vigiRegionService.getVigiAreasCitiesForCountryId(countryId).subscribe(
      (data: any) => { 
        this.cities = (data.vigiregions) ? data.vigiregions : []; 
      }
    )
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.selectedCountryChangedSubscription.unsubscribe();
  }

  exportRegions() {
    // this.growlService.showInfo('Export is not implemented yet');
    console.log('Export regions for country to xlsx')
    this.vigiRegionService.exportRegionsInXLSX(this.selectedCountryId).subscribe(
      (data) => { this.openFileForDownload(data); },
      (error: any) => {
        this.growlService.showError(error.etext);
      });
  }

  importRegions() {
    this.uploadDialogRef = this.uploadDialogService.show(
      'Import regions for country', 'Select xlsx file in order to import data for ' + this.selectedCountry.name.toUpperCase(), 'xlsx', '');
    this.uploadDialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.uploadDialogRef.componentInstance.formData) {
          console.log('File is provided');
          this.vigiRegionService.importRegionsFromXLSX(
            this.uploadDialogRef.componentInstance.formData, this.selectedCountryId
          ).subscribe(
            (data) => {
              this.growlService.showInfo('Regions are imported', 'Completed');
              // TODO select regions again and set selected region to none.
              this.getVigiRegionsForCountryId(this.selectedCountryId);
              this.getVigiCitiesForCountryId(this.selectedCountryId);
              this.selectedVigiRegion = null;
            },
            (error_data: any) => {
              if(error_data.error && error_data.error.msg_const) {
                this.growlService.showError(error_data.error.msg_const);
              } else {
                this.growlService.showErrorText('There was an error during the import of the regions!');
              }
            }
          );
        }
      }
    });
  }

  openFileForDownload(data: HttpResponse<Blob>) {
    const content_type = data.headers.get('Content-type');
    const x_filename = data.headers.get('x-filename');
    saveAs(data.body, x_filename);
  }

  saveVigiRegionDescr(){
    console.log('Save descr');
    if (!this.selectedVigiRegion){
      this.selectedVigiRegion = new VigiRegion(this.selectedCountryId);
    }
    this.selectedVigiRegion.risk_level = this.selectedRiskLevel;
    if (this.regions.indexOf(this.selectedVigiRegion) < 0) {
      this.growlService.showInfoText('Changes are saved');
    } else {
      this.vigiRegionService.updateVigiRegion(this.selectedVigiRegion).subscribe(
        (data) => {
          this.growlService.showInfoText('Changes are saved');
        },
        (error_data: any) => this.growlService.showError(error_data.error.msg_const)
      );
    }
  }

  addRegion(){
    // called from the big + in down right corner
    this.showRegionDialogToAdd();
  }

  showRegionDialogToAdd() {
    this.newRegion = true;
    this.area = new VigiRegion(0);
    this.areaDisplayDialog = true;
  }

  saveRegion() {
    const regions = (this.regions) ? [...this.regions] : [];
    const cities = (this.cities) ? [...this.cities] : [];
    this.area.country_id = this.selectedCountryId;
    if (this.newRegion) { // NEW
      this.vigiRegionService.createVigiRegion(this.area).subscribe(
        (data: any) => {
          this.getVigiRegionsForCountryId(this.selectedCountryId);
          this.getVigiCitiesForCountryId(this.selectedCountryId);
          this.area = null;
          this.areaDisplayDialog = false;
          this.growlService.showInfoText('Area is created.');
        },
        (error_data) => {
          this.growlService.showError(error_data.error.msg_const);
          this.area = null;
          this.areaDisplayDialog = false;
        }
      );
    } else { // EDIT
      this.vigiRegionService.updateVigiRegion(this.area).subscribe(
        (data: any) => {
          if(this.area.area_type === 'CITY') {
            this.getVigiCitiesForCountryId(this.selectedCountryId);
            this.cities[this.cities.indexOf(this.selectedRegion)] = data.vigiregion;
          } else {
            this.getVigiRegionsForCountryId(this.selectedCountryId);
            this.regions[this.regions.indexOf(this.selectedRegion)] = data.vigiregion;
          }
          this.area = null;
          this.areaDisplayDialog = false;
          this.growlService.showInfoText('Area is updated.')
        },
        (error_data) => {
          this.growlService.showError(error_data.error.msg_const);
          this.area = null;
          this.areaDisplayDialog = false;
        }
      );
    }
  }

  /**
   * delete region
   */
  deleteRegion(deletedArea: VigiRegion) {
    this.vigiRegionService.deleteVigiRegion(deletedArea.id).subscribe(
      (data) => {        
        if (deletedArea.area_type === 'CITY') {
          const index = this.cities.indexOf(deletedArea);
          this.cities = this.cities.filter((val, i) => i !== index);
        } else { 'REGION'
          const index = this.regions.indexOf(deletedArea);
          this.regions = this.regions.filter((val, i) => i !== index);
        }
        this.area = null;
        this.areaDisplayDialog = false;
        this.growlService.showInfo('Area is deleted.');
      },
      (error_data) => {
        if (error_data.error) {
          this.growlService.showError(error_data.error.msg_const);
        } else{
          this.growlService.showErrorText(error_data);
        }
        this.area = null;
        this.areaDisplayDialog = false;
      }
    );
  }

  onAreaSelect(area, type) {
    this.newRegion = false;
    this.area = this.cloneRegion(area);
    this.areaDisplayDialog = true;
  }

  cloneRegion(c: VigiRegion): VigiRegion {
    const area = new VigiRegion(0);
    Object.keys(c).forEach(prop => area[prop] = c[prop]);
    return area;
  }

}
