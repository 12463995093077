import { Injectable } from '@angular/core';
import { Travel } from '../models/travel';
import { AppConfig } from '../app.config';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class TravelService {
    constructor(private httpClient: HttpClient, private config: AppConfig) { }

    getAll() {
        return this.httpClient.get<any>(this.config.apiUrl + '/travels');
    }

    getTravelsNotSendToCrisis() {
        return this.httpClient.get<any>(this.config.apiUrl + '/travels/not_sent_to_crisis');
    }

    getAllTravelsForUserWithParams(params: any, mobileUserId: number) {
        let httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get<any>(this.config.apiUrl + '/travels/mobileuser/' + mobileUserId, {
            params: httpParams,
            observe: 'body'
        });
    }

    getTravelsWithParams(params: any) {
        let httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get<any>(this.config.apiUrl + '/travels/mobileusers', {
            params: httpParams,
            observe: 'body'
        });
    }

    downloadTravelsCSV(showCorporateUsersTable) {
        return this.httpClient.get(this.config.apiUrl + 
            '/travels/corporate/' + ((showCorporateUsersTable)?1:0) + 
            '/csv', {
            headers: new HttpHeaders().set('Accept', 'text/csv' ),
            observe: 'response',
            responseType: 'blob'
        });
    }

    getById(_id: number) {
        return this.httpClient.get<any>(this.config.apiUrl + '/travels/' + _id);
    }
   
    create(travel: Travel) {
        return this.httpClient.post(this.config.apiUrl + '/travels', travel);
    }

    update(travel: Travel) {
        return this.httpClient.put(this.config.apiUrl + '/travels/' + travel.id, travel);
    }

    delete(_id: number) {
        return this.httpClient.delete(this.config.apiUrl + '/travels/' + _id);
    }

    sendTravelToCrisis(_id: number) {
        return this.httpClient.get<any>(this.config.apiUrl + '/travels/' + _id + "/send_to_crisis");
    }

}
