import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { ConfirmDialogsService } from '../../_module_dialog/confirm_dialog/confirm-dialog.service';
import { GrowlService } from '../../_module_dialog/growl_dialog/growl.service';
import { SSOLogsService } from '../../models_services/sso_logs.service';
import { SSOLog } from '../../models/sso_log';
import { openFileForDownload } from '../../utils/file';
import { MobileUserService } from '../../models_services/mobile-users.service';
import { LogDevicePanic } from 'src/app/models/log_device_panic';
import { PanicService } from 'src/app/models_services/panic.service';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-users-panic-alerts-logs',
    templateUrl: 'users_panic_alerts_logs.component.html',
    styleUrls: ['users_panic_alerts_logs.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class UserPanicAlertsComponent implements OnInit {
  faFileCsv = faFileCsv;
  userId: number = 0;
  asyncLogDevicePanics: Observable<LogDevicePanic[]>;
  count: 0;
  currentPage = 1;
  loading: boolean;

  showDropDownForRow = -1;
  showHasPasswordInfoDialog = false;
  usersSelectItems: SelectItem[];

  // server side filter
  sortBy: string;
  orderAsc: string;
  params = {
    page: 1, per_page: 10, order: '-time',
    'group_id': 0
  };
  selectedSSOLogs: SSOLog[] = [];
  displayDialog: boolean = false;

  constructor(
    private confirmDService: ConfirmDialogsService,
    private router: Router,
    private growlService: GrowlService,
    private panicService: PanicService,
    private userService: MobileUserService) {
  }

  ngOnInit() {
    this.loadAllSSOLogs();
  }

  private loadAllSSOLogs() {
    this.getPage(1);
  }

  getPage(page: number) {
    this.params.page = page;
    this.asyncLogDevicePanics = this.panicService.getAllDevicePanicsLogsWithParams(this.params).pipe(
      tap((data: any) => {
        this.count = data.count;
        this.loading = false;
        this.currentPage = page;
      }),
      map((data: any) => {
        return data.panic_logs;
      })
    );
  }

  loadTransactionsLazy(event: LazyLoadEvent) {
    if (event.sortField) {
      this.params.order = ((event.sortOrder > 0) ? '-' : '') + event.sortField;
    }
    console.log(event.rows);
    console.log(event.first);
    console.log(event.first / event.rows);
    this.params.per_page = event.rows;
    this.getPage((event.first / event.rows) + 1);
  }

  downloadPanicsLogsFromDevices() {
    this.panicService.downloadPanicsLogsFromDevicesCSV(
    ).subscribe(
      (data) => {
        openFileForDownload(data);
      },
      (error_data: any) => { this.growlService.showError(error_data.error.msg_const); }
    );
  }

}
