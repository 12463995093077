
  <button pButton type="button" label="Corporate" *ngIf="!itIsSingleUserPage"
    [ngClass]="{'p-button-secondary': ((showCorporateUsersTable == 0)? true: false)}"
    (click)="showCorporateUsers()">
  </button>
  <button pButton type="button" label="Sponsored" *ngIf="!itIsSingleUserPage"
    [ngClass]="{'p-button-secondary': ((showCorporateUsersTable == 1)? true: false)}"
    (click)="showSponsoredUsers()">
  </button>

  <div class="row">
    <div class="medium-8 medium-offset-2 columns">
      <h2 class="subheader"></h2>

      <p-card header="User info" [style]="{width: '360px'}" [hidden]="!itIsSingleUserPage">
        <div>
          <label><b>Given name:</b></label>
           {{ user?.firstname }}
          <br />
          <label><b>Surname:</b></label>
           {{ user?.lastname }}
          <br />
          <label><b>Email:</b></label>
          {{ user?.email }}
        </div>
      </p-card>
      
      <p-table #dt [value]="asyncLocations | async" [paginator]="true" [rowsPerPageOptions]="[10, 25, 50]"
        paginatorPosition="both" [rows]="10" autoLayout="true" [lazy]="true" currentPageReportTemplate="Showing {count} entries" 
        (onLazyLoad)="loadTransactionsLazy($event)"
        [(selection)]="selectedLocation" selectionMode="single" dataKey="id"
        (onRowSelect)="onRowSelect()"   scrollable="true" 
        [totalRecords]="count">
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
              <td [attr.colspan]="8"> No records found </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th [hidden]="itIsSingleUserPage" [pSortableColumn]="'firstname'">{{ 'Given name'}}
              <p-sortIcon [field]="'firstname'"></p-sortIcon>
            </th>
            <th [hidden]="itIsSingleUserPage" [pSortableColumn]="'lastname'">{{ 'Surname'}}
              <p-sortIcon [field]="'lastname'"></p-sortIcon>
            </th>
            <th [hidden]="itIsSingleUserPage" [pSortableColumn]="'email'">{{ 'Email'}}
              <p-sortIcon [field]="'email'"></p-sortIcon>
            </th>
            <th>{{ 'Country'}}</th>
            <th>{{ 'City'}}</th>
            <th>{{ 'Location type'}}</th>
            <th>{{ 'Latitude'}}</th>
            <th>{{ 'Longitude'}}</th>
            <th>{{ 'Battery level'}}</th>
            <th [pSortableColumn]="'time'">
              {{ 'Time (GMT)'}} 
              <p-sortIcon [field]="'time'"></p-sortIcon></th>
            <th>{{ 'Regions in country'}}</th>
          </tr>
          <tr>
            <th [hidden]="itIsSingleUserPage">
              <input pInputText type="text" (input)="dt.filter($event.target.value, 'firstname', 'equals')">
            </th>
            <th [hidden]="itIsSingleUserPage">
              <input pInputText type="text" (input)="dt.filter($event.target.value, 'lastname', 'equals')">
            </th>
            <th [hidden]="itIsSingleUserPage">
              <input pInputText type="text" (input)="dt.filter($event.target.value, 'email', 'equals')">
            </th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">
            <td [hidden]="itIsSingleUserPage">
              <ng-template pHeader>Given name</ng-template>
              {{ rowData.firstname }}
            </td>
            <td [hidden]="itIsSingleUserPage">
              <ng-template pHeader>Surname</ng-template>
              {{ rowData.lastname }}
            </td>
            <td [hidden]="itIsSingleUserPage">
              <ng-template pHeader>VTS Email</ng-template>
              {{ rowData.email }}
            </td>
            <td>
              <ng-template pHeader>Country</ng-template>
              {{ rowData.country }}
            </td>
            <td>
              <ng-template pHeader>City</ng-template>
              {{ rowData.city }}
            </td>
            <td>
              <ng-template pHeader>Location type</ng-template>
              {{ rowData.type_str }}
            </td>
            <td>
              <ng-template pHeader>Latitude</ng-template>
              {{ rowData.latitude }}
            </td>
            <td>
              <ng-template pHeader>Longitude</ng-template>
              {{ rowData.longitude }}
            </td>
            <td>
              <ng-template pHeader>Battery level</ng-template>
              {{ rowData.battery_level }}
              <span *ngIf="rowData.battery_level">%</span>
            </td>
            <td>
              <ng-template pHeader>Time (GMT)</ng-template>
              {{ rowData.time }}
            </td>
            <td>
              <ng-template pHeader>Regions in country</ng-template>
              <span *ngFor="let region of rowData.regions; let isLast=last">
                {{region.name}}{{isLast ? '' : ', '}}
              </span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>



<p-dialog header="Location" [(visible)]="displayDialog" [style]="{width: '600px', height: '400px'}">
  <div id="googleMap" style="width: 100%; height: 100%;"></div>
</p-dialog>



