<p-table [value]="geolocationTypes" 
  styleClass="p-datatable-gridlines" 
  [tableStyle]="{ 'max-width': '30rem' }">
  <ng-template pTemplate="caption">Geolocation types</ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>Icon</th>
      <th>Type</th>
      <th *ngIf="showGeolocationTypeCheckboxes">Filter</th> 
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-geoType>
      <tr>
        <td>
          <img [src]="geoType.imgSrc" 
            alt="type icon" 
            width="32"/>
        </td>
        <td>{{ geoType.typeName }}</td>
        <td *ngIf="showGeolocationTypeCheckboxes">
          <input type="checkbox" [(ngModel)]="geoType.selected" (change)="onGeolocationTypeCheckboxChange()"/>
        </td>
      </tr>
  </ng-template>
</p-table>

<p-table [value]="geolocationColors" 
  styleClass="p-datatable-gridlines" 
  [tableStyle]="{ 'max-width': '30rem' }">
  <ng-template pTemplate="caption">Geolocation colors</ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>Color</th>
      <th>Description</th>
      <th *ngIf="showColorCheckboxes">Filter</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-geoColor>
      <tr>
        <td>
          <div style="width: 32px; height: 32px;" 
            [ngStyle]="{'background-color': geoColor.hex}"
            alt="type icon" 
            width="32">
          </div>
        </td>
        <td>{{ geoColor.description }}</td>
        <td *ngIf="showColorCheckboxes">
          <input type="checkbox" [(ngModel)]="geoColor.selected" (change)="onCheckboxChange()"/>
        </td>
      </tr>
  </ng-template>
</p-table>
<!-- <p>No geolocation older than 48 hours is kept</p> -->