import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpHeaders } from '@angular/common/http';
import { ImageInterface } from './image.interface';
import { GrowlService } from '../growl_dialog/growl.service';
import { Lightbox } from 'ngx-lightbox';
import { IAlbum } from 'ngx-lightbox';

// import { NgxGalleryImage, NgxGalleryOptions } from 'ngx-gallery-9';

@Component({
    selector: 'app-upload-dialog',
    template: `
        <h3 mat-dialog-title>{{ title }}</h3>
        {{ message }}
        <input type="file" (change)="fileChange($event)" [accept]="fileExtension">
        <div mat-dialog-actions>
            <button type="button" mat-raised-button (click)="dialogRef.close()">Cancel</button>
        </div>

        <div mat-dialog-content>
            <br *ngIf="imageUrl" />
            <img *ngIf="imageUrl" [src]="imageUrl" />
            <div *ngFor="let image of images; let i = index" (click)="openLightbox(i)">
                <img [src]="image.thumb" [alt]="image.caption" class="gallery-thumbnail" />
            </div>
        </div>
    `,
    standalone: false
})
export class UploadFileDialogComponent {
    public title: string;
    public message: string;
    public fileExtension: string;
    public imageUrl: string;
    
    public maxSize: number = 0;
    private _formData: FormData = null;
    private _options: HttpHeaders = null;

    // public images: ImageInterface[] = [];
    // galleryOptions: NgxGalleryOptions[];
    // galleryImages: NgxGalleryImage[];
    public images: IAlbum[] = [];
    

    openLightbox(index: number): void {
        this.lightbox.open(this.images, index);
    }

    closeLightbox(): void {
        this.lightbox.close();
    }

    constructor(
        public dialogRef: MatDialogRef<UploadFileDialogComponent>,
        private lightbox: Lightbox
    ) {}

    get formData(): FormData { return this._formData; }
    get options(): HttpHeaders { return this._options; }

    // https://stackoverflow.com/questions/40214772/file-upload-in-angular-2
    fileChange(event) {
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            this._formData = new FormData();

            // =========== for many files upload
            // for (let i = 0; i < fileList.length; i++) {
            //     this._formData.append('files[]', fileList[i]);
            // }

            // =========== for single file upload only
            const file: File = fileList[0];
            if (this.maxSize) {
                if (file.size > this.maxSize * 1048576){
                    alert("File is too big! Maximum allowed file size is " + this.maxSize + 'MB');
                    this.dialogRef.close(false);
                    return;
                };
            }

            this._formData.append('file', file, file.name);
            const _options = new HttpHeaders().set('Content-Type', 'multipart/form-data' )
            console.log('File is Uploaded from the upload dialog');
            this.dialogRef.close(true);
        }
    }
}
