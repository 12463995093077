<div>
  <p-tabs (onChange)="onTabChange($event)" [style]="{'max-width':'100%'}">
    <p-tablist>
      <p-tab value="0">SMTP Server</p-tab>
    </p-tablist>
    <p-tabpanels>
      <p-tabpanel value="0" >
        <div fxLayout="column">
          <div fxLayout="column"><span>Email</span>
            <input id="smtpEmail" [(ngModel)]="smtpEmail" type="email" pInputText required/>
          </div>
        </div>
        <div fxLayout="column">
          <div fxLayout="column"><span>Old password</span>
            <input id="smtpPassword" [(ngModel)]="oldSmtpPassword" type="password" pInputText required />
          </div>
        </div>
        <div fxLayout="column">
          <div fxLayout="column"><span>New password</span>
            <input id="smtpPassword" [(ngModel)]="smtpPassword" type="password" pInputText required />
          </div>
        </div>
      </p-tabpanel>
    </p-tabpanels>
  </p-tabs>

  <p-button [disabled]="!smtpEmail || !oldSmtpPassword || !smtpPassword" 
    *ngIf="tabIndex == 0" label="Save password" type="button" 
    (click)="(smtpEmail && oldSmtpPassword && smtpPassword) ? updateEmailPassword() : null"></p-button>
</div>
