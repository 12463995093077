import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Travel } from '../../models/travel';
import { TravelService } from '../../models_services/travel.service';
import { GrowlService } from '../../_module_dialog/growl_dialog/growl.service';

@Component({
    selector: 'app-crisis-list',
    templateUrl: 'crisis_list.component.html',
    styleUrls: ['crisis_list.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CrisisListComponent implements OnInit {
  userId: number = 0;
  asyncCrisisLogs: Observable<Travel[]>;
  count: 0;
  currentPage = 1;
  loading: boolean;

  // server side filter
  sortBy: string;
  orderAsc: string;

  params = {
    page: 1, per_page: 10000, order: '-date_created',
    'pid': { operator: '%', value: '' },
  };

  constructor(
    private travelService: TravelService,
    private growlService: GrowlService
    ) {
  }

  ngOnInit() {
    this.loadAllCrisisLogs();
  }

  private loadAllCrisisLogs() {
    this.asyncCrisisLogs = this.travelService.getTravelsNotSendToCrisis().pipe(
      map((data: any) => {
        return data.travels;
      })
    );
  }

  sendTravelToCrisis(travel: Travel) {
    this.travelService.sendTravelToCrisis(travel.id).subscribe(
      (data: any) => {
        if (data.travel && data.travel.is_sent_to_crisis) {
          this.growlService.showInfoText('Sending to CRISIS was successful');
          this.loadAllCrisisLogs();
        } else {
          this.growlService.showWarnText('Travel failed to be sent to CRISIS');
        }
      }, 
      (error_data: any) => {
        if (error_data.status == 404) {
          this.growlService.showError("Travel with that ID was not found on the server")
        }
        if (error_data.error && (error_data.error.msg_const || error_data.error.etext)) {
          if (error_data.error.msg_const) {
            this.growlService.showError(error_data.error.msg_const); 
          } 
          else {
            this.growlService.showError(error_data.error.etext); 
          }
          
        }
      }
    );
  }

}
