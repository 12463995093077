import { Component, OnInit, Input } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { VigiRegion } from '../../../../models/vigiregion';
import { VIGISelectedCountryService } from '../../selected-country.service';
import { GrowlService } from '../../../../_module_dialog/growl_dialog/growl.service';
import { Subscription } from 'rxjs';
import { VigiRegionService } from '../../../../models_services/vigiregion.service';
import { FactSheetTypeService } from '../../../../models_services/fact_sheet_types.service';
import { VigiCountryService } from '../../../../models_services/vigicountry.service';
import { HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { UploadDialogsService } from '../../../../_module_dialog/upload_dialog/upload-dialog.service';
import { MatDialogRef } from '@angular/material/dialog';
import { UploadFileDialogComponent } from '../../../../_module_dialog/upload_dialog/upload-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import { CountryService } from '../../../../models_services/country.service';
import { Country } from '../../../../models/country';

@Component({
    selector: 'app-regions-sheets',
    templateUrl: './regions-sheets.component.html',
    styleUrls: ['./regions-sheets.component.css'],
    standalone: false
})
export class RegionsSheetsComponent implements OnInit {
  selectedCountryChangedSubscription: Subscription;
  @Input()
  factSheetTypesSI: SelectItem[];
  descr_en: string;
  descr_fr: string;
  selectedCountryId: number;  // from the country dropdown
  selectedCountry: Country;
  selectedFactSheetTypeId: number; // from the sheet types dropdown
  selectedVigiRegion: VigiRegion; // Selected vigi region in the dropdown
  regions: VigiRegion[];
  regionsModifiedCopy: VigiRegion[];


  // region create/edit properties
  regionDisplayDialog: boolean;
  region: VigiRegion;
  selectedRegion: VigiRegion = new VigiRegion(0); // selected Vigi region from the table
  newRegion: boolean;

  uploadDialogRef: MatDialogRef<UploadFileDialogComponent>;

  areaTypes = [
    {label:'Select area type', value:null},
    {label:'Region', value: 'REGION'},
    {label:'City', value: 'CITY'},
  ];

  constructor(
    private vigiSelectedCountryService: VIGISelectedCountryService,
    private growlService: GrowlService,
    private vigiRegionService: VigiRegionService,
    private vigiCountryService: VigiCountryService,
    private factSheetTypeService: FactSheetTypeService,
    private uploadDialogService: UploadDialogsService,
    private countryService: CountryService
  ) { 
    this.selectedCountryId = this.vigiSelectedCountryService.selectedCountryId;
    this.getCountryById(this.selectedCountryId);
    this.getAllFactSheetTypes();
  }

  ngOnInit() {
    this.selectedCountryChangedSubscription = this.vigiSelectedCountryService.countryIdUpdatedEvent.subscribe(
      (val: number) => {
        this.selectedCountryId = val;
        this.getVigiRegionsForCountryId(val);
        this.getCountryById(val);
      }
    )
  }

  getCountryById(countryId: number){
    this.countryService.getById(countryId).subscribe(
      (data) => {
        this.selectedCountry = data.country;
      },
      (error_data) => {
        this.growlService.showError(error_data.error.msg_const);
      }
    );
  }

  getAllFactSheetTypes() {
    this.factSheetTypeService.getAllFactSheetTypes().subscribe(
      (data) => {
        this.factSheetTypesSI = []; 
        data.fact_sheet_types.forEach(f => {
          this.factSheetTypesSI.push({label: f.name_en, value: f.id});
        });
        if(this.factSheetTypesSI && this.factSheetTypesSI.length > 0) {
          this.selectedFactSheetTypeId = this.factSheetTypesSI[0].value;
        }
      }
    );
  }

  getVigiRegionsForCountryId(countryId){
    // get the regions for the selected country
    this.vigiRegionService.getVigiRegionsForCountryId(countryId).subscribe(
      (data: any) => { 
        this.regions = (data.vigiregions) ? data.vigiregions : []; 
        this.regionsModifiedCopy = (this.regions) ? [...this.regions] : [];
        this.regionsModifiedCopy.forEach(r => {
          r.name = r.name + " (" + r.area_type + ')';
        });
      }
    );
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.selectedCountryChangedSubscription.unsubscribe();
  }

  saveFactSheetDescr(){
    if (this.selectedVigiRegion && this.selectedFactSheetTypeId) {
      let factSheetDescription = {      
        'fact_sheet_type_id': this.selectedFactSheetTypeId,
        'descr_en': this.descr_en,
        'descr_fr': this.descr_fr,
        'country_id': this.selectedCountryId
      }
      this.vigiRegionService.saveDescrForRegion(factSheetDescription, this.selectedVigiRegion.id).subscribe(
        (data) => {
          this.growlService.showInfoText('Region description has been updated.')
        },
        (error_data: any) => {
          this.growlService.showError(error_data.error.msg_const);
        }
      );
    } else {
      if (!this.selectedVigiRegion) {
        this.growlService.showInfoText('Please select region!');
      }
      if (!this.selectedFactSheetTypeId){
        this.growlService.showInfoText('Please select type!');
      }
    }
  }

  onFactSheetTypeChange(){
    this.getFactSheetDescrForSelectedCountryAndSheetType();
  }

  onVigiRegionChange(){
    this.getFactSheetDescrForSelectedCountryAndSheetType();
  }

  getFactSheetDescrForSelectedCountryAndSheetType(){
    if (this.selectedVigiRegion && this.selectedFactSheetTypeId) {
      console.log('Get vigi region descr');
      this.vigiRegionService.getFactSheetDescrForSelectedRegionAndSheetType(
        this.selectedVigiRegion.id, this.selectedFactSheetTypeId).subscribe(
          (data) => {
            if(data.fact_sheet_descr) {
              this.descr_en = data.fact_sheet_descr.descr_en;
              this.descr_fr = data.fact_sheet_descr.descr_fr;
            } else {
              this.descr_en = '';
              this.descr_fr = '';
            }
          }
        );
    } else {
      this.descr_en = '';
      this.descr_fr = '';
    }
  }

  exportFactSheets() {
    console.log('Export fact sheets to xlsx')
    this.vigiCountryService.exportFactSheetsInXLSX(this.selectedCountryId).subscribe(
      (data) => { this.openFileForDownload(data); },
      (error: any) => {
        this.growlService.showError(error.etext);
      });
  }

  openFileForDownload(data: HttpResponse<Blob>) {
    const content_type = data.headers.get('Content-type');
    const x_filename = data.headers.get('x-filename');
    saveAs(data.body, x_filename);
  }

  importFactSheets() {
      this.uploadDialogRef = this.uploadDialogService.show(
        'Import fact sheets', 'Select xlsx file in order to import data for ' + this.selectedCountry.name.toUpperCase(), 'xlsx', '');
      this.uploadDialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (this.uploadDialogRef.componentInstance.formData) {
            console.log('File is provided');
            this.vigiCountryService.importFactSheetsInXLSX(
              this.uploadDialogRef.componentInstance.formData, this.selectedCountryId
            ).subscribe(
              (data) => {
                this.growlService.showInfo('Fact sheets are imported', 'Completed');
                // TODO select regions again and set selected region to none.
                this.getVigiRegionsForCountryId(this.selectedCountryId);
                this.selectedVigiRegion = null;
                this.vigiCountryService.importFactSheetsEventEmitter.emit();
              },
              (error_data: any) => {
                if(error_data.error && error_data.error.msg_const) {
                  this.growlService.showError(error_data.error.msg_const);
                } else {
                  this.growlService.showErrorText('There was an error during the import of the fact sheets!');
                }
              }
              );
          }
        }
      });
  }

  addRegion(){
    // called from the big + in down right corner
    this.showRegionDialogToAdd();
  }

  showRegionDialogToAdd() {
    this.newRegion = true;
    this.region = new VigiRegion(0);
    this.regionDisplayDialog = true;
  }

  saveRegion() {
    const regions = (this.regions) ? [...this.regions] : [];
    this.region.country_id = this.selectedCountryId;
    if (this.newRegion) { // NEW
      this.vigiRegionService.createVigiRegion(this.region).subscribe(
        (data: any) => {
          regions.push(data.vigiregion);
          this.regions = regions;
          this.region = null;
          this.regionDisplayDialog = false;
          this.growlService.showInfoText('Region is created.');
          this.getVigiRegionsForCountryId(this.selectedCountryId);
        },
        (error_data) => {
          this.growlService.showError(error_data.error.msg_const);
          this.region = null;
          this.regionDisplayDialog = false;
        }
      );
    } else { // EDIT
      this.vigiRegionService.updateVigiRegion(this.region).subscribe(
        (data: any) => {
          regions[this.regions.indexOf(this.selectedRegion)] = data.vigiregion;
          this.regions = regions;
          this.region = null;
          this.regionDisplayDialog = false;
          this.growlService.showInfoText('Region is updated.');
          this.getVigiRegionsForCountryId(this.selectedCountryId);
        },
        (error_data) => {
          this.growlService.showError(error_data.error.msg_const);
          this.region = null;
          this.regionDisplayDialog = false;
        }
      );
    }
  }

  /**
   * delete region
   */
  deleteRegion(deletedRegion: VigiRegion) {
    this.vigiRegionService.deleteVigiRegion(deletedRegion.id).subscribe(
      (data) => {
        const index = this.regions.indexOf(deletedRegion);
        this.regions = this.regions.filter((val, i) => i !== index);
        this.region = null;
        this.regionDisplayDialog = false;
        this.growlService.showInfo('Region is deleted.');
      },
      (error_data) => {
        if (error_data.error) {
          this.growlService.showError(error_data.error.msg_const);
        } else{
          this.growlService.showErrorText(error_data);
        }
        this.region = null;
        this.regionDisplayDialog = false;
      }
    );
  }

  onRegionRowSelect(event) {
    this.newRegion = false;
    this.region = this.cloneRegion(event.data);
    this.regionDisplayDialog = true;
  }

  cloneRegion(c: VigiRegion): VigiRegion {
    const region = new VigiRegion(0);
    Object.keys(c).forEach(prop => region[prop] = c[prop]);
    return region;
  }

}
