import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, pipe, combineLatest, concat, of } from 'rxjs';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { tap, map, bufferCount } from 'rxjs/operators';
import { MobileUserService } from '../../models_services/mobile-users.service';
import { MobileUser } from '../../models/mobile-user';
import { Travel } from '../../models/travel';
import { VIGISelectedCountryService } from '../risk_assessment/selected-country.service';
import { AlertService } from 'src/app/models_services/alert.service';
import { faFilePdf, faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { exportPanicInfoPDF } from 'src/app/common/pdf_export';
import { AppConfig } from 'src/app/app.config';

declare var google: any; // Declare google maps API global object

@Component({
    selector: 'app-how-are-you-logs',
    templateUrl: './how-are-you-logs.component.html',
    styleUrls: ['./how-are-you-logs.component.css'],
    standalone: false
})
export class HowAreYouLogsComponent implements OnInit {
  faFilePdf = faFilePdf;
  user: MobileUser;
  itIsSingleUserPage = false;
  userId = 0;
  showCorporateUsersTable = 1;
  asyncTravels: Observable<Travel[]>;
  subscription: Subscription;
  count: 0;
  currentPage = 1;
  loading: boolean;
  displayDialog: boolean = false;

  dialogCallback = null;
  travelData = null;

  sortProperties: any[];
  sortBy: string;
  // orderAsc: string;
  params:any = {}; 
  waitingParameterResolve = true;

  options = {
    center: {lat: 48.830772399902344, lng: 2.8097193241119385},
    zoom: 9
  };
  overlays = [];
  map: google.maps.Map; // Google Maps map object
  

  constructor(
    private route: ActivatedRoute,
    private alertService: AlertService,
    private userService: MobileUserService,
    private router: Router,
    private vigiSelectedCountryService: VIGISelectedCountryService,
    public appConfig: AppConfig
  ) { }

  ngOnInit() {
    this.initializeGoogleMap(); // Initialize Google Map
    try {
      this.itIsSingleUserPage = !!parseInt(this.route.snapshot.paramMap.get('id'));
      if(this.itIsSingleUserPage) {
        this.userId = parseInt(this.route.snapshot.paramMap.get('id'));
      }
    } catch (error) {
      console.log('Users "How are you" page');
    }
    if(this.itIsSingleUserPage) {
      this.params = {
        page: 1, per_page: 10, 
      };
    } else {
      this.params = {
        page: 1, per_page: 10,
        email: undefined,
        firstname: undefined,
        lastname: undefined,
        is_external_contractor: 0,
      };
    }
    this.options = {
      center: {lat: 36.890257, lng: 30.707417},
      zoom: 12
    };
    this.subscription = this.route.params.subscribe(
      (params: Params) => {
        
        if (params.hasOwnProperty('id')) {
          this.userService.getById(params['id']).subscribe(data => {
            this.waitingParameterResolve = false;
            console.log(data.user);
            this.user = data.user;
            this.loadHowAreYouLogs();
          });
        } else {
          this.waitingParameterResolve = false;
          this.loadHowAreYouLogs();
        }
      }
    );
    this.subscription = this.route.params.subscribe(
      (params: Params) => {
        if (params.hasOwnProperty('id')) {
          this.userService.getById(params['id']).subscribe(data => {
            console.log(data.user);
            this.user = data.user;
            this.loadHowAreYouLogs();
          });
        }
      }
    );
  }

  initializeGoogleMap() {
    const mapOptions = {
      center: this.options.center,
      zoom: this.options.zoom
    };

    // Create the Google Map instance
    const mapElement = document.getElementById('googleMap');
    if (mapElement) {
      this.map = new google.maps.Map(mapElement, mapOptions);
    }
  }

  private loadHowAreYouLogs() {
    this.getPage(1);
  }

  getPage(page: number) {
    this.params.page = page;
    let userId = (this.user) ? this.user.id  : 0;

    if(userId != 0) {
      this.asyncTravels = this.alertService.getAllHowAreYouForUserWithParams(this.params, this.user.id).pipe(
        tap((data: any) => {
          this.count = data.count;
          this.loading = false;
          this.currentPage = page;
        }),
        map( (data: any) => {
          return data.logs;
        })
      );
    } else {
      this.asyncTravels = this.alertService.getHowAreYouWithParams(this.params).pipe(
        tap((data: any) => {
          this.count = data.count;
          this.loading = false;
          this.currentPage = page;
        }),
        map( (data: any) => {
          return data.logs;
        })
      );
    }
  }

  loadTransactionsLazy(event: LazyLoadEvent) {
    if (this.waitingParameterResolve) {
      return;
    }
    this.params.email = event.filters['email'] ? event.filters['email'].value : undefined;
    this.params.firstname = event.filters['firstname'] ? event.filters['firstname'].value : undefined;
    this.params.lastname = event.filters['lastname'] ? event.filters['lastname'].value : undefined;
    // if (event.sortField) {
    //   this.params.order = ((event.sortOrder > 0) ? '-' : '') + event.sortField;
    // }
    this.params.per_page = event.rows;
    this.getPage((event.first / event.rows) + 1);
  }

  handleOverlayClick(event) {
    console.log(event.overlay) // event.map, event.overlay, event.originalEvent
  }

  showDialog() {
    this.displayDialog = true;
  }

  onDepartsCountryClick(travelData) {
    console.log('onDepartsCountryClick');
    this.vigiSelectedCountryService.setCountryId(travelData.departs_country.id);
    this.router.navigate(
      ['/home/risk_assessment/vigi_country', { outlets: { primary: null,  vigi: ['vigi_country_map'] } }]
    );
  }

  onArrivesCountryClick(travelData) {
    this.vigiSelectedCountryService.setCountryId(travelData.arrives_country.id);
    this.router.navigate(
      ['/home/risk_assessment/vigi_country', { outlets: { primary: null,  vigi: ['vigi_country_map'] } }]
    );
  }

  onAirportClick(airport, travelData) {
    if (this.map) {
      const latitude = airport.latitude;
      const longitude = airport.longitude;
      let center = new google.maps.LatLng(latitude, longitude);
      const marker = new google.maps.Marker({
        position: {lat: latitude, lng: longitude}, 
        title: airport.country + ':' + airport.city_name  
      });
      this.overlays.push(marker);
      this.map.setCenter(center);
    } else {
      this.travelData = travelData;
      this.dialogCallback = this.onAirportClick;
    }
    this.showDialog();
  }

  showCorporateUsers() {
    if (!this.showCorporateUsersTable) {
      this.showCorporateUsersTable = 1;
      this.params.is_external_contractor = 0;
      this.getPage(1);
    }
  }

  showSponsoredUsers() {
    if (this.showCorporateUsersTable) {
      this.showCorporateUsersTable = 0;
      this.params.is_external_contractor = 1;
      this.getPage(1);
    }
  }

  exportPanicInfo(mobileUser, locationDetails, location) {
    exportPanicInfoPDF(mobileUser, locationDetails, location, this.appConfig);
  }

}
