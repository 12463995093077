import { Component, OnInit, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { TreeNode } from 'primeng/api';
import { PoleService } from '../models_services/pole.service';
import { DivisionService } from '../models_services/division.service';
import { GroupService } from '../models_services/group.service';
import { Group } from '../models/group';
import { Pole } from '../models/pole';
import { Division } from '../models/division';
import { BackendUser } from '../models/backend-user';
import { MyPermissionsService } from '../_services/myx-permissions.service';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TreeDataService {
    private poles: Pole[] = [];
    private groups: Group[] = [];
    private divisions: Division[] = [];
    private polesMap: Map<number, Pole> = new Map<number, Pole>();
    private canCRUDGroup: boolean;
    private canCRUDPole: boolean;
    private canCRUDDivision: boolean;

    polesSubject = new Subject<Pole[]>();
    divisionsSubject = new Subject<Division[]>();
    groupsSubject = new Subject<Group[]>();
    treeNodeSubject = new Subject<any>();

    constructor(
        private poleService: PoleService,
        private divisionService: DivisionService,
        private groupService: GroupService,
        private permissionService: MyPermissionsService
    ) { }

    loadTreeCollections(currentUser: BackendUser) {
        this.permissionService.checkForPermissions(
            ['GROUP_CRUD', 'POLE_CRUD', 'DIVISION_CRUD']
        ).then(
            results => {
                if (results[0]) { this.canCRUDGroup = true; }
                if (results[1]) { this.canCRUDPole = true; }
                if (results[2]) { this.canCRUDDivision = true; }

                switch (currentUser.role) {
                    case 'MASTER_ADMIN':
                        this.groupService.getAllGroups().subscribe(
                            (groups_data) => {
                                this.groups = (groups_data.groups) ? groups_data.groups : [];
                                this.poleService.getAllPoles().subscribe(
                                    (poles_data) => {
                                        this.poles = (poles_data.poles) ? poles_data.poles : [];
                                        if (this.poles) {
                                            this.poles.forEach(element => { this.polesMap.set(element.id, element); });
                                        }
                                        this.divisionService.getAllDivisions().subscribe(
                                            (divisions_data) => {
                                                this.divisions = (divisions_data.divisions) ? divisions_data.divisions : [];
                                                this.buildTree();
                                            }
                                        );
                                    }
                                );
                            }
                        );
                        break;
                    case 'GROUP_ADMIN':
                        this.groupService.getGroupById(currentUser.group_id).subscribe(
                            (group_data) => {
                                this.groups = (group_data.group) ? [group_data.group] : [];
                                this.poleService.getPolesForGroups(this.groups).subscribe(
                                    (poles_data: any) => {
                                        this.poles = (poles_data.poles) ? poles_data.poles : [];
                                        this.divisionService.getDivisionsForPoles(this.poles).subscribe(
                                            (divisions_data: any) => {
                                                this.divisions = (divisions_data.divisions) ? divisions_data.divisions : [];
                                                this.buildTree();
                                            }
                                        );
                                    }
                                );
                            }
                        );
                        break;
                    case 'POLE_ADMIN':
                        this.poleService.getPoleById(currentUser.pole_id).subscribe(
                            (pole_data) => {
                                this.poles = [pole_data.pole];
                                this.groupService.getGroupById(pole_data.pole.group_id).subscribe(
                                    (group_data) => {
                                        this.groups = [group_data.group];
                                        this.divisionService.getDivisionsForPoles(this.poles).subscribe(
                                            (divisions_data: any) => {
                                                this.divisions = (divisions_data.divisions) ? divisions_data.divisions : [];
                                                this.buildTree();
                                            }
                                        );
                                    }
                                );
                            }
                        );
                        break;
                    case 'DIVISION_ADMIN':
                        this.divisionService.getDivisionById(currentUser.division_id).subscribe(
                            (division_data) => {
                                this.divisions = [division_data.division];
                                this.poleService.getPoleById(division_data.division.pole_id).subscribe(
                                    (pole_data) => {
                                        this.poles = [pole_data.pole];
                                        this.groupService.getGroupById(pole_data.pole.group_id).subscribe(
                                            (group_data) => {
                                                this.groups = [group_data.group];
                                                this.buildTree();
                                            }
                                        );
                                    }
                                );
                            }
                        );
                        break;
                    default:
                        break;
                }
            }
        );
    }

    buildTree() {
        const groups_trees = [];
        this.groups.forEach(g => {
            const poles_trees = [];
            this.poles.forEach(p => {
                if (p.group_id == g.id) {
                    const divisions_trees = [];
                    this.divisions.forEach(d => {
                        if (d.pole_id == p.id) {
                            const division = {
                                'label': d.name_en,
                                'selectable': (this.canCRUDDivision) ? true : false,
                                'data': { 'object': d, 'type': Division },
                            };
                            divisions_trees.push(division);
                        }
                    });
                    const pole_tree = {
                        'label': p.name_en,
                        'selectable': (this.canCRUDPole) ? true : false,
                        'children': divisions_trees,
                        'expanded': false,
                        'data': { 'object': p, 'type': Pole },
                    };
                    poles_trees.push(pole_tree);
                }
            });
            const group_tree = {
                'label': g.name_en,
                'selectable': (this.canCRUDGroup) ? true : false,
                'children': poles_trees,
                'expanded': false,
                'data': { 'object': g, 'type': Group },
            }
            groups_trees.push(group_tree);
        });

        // const root_tree: TreeNode = {
        //     'label': 'Groups',
        //     'children': groups_trees,
        //     'selectable': false,
        //     'expanded': false
        // };
        // this._processTreeNodes(groups_trees, root_tree);

        this.treeNodeSubject.next(groups_trees);
    }

    private _processTreeNodes(treeNode: TreeNode[], parent: TreeNode) {
        for (const node of treeNode) {
            if (parent != null) {
                node.data.parent = parent;
            }
            if (node.children && node.children.length > 0) {
                this._processTreeNodes(node.children, node);
            }
        }
    }
}