import { Injectable } from '@angular/core';
import JSZip from 'jszip';

@Injectable()
export class ZipService {

  // Get the content
  public getZipContent(file) {
    const jszip = new JSZip();
    return jszip.loadAsync(file).then((zip) => {
      // Do something with the zipcontent
      });
  }

  private blobToFile(theBlob, fileName) {
    // A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  // Get the actual blobs from the zip
  // Returns dict where keys are the questions ids
  public unzip_question_images(data) {
    const jszip = new JSZip();
    return jszip.loadAsync(data).then((zip) => {
      const promises = Object.keys(zip.files).filter(function (fileName) {
        // don't consider non image files
        // file names must have two underscores
        const ids = fileName.split('_');
        return (ids && ids.length === 4) ? true : false;
      }).map((filename: string) => {
        const imageFile = zip.files[filename];
        return imageFile.async('blob').then(function (blob) {
          return {
            photoId: filename.split('_')[0],
            reportId: filename.split('_')[1],
            questionId: filename.split('_')[2],
            file: blob, // URL.createObjectURL(blob) create an url. img.src = URL.createObjectURL(...) will work
            order: filename.split('_')[3]
          };
        });
      });
      // `promises` is an array of promises, `Promise.all` transforms it
      // into a promise of arrays
      return Promise.all(promises);
    })
    .then(result => {
      // We have here an array of  {photoId, reportId, questionId, file, order}
      // if you want the same result as imageSrc
      return result.reduce(function (acc, val) {
        if (val) {
          acc.push(val);
        }
        return acc;
      }, []);
    })
    .catch(function (e) {
      console.error(e);
    });
  }
}
