import { Component, OnInit } from '@angular/core';
import { GrowlService } from './growl.service';
import { Message } from 'primeng/message';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-growl',
    templateUrl: 'growl.component.html',
    standalone: false
})
// The growl component passes growl messages to the template whenever a message
// is received from the growl service. It does this by subscribing to the growl 
// service's getMessage() method which returns an Observable.
export class GrowlComponent implements OnInit {
    messages: Message[] = [];

    constructor(
        private growlService: GrowlService,
        private messageService: MessageService  // Inject MessageService
    ) {}

    ngOnInit() {
        this.growlService.getMessage().subscribe((message) => {
            if (message) { 
                this.messageService.add(message);  // ✅ Automatically shows toast messages
            }
        });
    }
}
