import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable()
export class MyPermissionsService {
    permissions = {};

    constructor(private _permissionService: NgxPermissionsService) {
        const MA = 'MASTER_ADMIN';
        const GA = 'GROUP_ADMIN';
        const PA = 'POLE_ADMIN';
        const DA = 'DIVISION_ADMIN';
        
        this.permissions = {
            'MENU_BACKENDUSERS': [MA, GA, PA],
            'MENU_MOBILEUSERS': [MA, GA, PA, DA],
            'MENU_COMPANIES': [MA],
            'MENU_ENTITIES': [MA, GA, PA, DA],
            'MENU_RISK_ASSESSMENT': [MA, GA],
            'MENU_SETTINGS': [MA],
            'MENU_RA_VIGI_COUNTRY': [MA, GA],
            'MENU_RA_FACT_SHEETS': [MA, GA],
            'MENU_RA_RISK_SETTINGS': [MA, GA],
            'MENU_RA_TIPS': [MA, GA],
            'MENU_USER_LOCATIONS': [MA, GA, PA, DA], 
            'MENU_USER_TRIPS': [MA, GA, PA, DA],
            'MENU_USER_TIME_MAP': [MA, GA, PA, DA],
            'MENU_STATISTICS': [MA, GA, PA, DA],
            'MENU_PUSH_NOTIFICATION': [MA, GA],
            'MENU_NOTIFICATIONS': [MA, GA, PA, DA],
            'MENU_SSO_LOGS': [MA, GA, PA],
            'MENU_ADMIN_LOGS': [MA],
            'MENU_CRISIS_LOGS': [MA, GA],
            'MENU_APP_SETTINGS': [MA, GA],
            'MENU_BACKEND_SETTINGS': [MA, GA],
            'MENU_AIRPORTS': [MA, GA],
            'GROUP_CRUD': [MA],
            'POLE_CRUD': [MA, GA],
            'DIVISION_CRUD': [MA, GA, PA],
            'CREATE_USERS': [MA, GA, PA],
            'CREATE_COMPANY': [MA],

            'CREATE_VIGI_REGIONS': [MA, GA, PA, DA],
            'DELETE_VIGI_REGIONS': [MA],

            'EDIT_USERS': [MA, GA, PA],

            'CREATE_AIRPORT': [MA, GA, PA]
        };
    }

    definePermisions(role: string) {
        this.deleteAllPermissions();
        const userPermissionsSet: Set<string> = new Set(); 
        for (const key in this.permissions) {
            if (this.permissions.hasOwnProperty(key)) {
                const short_named_roles = this.permissions[key];
                if (short_named_roles.indexOf(role) > -1) {
                    userPermissionsSet.add(key);
                }
            }
        }
        console.log('PERMISSIONS for ' + role);
        console.log(userPermissionsSet);
        this._permissionService.loadPermissions(Array.from(userPermissionsSet.values()));
    }

    deleteAllPermissions() {
        this._permissionService.flushPermissions();
    }

    checkForPermission(permission: string): Promise<boolean> {
        return this._permissionService.hasPermission(permission);
    }

    checkForPermissions(permissions: string[]): Promise<any> {
        const permissions_promisses = [];
        for (const permission of permissions) {
            permissions_promisses.push(this._permissionService.hasPermission(permission));
        }
        return Promise.all(permissions_promisses).then(results => {
            return results;
        });
    }

}
