<div *ngIf="displayNavigationArrows" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" 
pTooltip="Navigate between the markers positions">
  <div style="position: absolute; z-index: 2; top:3px; right: 60px;" fxLayout="row" fxLayoutGap="5px">
    <button pButton (click)="moveToFirstLocation()" icon="pi pi-step-backward"></button>
    <button pButton (click)="movePrevLocation()" icon="pi pi-caret-left"></button>
    <button pButton (click)="moveNextLocation()" icon="pi pi-caret-right"></button>
    <button pButton (click)="moveToLastLocation()" icon="pi pi-step-forward"></button>
  </div>
</div>

<app-marker-info-dialog 
  [selectedBPI]="selectedBPI"
  [displayMarkerInfoDialog]="displayMarkerInfoDialog"
  [showPanicModeInfoLink]="showPanicModeInfoLink"
  (onDialogClose)="onDialogClose()"
>
</app-marker-info-dialog>

<div id="googleMap" style="width: 100%; height: 100%;"></div>

