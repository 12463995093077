import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { TreeNode, SelectItem } from 'primeng/api';
import { TreeDataService } from '../../tree_data.service';
import { BackendUser } from '../../../models/backend-user';
import { GrowlService } from '../../../_module_dialog/growl_dialog/growl.service';
import { AlertService } from '../../../models_services/alert.service';
import { MobileUserService } from '../../../models_services/mobile-users.service';
import { CountryService } from '../../../models_services/country.service';

//  these two are used for the debounce filter
import { fromEvent, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, filter, switchMap } from 'rxjs/operators';
import { StoreService } from 'src/app/common/application.state';

@Component({
    selector: 'app-alert-push',
    templateUrl: './alert-push.component.html',
    styleUrls: ['./alert-push.component.css'],
    standalone: false
})
export class AlertPushComponent implements OnInit, AfterViewInit {
  currentUser: BackendUser;
  selectedNodes: TreeNode[];
  trees: TreeNode[];
  countriesSI: SelectItem[] = [];
  selectedSubscriptionCountry: SelectItem;
  selectedLocalizedCountry: SelectItem;

  selectedSubscriptionCountriesIds;
  selectedLocalizationCountriesIds;

  @ViewChild('searchUsersInput') 
  input: ElementRef;

  selectedUsers = [];
  title = '';
  body = '';
  titleFr = '';
  bodyFr = '';

  dropdowns = {
    'users': [],
    'selected_users': [],
    'selected_users_ids': new Set<number>(),
    // 'map_users': new Map(),
  };

  alertTypes: Object[];
  selectedAlertType: any;
  itIsHowAreYou: boolean = false;

  constructor(
    private treeDataService: TreeDataService,
    private growlService: GrowlService,
    private alertService: AlertService,
    private mobileUserService: MobileUserService,
    private countryService: CountryService,
    private storeService: StoreService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('current_user'));
  }

  ngOnInit() {
    this.alertTypes = [
      { name: 'Send to entities', code: 'MULTY_DIVISION_ALERT' },
      { name: 'Send to users', code: 'MULTY_USER_ALERT' },
      { name: 'Send to country', code: 'SUBSCRIPTION_COUNTRY_ALERT' },
    ];
    this.treeDataService.loadTreeCollections(this.currentUser);
    this.treeDataService.treeNodeSubject.subscribe(
      (treeNode: any) => {
        this.trees = treeNode;
      }
    );
    this.loadAllCountries();
    // this.loadFirebaseUsers();
    
    if(this.storeService.howAreYouPobIds.length > 0){
      this.onItIsHowAreYouCheckboxChange(true);
      this.selectedAlertType = { name: 'Send to users', code: 'MULTY_USER_ALERT' };
      this.getUsersById(this.storeService.howAreYouPobIds);
      this.onDropdownUserSelectionChange({'value':this.storeService.howAreYouPobIds});
    }
  } // ngOnInit ENDS

  ngAfterViewInit() {
    // Now the ViewChild (input) is available
    fromEvent(this.input.nativeElement, 'keyup').pipe(
      filter(Boolean),
      debounceTime(150),
      distinctUntilChanged(),
      switchMap((text) => {
        this.dropdowns['users'] = [];
        this.dropdowns['selected_users'] = [];
        const filterValue = this.input.nativeElement.value;
        if (filterValue && filterValue.length > 1) {
          return this.filterAccessibleUsersWithFirebase(filterValue);
        } else {
          // If filter value is not valid, return an empty observable
          return of(null);
        }
      })
    ).subscribe(data => {
      if (data) {
        this.dropdowns['users'] = [];
        data.users.forEach(element => {
          this.dropdowns['users'].push({ value: element.id, label: element.email });
          if (this.dropdowns['selected_users_ids'].has(element.id)) {
            this.dropdowns['selected_users'].push(element.id);
          }
        });
      }
    });
  }

  filterAccessibleUsersWithFirebase(filterValue: string) {
    return this.mobileUserService.filterAccessibleUsersWithFirebase(filterValue);
  }

  getUsersById(pobIds: any[]) {
    this.mobileUserService.getByUsersIds(pobIds)
      .subscribe(
        (data:any) => {
          if (data.users) {
            data.users.forEach(element => {
              this.dropdowns['users'].push({ value: element.id, label: element.email });
              // this.dropdowns['map_users'].set(element.id, element);
              if(this.dropdowns['selected_users_ids'].has(element.id)) {
                this.dropdowns['selected_users'].push(element.id);
              }
            });
          }
      });
  }

  onDropdownUserSelectionChange(event){
    console.log("Event:");
    console.log(event.value);
    let singleSelection = false;
    if (event.itemValue) {
      singleSelection = true;
    }
    if(singleSelection){
      let itemValue = event.itemValue;
      if (event.value.indexOf(itemValue) > -1){
        this.dropdowns['selected_users_ids'].add(itemValue);
      } else {
        this.dropdowns['selected_users_ids'].delete(itemValue);
      }
    } else {
      if(event.value.length == 0) {
        this.dropdowns['selected_users_ids'].clear();
      } else {
        event.value.forEach(id => {
          this.dropdowns['selected_users_ids'].add(id);
        });
      }
    }
    console.log(event.itemValue);
  }

  loadAllCountries() {
    this.countryService.getAll().subscribe(
        (data) => {
            this.countriesSI = [];
            data.countries.forEach(c => {
                this.countriesSI.push({ label: c.name, value: c.id });
            });
        }
    );
  }

  sendPushMessage() {
    if(!this.selectedAlertType){
      this.growlService.showWarnText("Please select alert type");
      return;
    }
    console.log("Selected alert type: " + this.selectedAlertType);
    // iterate and find the ids of all selected divisions
    const divisionIds = [];
    const poleIds = [];
    const selectedUsers = [];
    let type = this.selectedAlertType.code;
    if (type == 'MULTY_DIVISION_ALERT') {
      if (!this.selectedNodes || this.selectedNodes.length < 1) {
        this.growlService.showWarnText("Please select one or more entities with the checkboxes!");
        return;
      }
      this.selectedNodes.forEach(node => {
        if (node.data.object.pole_id) {
          divisionIds.push(node.data.object.id);
        }
        if (node.data.object.group_id) {
          poleIds.push(node.data.object.id);
        }
      });
    } else if (type == 'MULTY_USER_ALERT') {
      let selected_uid_array = Array.from(this.dropdowns['selected_users_ids'].values());
      if (!selected_uid_array || selected_uid_array.length == 0) {
        this.growlService.showWarnText("Please select one or more users");
        return;
      } else {
        selectedUsers.push(...selected_uid_array);
      }
    } else if (type == 'SUBSCRIPTION_COUNTRY_ALERT') {
      // type is already set
    } else if (type == 'HOW_ARE_YOU') {
      // type is already set
    }
    // } else if (this.accordionIndex == 3) {
    //   type = 'LOCALIZED_COUNTRY_ALERT';
    // }
  
    // Now sent the alert for the selected type
    this.alertService.sentTo( type,
      divisionIds, poleIds, selectedUsers, this.selectedSubscriptionCountriesIds, this.selectedLocalizationCountriesIds,
      this.title, this.body, 
      this.titleFr, this.bodyFr, this.itIsHowAreYou
    ).subscribe(
      (data: any) => {
        if (data.failure_message) {
          this.growlService.showErrorText(data.failure_message, 'Error', true, 60000);
        }
        if (data.users_emails_without_push) {
          this.growlService.showErrorText(
            'User emails without push: ' + data.users_emails_without_push.join(' '))
        }
        if (data.success_message) {
          this.growlService.showInfoText(data.success_message, 'Completed', true, 60000);
        }
        if (data.success_message_email) {
          this.growlService.showInfoText(data.success_message_email, 'Completed', true, 60000);
        }
      }, 
      (error_data) => {
        if(error_data.error.msg_const) {
          this.growlService.showError(error_data.error.msg_const, 'ERROR', true, 60000);
        }
      }
    );
  }

  nodeSelect(event) {
    console.log(this.selectedNodes);
  }

  nodeUnselect(event) {
  }

  onLocalizationCountriesChange() {

  }

  onSubscriptionCountriesChange() {

  }

  onItIsHowAreYouCheckboxChange(checkbox_state: boolean) {
    if (checkbox_state) {
      this.itIsHowAreYou = true;
      // change the form fields to have text for "How are you?" alert
      let TITLE_EN = 'How are you?';
      let BODY_EN = 'Please confirm that you are OK? <br/>'
      + 'Click "I\'m safe" to report that you are safe. <br/>' 
      + 'Click on "SOS" to indicate that you are requesting a support request.';
      let TITLE_FR = 'How are you?';
      let BODY_FR = 'Veuillez confirmer que tout va bien ? <br/>'
      + 'Cliquez sur "je suis en sécurité" afin d’indiquer que vous êtes en sécurité.<br/>'
      + 'Cliquez sur "SOS" en cas de demande de support.';
      this.title = TITLE_EN;
      this.body = BODY_EN;
      this.titleFr = TITLE_FR;
      this.bodyFr = BODY_FR;
    } else {
      this.itIsHowAreYou = false;
      this.title = '';
      this.body = '';
      this.titleFr = '';
      this.bodyFr = '';
    }
  }

}
