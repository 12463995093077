import { Observable } from 'rxjs';
import { SingleImageDialogComponent } from './single_image_dialog.component';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

@Injectable()
/**
 * The service will act as a wrapper for MdDialog to
 * simplify the process of calling the dialog and subscribing to it.
 */
export class SingleImageDialogService {

    constructor(private dialog: MatDialog) { }

    public show(title: string, imageUrl: SafeUrl): Observable<boolean> {
        let dialogRef: MatDialogRef<SingleImageDialogComponent>;
        dialogRef = this.dialog.open(SingleImageDialogComponent, {
            width: '1200px',
        });
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.imageUrl = imageUrl;
        return dialogRef.afterClosed(); // emits event when the dialog is closed
    }

}
