<form id="mapForm" [formGroup]="timeFilterForm" (ngSubmit)="onSubmitTimeFilterForm()">
  <div fxLayout="column" fxLayoutGap="5px">
    <div formGroupName="dates">
      <div *ngIf="timeFilterForm.get('dates').hasError('timeMismatch')" style="color: red">
        Start date must be before end date!
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <label>Start date</label>
        <p-calendar 
          inputId="startDate" 
          [hourFormat]="24" 
          [showTime]="true" 
          dateFormat="dd.mm.yy" 
          [numberOfMonths]="1"
          variant="filled"
          formControlName="startDate" 
          [readonlyInput]="true" 
          [showIcon]="true" 
          [maxDate]="maxDate" />
      </div>

      <div fxLayout="row" fxLayoutGap="10px">
        <label>End date</label>
        <p-calendar 
          inputId="endDate" 
          [hourFormat]="24" 
          [showTime]="true" 
          dateFormat="dd.mm.yy" 
          [numberOfMonths]="1"
          variant="filled"
          formControlName="endDate" 
          [readonlyInput]="true" 
          [showIcon]="true" 
          [maxDate]="maxDate" />
      </div>
    </div>
    
    <button mat-raised-button color="primary" type="submit" 
    [disabled]="!timeFilterForm.valid || timeFilterForm.get('dates').hasError('timeMismatch')">
      Filter
    </button>
    <button mat-raised-button color="primary" type="button" 
      (click)="onClearDates()">
      Reset dates form
    </button>
  </div>
</form>
