import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, pipe, combineLatest, concat, of, iif } from 'rxjs';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { LocationService } from '../../../models_services/location.service';
import { LazyLoadEvent } from 'primeng/api';
import { tap, map, bufferCount } from 'rxjs/operators';
import { MobileUserService } from '../../../models_services/mobile-users.service';
import { MobileUser } from '../../../models/mobile-user';
import { Location } from '../../../models/location';
import { VigiCountryService } from '../../../models_services/vigicountry.service';
import { VigiRegionService } from '../../../models_services/vigiregion.service';

declare var google: any; // Declare google maps API global object

@Component({
    selector: 'app-mobile-user-locations',
    templateUrl: './mobile-user-locations.component.html',
    styleUrls: ['./mobile-user-locations.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class MobileUserLocationsComponent implements OnInit {
  user: MobileUser;
  itIsSingleUserPage = false;
  asyncLocations: Observable<Location[]>;
  subscription: Subscription;
  selectedLocation: Location;
  count: 0;
  currentPage = 1;
  loading: boolean;
  displayDialog: boolean = false;

  dialogCallback = null;
  showCorporateUsersTable = 1;

  sortProperties: any[];
  sortBy: string;
  orderAsc: string;
  params:any = {};   

  options = {
    center: {lat: 48.830772399902344, lng: 2.8097193241119385},
    zoom: 9
  };
  overlays = [];
  map: google.maps.Map;

  waitingParameterResolve = true;

  constructor(
    private route: ActivatedRoute,
    private locationService: LocationService,
    private userService: MobileUserService,
    private vigiRegionService: VigiRegionService
  ) { }

  ngOnInit() {
    this.options = {
      center: {lat: 36.890257, lng: 30.707417},
      zoom: 12
    };
    try {
      this.itIsSingleUserPage = !!parseInt(this.route.snapshot.paramMap.get('id'));
    } catch (error) {
      console.log('Users locations page');
    }
    if(this.itIsSingleUserPage) {
      this.params = {
        page: 1, per_page: 10, order: '-time',
        email: { operator: '%', value: null, 'class': 'MobileUser' },
        firstname: { operator: '%', value: null, 'class': 'MobileUser' },
        lastname: { operator: '%', value: null, 'class': 'MobileUser' },
      };
    } else {
      this.params = {
        page: 1, per_page: 10, order: '-time',
        email: { operator: '%', value: null, 'class': 'MobileUser' },
        firstname: { operator: '%', value: null, 'class': 'MobileUser' },
        lastname: { operator: '%', value: null, 'class': 'MobileUser' },
        is_external_contractor: { operator: '=', value: 0, 'class': 'MobileUser' },
      };
    }
    this.subscription = this.route.params.subscribe(
      (params: Params) => {
        
        if (params.hasOwnProperty('id')) {
          this.userService.getById(params['id']).subscribe(data => {
            this.waitingParameterResolve = false;
            console.log(data.user);
            this.user = data.user;
            this.loadLocationsForUser();
          });
        } else {
          this.waitingParameterResolve = false;
          this.loadLocationsForUser();
        }
      }
    );
    this.initializeGoogleMaps();
  }

  initializeGoogleMaps() {
    if (!google || !google.maps) {
      console.error("Google Maps API not loaded.");
      return;
    }
    const mapOptions = {
      center: this.options.center,
      zoom: this.options.zoom
    };
    const mapElement = document.getElementById('googleMap');
    if (mapElement) {
      this.map = new google.maps.Map(mapElement, mapOptions);
      this.map.setCenter(this.options.center);
    }
  }

  private loadLocationsForUser() {
    this.getPage(1);
  }

  getPage(page: number) {
    this.params.page = page;
    let userId = (this.user) ? this.user.id  : 0;

    if(userId != 0) {
      this.asyncLocations = this.locationService.getAllLocationsForUserWithParams(
        this.params, userId
      ).pipe(

          tap((data: any) => {
            this.count = data.count;
            this.loading = false;
            this.currentPage = page;
          }),

          map( (data: any) => {
            if (data.locations) {
              for (const location of data.locations) {
                if (location.country_id) {
                  this.vigiRegionService.getVigiRegionsForCountryId(location.country_id).subscribe(
                    (data: any) => { 
                      location.regions = (data.vigiregions) ? data.vigiregions : [];
                    }
                  );
                }
              }
            }
            return data.locations;
          })
      );
    } else {
      this.asyncLocations = this.locationService.getAllLocationsForUsersWithParams(
        this.params
      ).pipe(

          tap((data: any) => {
            this.count = data.count;
            this.loading = false;
            this.currentPage = page;
          }),

          map( (data: any) => {
            if (data.locations) {
              for (const location of data.locations) {
                if (location.country_id) {
                  this.vigiRegionService.getVigiRegionsForCountryId(location.country_id).subscribe(
                    (data: any) => { 
                      location.regions = (data.vigiregions) ? data.vigiregions : [];
                    }
                  );
                }
              }
            }
            return data.locations;
          })
      );
    }

  }

  loadTransactionsLazy(event: LazyLoadEvent) {
    if (this.waitingParameterResolve) {
      return;
    }
    this.params.email.value = event.filters['email'] ? event.filters['email'].value : '';
    this.params.lastname.value = event.filters['lastname'] ? event.filters['lastname'].value : '';
    this.params.firstname.value = event.filters['firstname'] ? event.filters['firstname'].value : '';
    if (event.sortField) {
      this.params.order = ((event.sortOrder > 0) ? '-' : '') + event.sortField;
    }
    this.params.per_page = event.rows;
    this.getPage((event.first / event.rows) + 1);
  }

  onRowSelect() {
    this.showDialog();
  }

  handleOverlayClick(event) {
    console.log(event.overlay) // event.map, event.overlay, event.originalEvent
  }

  showDialog() {
    this.displayDialog = true;
    setTimeout(() => {
      this.initializeGoogleMaps();
      if (this.map) {
        console.log('on row select');
        
        const latitude = parseFloat(this.selectedLocation.latitude);
        const longitude = parseFloat(this.selectedLocation.longitude);
        let center = new google.maps.LatLng(latitude, longitude);
    
        const marker = new google.maps.Marker({
          position: center, 
          title: this.selectedLocation.country + ': ' + this.selectedLocation.city,
          map: this.map // 🛑 This line actually adds the marker to the map!
        });
    
        this.map.setCenter(center);
        this.map.setZoom(12); // Optional: Adjust zoom level when selecting a row
      } else {
        this.dialogCallback = this.onRowSelect;
      }
  
    }, 300); // Delay slightly to ensure the DOM updates
  }

  showCorporateUsers() {
    if (!this.showCorporateUsersTable) {
      this.showCorporateUsersTable = 1;
      this.params.is_external_contractor.value = 0;
      this.getPage(1);
    }
  }

  showSponsoredUsers() {
    if (this.showCorporateUsersTable) {
      this.showCorporateUsersTable = 0;
      this.params.is_external_contractor.value = 1;
      this.getPage(1);
    }
  }
  
}
