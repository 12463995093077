<div  id="timeFilterButtons" fxLayout="row" fxLayoutAlign="center center"
  fxLayoutGap="10px">
  <div style="position: absolute; z-index: 2; top:3px;" fxLayout="row" fxLayoutGap="5px">
    <button pButton label="Time filter" (click)="onTimeFilterButtonClick()"></button>
    <button pButton type="button" label="Corporate"
      [ngClass]="{'p-button-secondary': (this.userType == 'SPONSORED' || !this.userType)? true: false}"
      (click)="showCorporateUsers()">
    </button>
    <button pButton type="button" label="Sponsored"
      [ngClass]="{'p-button-secondary': (this.userType == 'CORPORATE' || !this.userType)? true: false}"
      (click)="showSponsoredUsers()">
    </button>
    <p-button icon="pi pi-question" (click)="onLegendQuestionClick()"></p-button>
    <button 
      pButton
      label="HAY"
      [ngClass]="{'p-button-secondary': (!this.isCursorActive)? true: false}"
      (click)="switchMapDrawingOnOff()">
    </button>
  </div>

</div>

<div id="legend" [style.visibility]="displayLegend? 'visible': 'hidden'" 
  style="padding:5px"
  fxLayoutAlign="center"
  style="position: fixed; left: 50%; margin-top: 35px; z-index: 2; background-color: #EEE;padding: 10px; border-radius:5px;">
  <app-poilegend 
  (timeRangeFilter)="onTimeRangeFilter($event)" 
  (geolocationTypeFilter)="onGeolocationTypeFilter($event)"
  [showColorCheckboxes]="true"
  [showGeolocationTypeCheckboxes]="true"
  >
  </app-poilegend>
</div>

<div id="mapgm" [style.visibility]="displayTimeFilterForm? 'visible': 'hidden'" 
  style="padding:5px"
  fxLayoutAlign="center"
  style="position: fixed; left: 30%; margin-top: 35px; z-index: 2; background-color: #EEE;padding: 10px; border-radius:5px;">
  <app-time-filter (onFilter)="onFilter($event)"></app-time-filter>
</div>

<app-pob-map 
  [bpiList]="bpiList"
  [showPanicModeInfoLink]="false"
>
</app-pob-map>
