import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { ConfirmDialogsService } from '../../_module_dialog/confirm_dialog/confirm-dialog.service';
import { GrowlService } from '../../_module_dialog/growl_dialog/growl.service';
import { SSOLogsService } from '../../models_services/sso_logs.service';
import { SSOLog } from '../../models/sso_log';
import { openFileForDownload } from '../../utils/file';
import { MobileUserService } from '../../models_services/mobile-users.service';

@Component({
    selector: 'app-sso-list',
    templateUrl: 'sso_list.component.html',
    styleUrls: ['sso_list.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SSOListComponent implements OnInit {
  userId: number = 0;
  asyncSSOLogs: Observable<SSOLog[]>;
  count: 0;
  currentPage = 1;
  loading: boolean;

  showDropDownForRow = -1;
  showHasPasswordInfoDialog = false;
  usersSelectItems: SelectItem[];

  // server side filter
  sortBy: string;
  orderAsc: string;
  params = {
    page: 1, per_page: 10, order: '-time',
    'type': { operator: '=', value: '' },
  };
  selectedSSOLogs: SSOLog[] = [];
  displayDialog: boolean = false;

  users_params = {
    page: 1, per_page: 10000, order: 'email',
    'phone': { operator: '%', value: '' },
    'firstname': { operator: '%', value: '' },
    'lastname': { operator: '%', value: '' },
    'email': { operator: '%', value: '' },
    'showNotRegistered': true,
    'showDeleted': false,
    'showCorporateUsers': true,
    'group_id': 0
  };

  constructor(
    private confirmDService: ConfirmDialogsService,
    private router: Router,
    private growlService: GrowlService,
    private ssoLogsService: SSOLogsService,
    private userService: MobileUserService) {
  }

  ngOnInit() {
    this.loadAllSSOLogs();
    this.getCorporateUsersWithoutSSOAutorization();
  }

  private loadAllSSOLogs() {
    this.getPage(1);
  }

  getPage(page: number) {
    this.params.page = page;
    this.asyncSSOLogs = this.ssoLogsService.getAllWithParams(this.params).pipe(
      tap((data: any) => {
        this.count = data.count;
        this.loading = false;
        this.currentPage = page;
      }),
      map((data: any) => {
        return data.sso_logs;
      })
    );
  }

  loadTransactionsLazy(event: LazyLoadEvent) {
    this.params.type.value = event.filters['type'] ? event.filters['type'].value : 0;
    if (event.sortField) {
      this.params.order = ((event.sortOrder > 0) ? '-' : '') + event.sortField;
    }
    console.log(this.params.type);
    console.log(event.rows);
    console.log(event.first);
    console.log(event.first / event.rows);
    this.params.per_page = event.rows;
    this.getPage((event.first / event.rows) + 1);
  }

  //CRUD methods
  removeItem(ssoLog: SSOLog) {
    // call confirmation dialog
    this.confirmDService.show('SSO log delete', 'Would you like to delete this SSO log ?')
      .subscribe(res => {
        const result = res;
        if (res) {
          console.log('deleting sso log confirmed: ' + JSON.stringify(ssoLog));
          this.ssoLogsService.delete(ssoLog.id).subscribe(
            () => {
              this.loadAllSSOLogs();
              this.growlService.showInfo('SSOLog is deleted.');
            },
            (error_data: any) => { this.growlService.showError(error_data.error.msg_const); });
        }
      });
  }

  bulkDeleteselectedSSOLogs(selectedSSOLogs) {
    console.log("delete selected logs");
    this.confirmDService.show('Delete selected SSO Logs', 'Are you sure that you want to delete selected SSO Logs ?')
      .subscribe(res => {
        const result = res;
        if (res) {
          console.log('bulk deleting sso log confirmed');
          this.ssoLogsService.deleteBulkOfSSOLogs(selectedSSOLogs).subscribe(
            () => {
              this.loadAllSSOLogs();
              this.growlService.showInfo('SSO Logs are deleted.');
              selectedSSOLogs.splice(0, selectedSSOLogs.length);
            },
            (error_data: any) => { this.growlService.showError(error_data.error.msg_const); });
        }
      });
  }

  downloadLogsCSV() {
    this.ssoLogsService.downloadSSOLogsCSV().subscribe(
      (data) => {
        openFileForDownload(data);
      },
      (error_data: any) => { this.growlService.showError(error_data.error.msg_const); }
    );
  }

  showDropDownOfCorporateUsers(ssoLog: SSOLog, rowIndex: number) {
    this.showDropDownForRow = rowIndex;
    console.log(ssoLog);
  }

  getCorporateUsersWithoutSSOAutorization() {
    this.userService.getAllWithParams(this.users_params).subscribe(
      (data) => {
        this.usersSelectItems = [];
        if (data.users) {
          this.usersSelectItems.push({ label: 'Select email...', value: null });
          data.users.forEach(user => {
            this.usersSelectItems.push({ label: user.email, value: user.id });
          });
        }
      },
      (error_data: any) => { this.growlService.showError(error_data.error.msg_const); }
    );
  }

  linkCorporateUserToSSOEmail(ssoLog: SSOLog) {
    this.userService.linkUsersByEmails(this.userId, ssoLog.id, ssoLog.sso_email).subscribe(
      (data: any) => {
        if (data.user) {
          this.growlService.showInfo('SSO email ' + ssoLog.sso_email + ' is now linked to: ' + data.user.email);
          this.showDropDownForRow = -1;
          // this.loadAllSSOLogs();
          this.getCorporateUsersWithoutSSOAutorization();
        }
      },
      (error_data: any) => { this.growlService.showError(error_data.error.msg_const); }
    )
  }

  checkSSOEmailSameAsAppEmail(ssoEmail, signupEmail){
    if(ssoEmail==signupEmail) {
      return 'Yes';
    }
    return 'No';
  }

  showHasPasswordInfo(){
    this.showHasPasswordInfoDialog = true;
  }


}
