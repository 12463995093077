import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { CountryService } from '../../../models_services/country.service';
import { fadeInAnimation } from '../../../_animations/fade-in.animation';
import { VIGISelectedCountryService } from '../selected-country.service';
import { FactSheetTypeService } from '../../../models_services/fact_sheet_types.service';

@Component({
    selector: 'app-fact-sheet',
    templateUrl: './fact_sheet.component.html',
    styleUrls: ['./fact_sheet.component.css'],
    // make fade in animation available to this component
    animations: [fadeInAnimation],
    // attach the fade in animation to the host (root) element of this component
    host: { '[@fadeInAnimation]': '' },
    standalone: false
})
export class FactSheetComponent implements OnInit {
  selectedCountryId: number = 0;
  countriesSI: SelectItem[];
  factSheetTypesSI: SelectItem[];

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private router: Router,
    private vigiSelectedCountryService: VIGISelectedCountryService,
    private countryService: CountryService,
    private factSheetTypeService: FactSheetTypeService
  ) {
    this.selectedCountryId = this.vigiSelectedCountryService.selectedCountryId;

    iconRegistry.addSvgIcon('left_page_switch', sanitizer.bypassSecurityTrustResourceUrl('assets/img/actions_svg/left_page_switch.svg'));
    iconRegistry.addSvgIcon('right_page_switch', sanitizer.bypassSecurityTrustResourceUrl('assets/img/actions_svg/right_page_switch.svg'));
  }

  ngOnInit() {
    this.getAllCountries();
  }

  getAllCountries() {
    this.countryService.getAll().subscribe(
      (data) => {
        this.countriesSI = []; 
        data.countries.forEach(c => {
          this.countriesSI.push({label: c.name, value: c.id});
        });
        this.vigiSelectedCountryService.setCountryId(this.selectedCountryId);
      }
    );
  }

  onCountryChange(){
    this.vigiSelectedCountryService.setCountryId(this.selectedCountryId);
  }

}
