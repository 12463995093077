import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GEOLOCATION_TIME_TYPES, GEOLOCATION_TYPES } from 'src/app/common/constants'; // Import the constants


export interface TimeFilterInterface {
  startTime: number;
  endTime: number;
}

@Component({
  selector: 'app-poilegend',
  templateUrl: './poilegend.component.html',
  styleUrls: ['./poilegend.component.css'],
  standalone: false
})
export class POILegendComponent implements OnInit {
  geolocationTypes: any;
  geolocationColors: any;
  @Input() showGeolocationTypeCheckboxes: boolean = false;
  @Input() showColorCheckboxes: boolean = false;
  @Output() timeRangeFilter = new EventEmitter<string[]>(); // Emit selected colors
  @Output() geolocationTypeFilter = new EventEmitter<string[]>(); // Emit selected colors
  ngOnInit() {

  }

  constructor() {
    this.geolocationTypes = [
      {
        'imgSrc': 'assets/img/poi_base32/mappanicicon32.png',
        'typeName': 'Panic Alert',
        'typeDescription': 'Geolocation',
        selected: false,
        types: [GEOLOCATION_TYPES.PANIC_GEOLOCATION.name]
      },
      {
        'imgSrc': 'assets/img/poi_base32/manual32.png',
        'typeName': 'Manual Check-in',
        'typeDescription': 'Geolocation',
        selected: false,
        types: [GEOLOCATION_TYPES.MANUAL_CHECK_IN.name]
      },
      {
        'imgSrc': 'assets/img/poi_base32/mapcheckinicon32.png',
        'typeName': 'Travel Arrival Check-in',
        'typeDescription': 'Geolocation',
        selected: false,
        types: [GEOLOCATION_TYPES.TRAVEL_ARRIVAL_CHECK_IN.name]
      },
      {
        'imgSrc': 'assets/img/poi_base32/mapbackgroundlocationicon32.png',
        'typeName': 'Back-ground Location',
        'typeDescription': 'Geolocation',
        selected: false,
        types: [
          GEOLOCATION_TYPES.BACKGROUND_CHECK_IN.name, 
          GEOLOCATION_TYPES.COUNTRY_GEOFENCING_CHECKIN.name
        ]
      },
      {
        'imgSrc': 'assets/img/poi_base32/mapiamfineicon32.png',
        'typeName': 'I’m fine',
        'typeDescription': 'Geolocation',
        selected: false,
        types: [GEOLOCATION_TYPES.I_AM_FINE.name]
      },
      {
        'imgSrc': 'assets/img/poi_base32/mapsosicon32.png',
        'typeName': 'SOS',
        'typeDescription': 'Geolocation',
        selected: false,
        types: [GEOLOCATION_TYPES.SOS_GEOLOCATION.name]
      }

    ];

    this.geolocationColors = [
      {
        'hex': '#007700',
        'description': 'Geolocation is less than 6 hours old',
        selected: false,
        type: GEOLOCATION_TIME_TYPES[0],
      },
      {
        'hex': '#ff7351',
        'description': 'Geolocation is between 6 and 12 hours old',
        selected: false,
        type: GEOLOCATION_TIME_TYPES[1],
      },
      {
        'hex': '#ff351f',
        'description': 'Geolocation is between 12 to 48 hours old',
        type: GEOLOCATION_TIME_TYPES[2],
      },
      {
        'hex': '#6A6A6A',
        'description': 'Geolocation is more than 48 hours old',
        selected: false,
        type: GEOLOCATION_TIME_TYPES[3],
      },
    ];
  }

  onCheckboxChange() {
    const selectedColors = this.geolocationColors
      .filter(color => color.selected)
      .map(color => color.type);

    this.timeRangeFilter.emit(selectedColors);
  }

  onGeolocationTypeCheckboxChange() {
    const selectedTypes = this.geolocationTypes
      .filter(type => type.selected)
      .flatMap(type => type.types); // Flatten the array of arrays
  
    this.geolocationTypeFilter.emit(selectedTypes);
  }  

}
