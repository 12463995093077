import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';

@Component({
    selector: 'app-confirm-dialog',
    template: `
        <h3 mat-dialog-title>{{ title }}</h3>
        <p mat-dialog-content>{{ message }}</p>
        <div mat-dialog-actions>
            <button type="button" mat-raised-button  (click)="dialogRef.close(true)">OK</button>
        </div>
    `,
    standalone: false
})
/** The component is a basic html markup of the actual look of our dialog.
 * The dialog will be placed dead centre in a container with an overlay.
 *
 * Note that InfoDialogComponent has been added to the entryComponents array.
 * You need to add any component that is dynamically
 * generated by the component factory resolver to this array.
 */
export class InfoDialogComponent {

    public title: string;
    public message: string;

    constructor(public dialogRef: MatDialogRef<InfoDialogComponent>) {
    }

}
