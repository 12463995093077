import { Component, OnInit, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import MarkerClusterer from '@google/markerclusterer';
import { BasicPobInfo } from '../../../models/bpi';
import { GrowlService } from '../../../_module_dialog/growl_dialog/growl.service';
import { BackendUserService } from '../../../models_services/backend_user.service';
import { LocationI } from '../../../models/interface/locationI';
import { MapService } from '../../../models_services/map.service';
import { StoreService } from 'src/app/common/application.state';
import { Router } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';

declare var google: any; // Declare google maps API global object

@Component({
  selector: 'app-pob-map',
  templateUrl: './pob-map.component.html',
  styleUrls: ['./pob-map.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class PobMapComponent implements OnInit, OnDestroy {
  @Input() displayNavigationArrows = false;
  @Input() showPanicModeInfoLink: boolean;
  displayMarkerInfoDialog = false;
  isCursorActive = false;
  
  _bpiList: BasicPobInfo[];
  selectedBPI: BasicPobInfo;
  markersInfoMap: Map<number, BasicPobInfo>;
  markerCluster: MarkerClusterer;
  @Input() showPobsEmails: boolean = true;

  polygonDrawingEndResult: any;
  
  options = {
    center: {lat: 48.830772399902344, lng: 2.8097193241119385},
    zoom: 9
  };
  overlays = [];
  map: google.maps.Map; // Google Maps map object

  constructor(
    private growlService: GrowlService,
    private mapService: MapService,
    private backendUserService: BackendUserService,
    private storeService: StoreService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.handleOverlayClick = this.handleOverlayClick.bind(this);
  }

  ngOnInit() {
    this.mapService.nullMapService();
    this.subscribeToDrawingPolygon();

    if (!window['google']) {
      console.error('Google Maps API is not loaded');
      return;
    }
    this.initializeGoogleMap();
  }

  // Initialize Google Map
  initializeGoogleMap() {
    if (!google || !google.maps) {
      console.error("Google Maps API not loaded.");
      return;
    }
    const mapOptions = {
      center: this.options.center,
      zoom: this.options.zoom
    };
    const mapElement = document.getElementById('googleMap');
    if (mapElement) {
      this.map = new google.maps.Map(mapElement, mapOptions);
      this.map.setCenter(this.options.center);
  
      // setTimeout(() => {
      //   this.refreshMapPOIs();
      // }, 500);
    }
    this.cdr.detectChanges(); // Force Angular to update the view
  }
  

  @Input()
  set bpiList(bpiList: BasicPobInfo[]) {
    this._bpiList = bpiList;
    // Print the count of items in bpiList
    console.log('set bpiList(bpiList: BasicPobInfo[]) - Count of bpiList items:', bpiList?.length || 0);
    this.refreshMapPOIs();
  }

  refreshMapPOIs(){
    this.overlays.length = 0;
    if (!this._bpiList || this._bpiList.length === 0) {
      console.log("There are not locations to be displayed on the map");
    }
    
    if (this.map) {
        this.markersInfoMap = new Map<number, BasicPobInfo>();
      this.markerCluster = this.mapService.populateMapWithPath(
        this.map, 
        this.markersInfoMap, 
        this.overlays, 
        this.markerCluster,
        this._bpiList, 
        this.handleOverlayClick, 
        this.showPobsEmails
      );
    }
    
  }

  // handleOverlayClick(event) if it comes from primeng with (onOverlayClick)="handleOverlayClick($event)"
  // event.overlay.labelContent
  // But here the event comes from the spiderfier oms and receives the marker as parameter
  handleOverlayClick(marker) {
    const label: string = marker.labelContent;
    this.selectedBPI = this.markersInfoMap.get(+(label.split('.')[0]));
    this.displayMarkerInfoDialog = true;
    this.cdr.detectChanges();
  }

  onDialogClose() {
    this.displayMarkerInfoDialog = false;
  }

  moveNextLocation():void {
    const result: LocationI = this.mapService.moveNextLocation(this._bpiList);
    (result) ? this.map.setCenter(result) : this.options.center;
  }
  
  movePrevLocation ():void {
    const result: LocationI = this.mapService.movePrevLocation(this._bpiList);
    (result) ? this.map.setCenter(result) : this.options.center;
  }
  
  moveToLastLocation ():void {
    const result: LocationI = this.mapService.moveToLastLocation(this._bpiList);
    (result) ? this.map.setCenter(result) : this.options.center;
  }
  
  moveToFirstLocation ():void {
    const result: LocationI = this.mapService.moveToFirstLocation(this._bpiList);
    (result) ? this.map.setCenter(result) : this.options.center;
  }

  onCursorClick(){
    console.log("onCursorClick");
    this.isCursorActive = !this.isCursorActive;
    if (this.isCursorActive) {
      this.mapService.initDrawingManager(this.map);
    } else {
      this.mapService.deactivateDrawingManager();
      this.mapService.clearDrawingManager();
    }
  }

  subscribeToDrawingPolygon() {
    this.mapService.getPolygonDrawingEndObservable().subscribe({
      next: (result) => {
        this.polygonDrawingEndResult = result;
        console.log("PolygonDrawingEndResult: ");
        console.log(this.polygonDrawingEndResult);
        if (this.polygonDrawingEndResult && this.polygonDrawingEndResult.length > 0) {
          var selectedBPIsIds = []; 
          this.polygonDrawingEndResult.forEach(pdr => {
            const label: string = pdr.labelContent;
            let basicPobInfo = this.markersInfoMap.get(+(label.split('.')[0]))
            selectedBPIsIds.push(
              basicPobInfo.pob_id
            );
          });
          selectedBPIsIds.forEach(element => {
            console.log("selected bpi");
            console.log(element);
          });
          this.storeService.setHowAreYouPobIds(selectedBPIsIds);
          this.router.navigate(['/home/push_notification']);
        } else {
          this.growlService.showInfoText("No locations were found in that polygon");
          this.isCursorActive = false;
          this.mapService.deactivateDrawingManager();
          this.mapService.clearDrawingManager();
        }
      },
      error: (error) => {
        console.error('Error:', error);
      }
    });
  }

  ngOnDestroy() {
    if (this.map) {
      google.maps.event.clearInstanceListeners(this.map);
      this.map = null;
    }

    // Remove the map container from the DOM to prevent issues on reinitialization
    const mapElement = document.getElementById('googleMap');
    if (mapElement && mapElement.parentNode) {
        mapElement.parentNode.removeChild(mapElement);
    }
  }
  
}
