import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';
import { CountryService } from '../../../models_services/country.service';
import { fadeInAnimation } from '../../../_animations/fade-in.animation';
import { VIGISelectedCountryService } from '../selected-country.service';

@Component({
    selector: 'app-vigi-country',
    templateUrl: './vigi-country.component.html',
    styleUrls: ['./vigi-country.component.css'],
    // make fade in animation available to this component
    animations: [fadeInAnimation],
    // attach the fade in animation to the host (root) element of this component
    host: { '[@fadeInAnimation]': '' },
    standalone: false
})
export class VigiCountryComponent implements OnInit {
  left_link = null;
  right_link = 'vigi_region_descr';
  leftButtonDisabled = true;
  rightButtonDisabled = true;
  selectedCountryId: number = 0;
  countriesSI: SelectItem[];
  vigiSection: string;
  

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private router: Router,
    private vigiSelectedCountryService: VIGISelectedCountryService,
    private countryService: CountryService
  ) {
    this.selectedCountryId = this.vigiSelectedCountryService.selectedCountryId;

    iconRegistry.addSvgIcon('left_page_switch', sanitizer.bypassSecurityTrustResourceUrl('assets/img/actions_svg/left_page_switch.svg'));
    iconRegistry.addSvgIcon('right_page_switch', sanitizer.bypassSecurityTrustResourceUrl('assets/img/actions_svg/right_page_switch.svg'));
    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((val: NavigationEnd) => {
      if (val.url.indexOf('vigi_region_descr') > -1) {
        this.leftButtonDisabled = false;
        this.rightButtonDisabled = false;
        this.left_link = 'vigi_country_descr';
        this.right_link = 'vigi_country_map';
        this.vigiSection = 'VIGI Regions';
      } else if (val.url.indexOf('vigi_country_map') > -1){
        this.leftButtonDisabled = false;
        this.rightButtonDisabled = true;
        this.left_link = 'vigi_region_descr';
        this.right_link = null;
        this.vigiSection = 'VIGI Map';
      } else if (val.url.indexOf('vigi_country_descr') > -1){
        this.leftButtonDisabled = true;
        this.rightButtonDisabled = false;
        this.left_link = null;
        this.right_link = 'vigi_region_descr';
        this.vigiSection = 'VIGI Country';
      } 
      console.log(val instanceof NavigationEnd);
      console.log(val);
    });
  }

  ngOnInit() {
    this.getAllCountries();
  }

  getAllCountries() {
    this.countryService.getAll().subscribe(
      (data) => {
        this.countriesSI = []; 
        data.countries.forEach(c => {
          this.countriesSI.push({label: c.name, value: c.id});
        });
        this.vigiSelectedCountryService.setCountryId(this.selectedCountryId);
      }
    );
  }

  onCountryChange(){
    this.vigiSelectedCountryService.setCountryId(this.selectedCountryId);
  }

}
