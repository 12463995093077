<div style="position: fixed; bottom: 15px; right: 15px; z-index: 2;">
  <button mat-fab color="primary" [matMenuTriggerFor]="menu1" matTooltip="Options">
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu #menu1="matMenu">
    <button mat-menu-item matTooltip="Create new mobile user" (click)="addItem()">
      <mat-icon>add</mat-icon> Create new mobile user
    </button>
    <button mat-menu-item matTooltip="Download mobile users CSV" (click)="downloadUsersCSV()">
      <mat-icon>file_download</mat-icon> Download CSV
    </button>
  </mat-menu>
</div>

<button pButton type="button" label="Corporate" 
  [ngClass]="{'p-button-secondary': !showCorporateUsersTable}"
  (click)="showCorporateUsers()">
</button>
<button pButton type="button" label="Sponsored" 
  [ngClass]="{'p-button-secondary': showCorporateUsersTable}"
  (click)="showSponsoredUsers()">
</button>
<button pButton type="button" label="Global map" [ngClass]="{'p-button-secondary': true}"
  (click)="showGlobalMap()"
  style="float:right;"
>
</button>


  <div class="row">
    <div class="medium-10 medium-offset-1 columns">
      <h2 class="subheader"></h2>

      <p-table 
      #dt
      [value]="asyncUsers | async" 
      [scrollable]="true" 
      [rows]="25" 
      [virtualScroll]="false" 
      [lazy]="true" 
      (onLazyLoad)="loadTransactionsLazy($event)"
      [paginator]="true"
      paginatorPosition="both"
      [rowsPerPageOptions]="[25, 50, 100, 250, 500]"
      currentPageReportTemplate="Showing {count} entries"
      [tableStyle]="{ 'width': '100%' }"
      selectionMode="multiple" 
      [(selection)]="selectedMobileUsers"
      dataKey="id"
      [totalRecords]="count"
      >
      <!-- <p-table #dt id="users_table" [value]="asyncUsers | async" [tableStyle]="{ 'width': '100%' }"
        [paginator]="true" [rowsPerPageOptions]="[25, 50, 100, 250, 500]"
         [rows]="25" autoLayout="true" 
        [lazy]="true" currentPageReportTemplate="Showing {count} entries" 
        (onLazyLoad)="loadTransactionsLazy($event)"
        [(selection)]="selectedMobileUsers" dataKey="id"
        scrollable="true" > -->

        <ng-template pTemplate="caption">
          <div class="table-caption">
            <!-- <p-checkbox 
              [(ngModel)]="params.showNotRegistered"
              [label]=" (showCorporateUsersTable)?  'Show incomplete registrations': 'Show unvalidated users'"
              binary="true" 
              (onChange)="showIncompleteRegs($event)">
            </p-checkbox> -->
            <p-checkbox 
              [(ngModel)]="params.showNotRegistered"
              [binary]="true"
              (onChange)="showIncompleteRegs($event)">
            </p-checkbox>
            <label>{{ showCorporateUsersTable ? '&nbsp; Show incomplete registrations' : '&nbsp; Show unvalidated users' }}</label>


            &nbsp;
            <button mat-icon-button (click)="downloadUsersCSV()">
              <fa-icon [icon]="faFileCsv" style="font-size: 40px;"></fa-icon>
            </button>
            <!-- <p-checkbox [(ngModel)]="params.showDeleted"
              [label]="'Show deleted users'"
              binary="false" (onChange)="showDeletedRegs($event)">
            </p-checkbox> -->
          </div>
        </ng-template>
        
        <ng-template pTemplate="header">
          <tr>
            <th>
              <p-tableHeaderCheckbox style="float:left"></p-tableHeaderCheckbox>
              <span>&nbsp; Actions</span>
            </th>
            <th>Validation</th>
            <th [pSortableColumn]="'date_created'">{{ 'Date created (GMT)'}}<p-sortIcon [field]="'date_created'"></p-sortIcon></th>
            <th [pSortableColumn]="'phone'">{{ 'Cell number'}}<p-sortIcon [field]="'phone'"></p-sortIcon></th>
            <th>{{ 'Given name'}}</th>
            <th>{{ 'Surname'}}</th>
            <th [pSortableColumn]="'email'">{{ 'VTS Email'}}<p-sortIcon [field]="'email'"></p-sortIcon></th>
            <!-- <th>{{ 'SSO Email'}}</th> -->
            <th>{{ 'Group'}}</th>
            <th>{{ 'Pole'}}</th>
            <th *ngIf="showCorporateUsersTable">{{ 'Division'}}</th>
            <th *ngIf="!showCorporateUsersTable">{{ 'Sponsor email'}}</th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>
              <input pInputText type="text" (input)="dt.filter($event.target.value, 'phone', 'equals')">
            </th>
            <th>
              <input pInputText type="text" (input)="dt.filter($event.target.value, 'firstname', 'equals')">
            </th>
            <th>
              <input pInputText type="text" (input)="dt.filter($event.target.value, 'lastname', 'equals')">
            </th>
            <th>
              <input pInputText type="text" (input)="dt.filter($event.target.value, 'email', 'equals')">
            </th>
            <th>
              <!-- Group -->
              <span (click)="$event.stopPropagation()">
                <p-dropdown appendTo="body" id="groupDropdown"
                  [options]="groupsSelectItems" 
                  (onChange)="dt.filter($event.value, 'group_id', 'equals')">
                </p-dropdown>
              </span>
            </th>
            <th>
              <!-- Pole -->
              <span (click)="$event.stopPropagation()">
                <p-dropdown appendTo="body"
                  [options]="polesSelectItems" 
                  (onChange)="dt.filter($event.value, 'pole_id', 'equals')">
                </p-dropdown>
              </span>
            </th>
            <th *ngIf="showCorporateUsersTable">
              <!-- Division -->
              <span (click)="$event.stopPropagation()">
                <p-dropdown appendTo="body"
                  [options]="divisionsSelectItems" 
                  (onChange)="dt.filter($event.value, 'division_id', 'equals')">
                </p-dropdown>
              </span>
            </th>
            <th *ngIf="!showCorporateUsersTable"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData" [ngClass]="{'deleted_flag': rowData.deleted === true}">
            <td>
              <ng-template pHeader>Actions</ng-template>
              <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
              &nbsp;
              <button 
                *ngIf="!params.showNotRegistered && showCorporateUsersTable"
                mat-icon-button pTooltip="Edit user" (click)="editItem(rowData)">
                  <i class="fa fa-edit" ></i>
              </button>
              <button mat-icon-button pTooltip="Delete user" (click)="removeItem(rowData)">
                  <i class="fa fa-trash" ></i>
              </button>
              <button mat-icon-button pTooltip="See user travels" (click)="navigateMobileUserTravels(rowData)">
                  <i class="fa fa-plane" ></i>
              </button>
              <button mat-icon-button pTooltip="See user locations table" (click)="navigateMobileUserLocations(rowData)">
                  <i class="fa fa-list-alt" ></i>
              </button>
              <button mat-icon-button pTooltip="See user device information" 
                (click)="showDeviceInfo(rowData)">
                <i class="fa fa-mobile" style="font-size: 32px !important;"></i>
              </button>
              <button mat-icon-button pTooltip="Unblock this user" 
                *ngIf="rowData.blocked"
                (click)="showUnblockUserDialog(rowData)">
                <i class="fa fa-ban text-warning" style="color:orange"></i>
              </button>
              <button mat-icon-button pTooltip="Block this user" 
                *ngIf="!rowData.blocked"
                (click)="showBlockUserDialog(rowData)">
                <i class="fa fa-ban text-info" ></i>
              </button>
              <!-- <button mat-icon-button pTooltip="Confirm user email" 
                *ngIf="rowData.status && rowData.status.indexOf('e') > -1"
                (click)="showEmailConfirmationDialog(rowData)">
                <b><span style="font-size: 28px; color:orange; vertical-align: sub;">e</span></b>
              </button>
              <button mat-icon-button pTooltip="Confirm user sponsor" 
                *ngIf="rowData.status && rowData.status.indexOf('s') > -1"
                (click)="showSponsorConfirmationDialog(rowData)">
                <b><span style="font-size: 28px; color:orange; vertical-align: sub;">s</span></b>
              </button> -->
              <button *ngIf="rowData.status && params.showNotRegistered 
                && (
                  rowData.status.length<3 || rowData.status.indexOf('e') > -1  ||  
                    rowData.status.indexOf('p') > -1  || rowData.status.indexOf('s') > -1 || 
                    rowData.status.indexOf('d') > -1)" 
                mat-icon-button pTooltip="Show incomplete registration info" (click)="showIncompleteRegInfo(rowData)">
                <i class="fa fa-info-circle" style="color:orange"></i>
              </button>
              <button
                mat-icon-button pTooltip="Show time map" (click)="showRealTimeMap(rowData)">
                <!-- <mat-icon svgIcon="actions:realtimemap" class="primary-color" ></mat-icon> -->
                <i class="fa fa-street-view" ></i>
              </button>

              <button mat-icon-button pTooltip="See user notification subscription" 
                (click)="showCountrySubscriptionInfo(rowData)">
                <i class="fa fa-paper-plane" ></i>
              </button>
              <button mat-icon-button pTooltip="Download user information" 
                (click)="downloadUserInfo(rowData)">
                <i class="fa fa-download" ></i>
              </button>
              <button mat-icon-button pTooltip="See responders info" 
                (click)="showRespondersInfo(rowData)">
                <i class="fa fa-reply-all"></i>
              </button>
            </td>
            <td>
              <ng-template pHeader>Validation</ng-template>
              <div style="display:inline-block">
                <table>
                  <tr>
                    <td><i class="fa fa-mobile" ></i></td>
                    <td><i class="fa fa-at" ></i></td>
                    <td><i class="fa fa-user" ></i></td>
                  </tr>
                  <tr>
                    <td><mat-checkbox (change)="onPhoneVerifyChange(rowData, $event)"
                      [checked]="(rowData.status.indexOf('P') > -1) ? true:false"></mat-checkbox></td>
                    <td><mat-checkbox (change)="onEmailVerifyChange(rowData, $event)"
                      [checked]="(rowData.status.indexOf('E') > -1) ? true:false"></mat-checkbox></td>
                    <td>
                      <mat-checkbox (change)="onSponsorDevisionVerifyChange(rowData, $event)"
                        [checked]="(rowData.status.indexOf('D') > -1 || rowData.status.indexOf('S') > -1) ? 1:0">
                      </mat-checkbox>
                    </td>
                  </tr>
                </table>
              </div>
            </td>
            <td>
              <ng-template pHeader>Date created (GMT)</ng-template>
              {{ rowData.date_created }}
            </td>
            
            <td>
              <ng-template pHeader>Cell Number</ng-template>
              {{ rowData.phone }}</td>
            <td>
              <ng-template pHeader>Given name</ng-template>
              {{ rowData.firstname }}
            </td>
            <td>
              <ng-template pHeader>Surname</ng-template>
              {{ rowData.lastname }}
            </td>
            <td>
              <ng-template pHeader>VTS Email</ng-template>
              {{ rowData.email }}
            </td>
            <!-- <td>
              <ng-template pHeader>SSO Email</ng-template>
              {{ rowData.email_identity_provider }}
            </td> -->
            <td>
              <ng-template pHeader>Group</ng-template>
              <span *ngIf="showCorporateUsersTable">{{ rowData.group?.name_en }}</span>
              <span *ngIf="!showCorporateUsersTable">{{ rowData.sponsor_group?.name_en }}</span>
            </td>
            <td>
              <ng-template pHeader>Pole</ng-template>
              <span *ngIf="showCorporateUsersTable">{{ rowData.pole?.name_en }}</span>
              <span *ngIf="!showCorporateUsersTable">{{ rowData.sponsor_pole?.name_en }}</span>
            </td>
            <td *ngIf="showCorporateUsersTable">
              <ng-template pHeader>Division</ng-template>
              {{ rowData.division?.name_en}}
            </td>
            <td *ngIf="!showCorporateUsersTable">
              {{ rowData.sponsor_email}}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <ul>
                <li *ngFor="let mobileUser of selectedMobileUsers" style="text-align: left">
                    ID{{mobileUser.id + ' - ' + mobileUser.email + ((mobileUser.firstname) ? (' - ' + mobileUser.firstname):'')  +  ((mobileUser.lastname) ? (' - ' + mobileUser.lastname) : '')}}
                </li>
            </ul>
            <button *ngIf="selectedMobileUsers && selectedMobileUsers.length > 0" 
              (click)="bulkDeleteMobileUsers(selectedMobileUsers)" >Delete selected users</button>
        </ng-template>
      </p-table>
    </div>
  </div>



<p-dialog header="Incomplete registration info" [(visible)]="displayInfoDialog" >
  <pre>{{ infoDialogStr }}</pre>
</p-dialog>

<p-dialog header="Alert subscription countries" 
  [(visible)]="displayAlertedCountriesDialog" >
    <div>
      <h3>List of countries</h3>
      <ul *ngIf="alertedCountries">
        <li *ngFor="let c of alertedCountries">
          {{c.name}}
        </li>
      </ul>
      <div *ngIf="!alertedCountries">
        No countries subscriptions are found.
      </div>
    </div>
</p-dialog>

<p-dialog header="" 
  [(visible)]="showRespondersInfoDialog" >
    <div>
      <h3>Responders</h3>
      <div 
        *ngIf="!requests || requests.length == 0; else requestsTable ">
          <td colspan="3">No responders are found for that user</td>
      </div>
      <ng-template #requestsTable>
        <p-table [value]="[{}]">
          <ng-template pTemplate="header">
            <tr>
              <th>First name</th><th>Last name</th><th>email</th><th>Status</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body">
            <tr *ngFor="let req of requests">
              <td>{{req.responder.firstname}}</td>
              <td>{{req.responder.lastname}}</td>
              <td>{{req.responder.email}}</td>
              <td>{{req.status}}</td>
            </tr>
          </ng-template>
        </p-table>
      </ng-template>
    </div>
</p-dialog>

<p-dialog header="Devices info" 
  [(visible)]="showDeviceInfoDialog" >
    <div>
      <h3>Devices</h3>
      <div 
        *ngIf="!selectedMobileUser?.a1_fb && !selectedMobileUser?.a2_fb && !selectedMobileUser?.i1_fb && !selectedMobileUser?.i2_fb; else devicesTable ">
          <td colspan="3">No devices are found for that user</td>
      </div>
      <ng-template #devicesTable>
        <p-table [value]="[{}]">
          <ng-template pTemplate="header">
              <tr>
                <th>OS type</th>
                <th>OS version</th>
                <th>Device type</th>
                <th>Installation date</th>
                <th>App version</th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body">
            <tr *ngIf="selectedMobileUser?.a1_fb">
              <td>{{selectedMobileUser.a1_os}}</td>
              <td>{{selectedMobileUser.a1_os_v}}</td>
              <td>{{selectedMobileUser.a1_d_t}}</td>
              <td>{{selectedMobileUser.a1_install_date}}</td>
              <td>{{selectedMobileUser.a1_app_version}}</td>
            </tr>
            <tr *ngIf="selectedMobileUser?.a2_fb">
              <td>{{selectedMobileUser.a2_os}}</td>
              <td>{{selectedMobileUser.a2_os_v}}</td>
              <td>{{selectedMobileUser.a2_d_t}}</td>
              <td>{{selectedMobileUser.a2_install_date}}</td>
              <td>{{selectedMobileUser.a2_app_version}}</td>
            </tr>
            <tr *ngIf="selectedMobileUser?.i1_fb">
              <td>{{selectedMobileUser.i1_os}}</td>
              <td>{{selectedMobileUser.i1_os_v}}</td>
              <td>{{selectedMobileUser.i1_d_t}}</td>
              <td>{{selectedMobileUser.i1_install_date}}</td>
              <td>{{selectedMobileUser.i1_app_version}}</td>
            </tr>
            <tr *ngIf="selectedMobileUser?.i2_fb">
              <td>{{selectedMobileUser.i2_os}}</td>
              <td>{{selectedMobileUser.i2_os_v}}</td>
              <td>{{selectedMobileUser.i2_d_t}}</td>
              <td>{{selectedMobileUser.i2_install_date}}</td>
              <td>{{selectedMobileUser.i2_app_version}}</td>
            </tr>
          </ng-template>
      </p-table>

      </ng-template>
    </div>
</p-dialog>

