<div>
  
    <p-tabs value="0">
      <p-tablist>
        <p-tab value="0">Location expiry days</p-tab>
        <p-tab value="1">Travels expiry days</p-tab>
        <p-tab value="2">Admin logs expiry days</p-tab>
      </p-tablist>
      <p-tabpanels>
        <p-tabpanel value="0"  >
          <form [formGroup]="locForm" (ngSubmit)="onLocExpirySubmit()">
            <div class="p-field">
              <input type="text" 
                id="locationExpirityDays" 
                pInputText 
                formControlName="locationExpirityDays" 
                placeholder="Database Locations expiry days"
              />
              <small *ngIf="locForm.get('locationExpirityDays').errors?.pattern" class="p-error">
                Only numeric values allowed
              </small>
            </div>
            <button type="submit" pButton label="Save" [disabled]="!locForm.valid">
            </button>
          </form>
        </p-tabpanel>

        <p-tabpanel value="1"  >
          <form [formGroup]="traForm" (ngSubmit)="onTraExpirySubmit()">
            <div class="p-field">
              <input type="text" 
                id="travelExpirityDays" 
                pInputText 
                formControlName="travelExpirityDays" 
                placeholder="Database Travels expiry days"
              />
              <small *ngIf="traForm.get('travelExpirityDays').errors?.pattern" class="p-error">
                Only numeric values allowed
              </small>
            </div>
            <button type="submit" pButton label="Save" [disabled]="!traForm.valid">
            </button>
          </form>
        </p-tabpanel>

        <p-tabpanel value="2"  >
          <form [formGroup]="alogForm" (ngSubmit)="onAdminLogsExpirySubmit()">
            <div class="p-field">
              <input type="text" 
                id="adminLogExpirityDays" 
                pInputText 
                formControlName="adminLogExpirityDays" 
                placeholder="Database admin logs expiry days"
              />
              <small *ngIf="alogForm.get('adminLogExpirityDays').errors?.pattern" class="p-error">
                Only numeric values allowed
              </small>
            </div>
            <button type="submit" pButton label="Save" [disabled]="!alogForm.valid">
            </button>
          </form>
        </p-tabpanel>
      </p-tabpanels>
    </p-tabs>
    
</div>
