import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { Growl } from './growl';
import { Message } from 'primeng/message';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
/**The growl service enables any component in the app to display alert messages at the top of the
 * page via the growl component. */
export class GrowlService {
    private subject = new Subject<any>();
    private keepAfterNavigationChange = false;

    constructor(
        private router: Router,
        private translateService: TranslateService,
    ) {
        // clear alert message on route change
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
                    this.subject.next(null);
                }
            }
        });
    }

    // this.msgs.push({severity:'info', summary:'Info Message', detail:'PrimeNG rocks'});
    // this.msgs.push({severity:'warn', summary:'Warn Message', detail:'There are unsaved changes'});
    // this.msgs.push({severity:'error', summary:'Error Message', detail:'Validation failed'});
    showMessage(message: Message, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next(message);
    }

    /** Using translation plan */
    showInfo(details: string, summary = 'COMPLETED', keepAfterNavigationChange = false) {
        if (!details) {
            console.error('ERROR, NO STRING CONSTANT TO TRANSLATE');
            return;
        }
        this.translateService.get([details, summary]).subscribe( (result_object) => {
            this.keepAfterNavigationChange = keepAfterNavigationChange;
            const message = { 'severity': 'info', 'summary': result_object[summary], 'detail': result_object[details] }
            this.subject.next(message);
        });
    }

    /** Is not using translation keys */
    showInfoText(details: string, summary = 'Completed', keepAfterNavigationChange = false, life=3000) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        const message = { 'severity': 'info', 'summary': summary, 'detail': details, 'life': life };
        this.subject.next(message);
    }

    showError( details: string, summary = 'ERROR', keepAfterNavigationChange = false, life=3000) {
        if (!details) {
            console.error('ERROR, NO STRING CONSTANT TO TRANSLATE');
            return;
        }
        this.translateService.get([details, summary]).subscribe( (result_object) => {
            this.keepAfterNavigationChange = keepAfterNavigationChange;
            const message = { 'severity': 'error', 'summary': result_object[summary], 'detail': result_object[details], 'life': life };
            this.subject.next(message);
        });
    }

    /** Is not using translation keys */
    showErrorText( details: string, summary = 'Error', keepAfterNavigationChange = false, life=3000) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        const message = { 'severity': 'error', 'summary': summary, 'detail': details, 'life': life };
        this.subject.next(message);
    }

    showWarn( details: string, summary = 'WARNING', keepAfterNavigationChange = false) {
        if (!details) {
            console.error('ERROR, NO STRING CONSTANT TO TRANSLATE');
            return;
        }
        this.translateService.get([details, summary]).subscribe( (result_object) => {
            this.keepAfterNavigationChange = keepAfterNavigationChange;
            const message = { 'severity': 'warn', 'summary': result_object[summary], 'detail': result_object[details] };
            this.subject.next(message);
        });
    }

    /** Is not using translation keys */
    showWarnText( details: string, summary = 'Warning', keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        const message = { 'severity': 'warn', 'summary': summary, 'detail': details };
        this.subject.next(message);
    }

    /**
     * getMessage() method that returns an Observable that is used by the growl component to subscribe to
     * notifications for whenever a message should be displayed
     */
    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}
