import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { GroupService } from '../../models_services/group.service';
import { PoleService } from '../../models_services/pole.service';
import { DivisionService } from '../../models_services/division.service';
import { Group } from '../../models/group';
import { Pole } from '../../models/pole';
import { Division } from '../../models/division';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
Chart.register(ArcElement, Tooltip, Legend);
import { VIGISelectedCountryService } from '../risk_assessment/selected-country.service';
import { CountryService } from 'src/app/models_services/country.service';
import { MobileUser } from 'src/app/models/mobile-user';
import { openFileForDownload, saveAsExcelFile } from 'src/app/utils/file';
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import { MobileUserService } from 'src/app/models_services/mobile-users.service';
import { GrowlService } from 'src/app/_module_dialog/growl_dialog/growl.service';
import { faFilePdf, faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { DeviceService } from 'src/app/models_services/device.service';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { InfoDialogsService } from 'src/app/_module_dialog/info_dialog/info-dialog.service';
import { LazyLoadEvent, SelectItem } from 'primeng/api';

var colors = [
  "#4d79a7", "#f28e2b","#e15659","#76b7b2","#58a14e","#edc949","#af79a1","#ff9da7","#9c755f","#bab0ab"
];
var emptyChartData = { 
  labels: [], 
  datasets: [{ 
    data:[],
    backgroundColor: [],
    hoverBackgroundColor: []
  }]
}

@Component({
    selector: 'app-statistics',
    templateUrl: './statistics.component.html',
    styleUrls: ['./statistics.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class StatisticsComponent implements OnInit {
  activeTabIndex: number = 0;
  faFilePdf = faFilePdf;
  faFileCsv = faFileCsv;

  groupsData: any;
  polesData: any;
  divisionsData: any;
  groupIds = [];
  poleIds = [];
  divisionIds = [];
  saveAsExcelFile = saveAsExcelFile;

  groups: Group[];
  poles: Pole[];
  divisions: Division[];

  groupIdGroupMap: Map<number, Group>;
  poleIdPoleMap: Map<number, Pole>;
  divisionIdDivisionMap: Map<number, Division>;

  groupsOptions = {};
  polesOptions = {};
  divisionsOptions = {};

  selectedCountryId: number = 0;
  countriesSI: SelectItem[];
  countrySubscribedUsers: MobileUser[];

  cols: any[];
  exportColumns: any[];

  osversion_dt_columns = [
    { field: 'os', header: 'OS' },
    { field: 'os_v', header: 'OS version' },  
    { field: 'count', header: 'Count' },    
  ];
  data_types_dt_columns = [
    { field: 'os', header: 'OS' },
    { field: 'd_t', header: 'Device type' },  
    { field: 'count', header: 'Count' },    
  ];
  os_app_v_dt_columns = [
    { field: 'os', header: 'OS' },
    { field: 'app_v', header: 'App version' },  
    { field: 'count', header: 'Count' },    
  ];
  os_version_app_v_dt_columns = [
    { field: 'os_v', header: 'OS Version' },
    { field: 'app_v', header: 'App version' },  
    { field: 'count', header: 'Count' },    
  ];
  os_v_app_v_stats_columns = [
    { field: 'os', header: 'OS' },
    { field: 'os_v', header: 'OS Version' },
    { field: 'app_v', header: 'App version' },  
    { field: 'count', header: 'Count' },    
  ]
  app_v_dt_columns = [
    { field: 'app_v', header: 'App version' },
    { field: 'count', header: 'Count' },    
  ];

  os_v_stats: any;
  d_t_stats: any;
  os_app_v_stats: any;
  os_v_app_v_stats: any;
  os_osv_app_v_stats: any;
  app_v_stats: any;

  constructor(
    private groupService: GroupService,
    private poleService: PoleService,
    private divisionService: DivisionService,
    private countryService: CountryService,
    private vigiSelectedCountryService: VIGISelectedCountryService,
    private userService: MobileUserService,
    private growlService: GrowlService,
    private deviceService: DeviceService,
    private infoDialogservice: InfoDialogsService
  ) {
    this.selectedCountryId = this.vigiSelectedCountryService.selectedCountryId;
  }

  ngOnInit() {
     this.getAllAccessibleGroups();
     this.getAllCountries();
     this.getCountrySubscribedUsers();
     this.getDeviceVStats();
     this.cols = [
      { field: 'date_created', header: 'Date created (GMT)', has_relation:false },
      { field: 'phone', header: 'Cell number', has_relation:false },
      { field: 'email', header: 'Email', has_relation:false },
      { field: 'email_identity_provider', header: 'Id. Pr. Email', has_relation:false },
      { field: 'group', field2: 'name_en', header: 'Group', has_relation:true, 
        has_alternative_field:true, alternative_field:'sponsor_group' },
      { field: 'pole', field2: 'name_en', header: 'Pole', has_relation:true, 
        has_alternative_field:true, alternative_field:'sponsor_pole' },
      { field: 'division', field2: 'name_en', header: 'Division', has_relation:true },
      { field: 'sponsor_email', header: 'Sponsor email', has_relation:false },
    ];
  }

  // exportOSVersions() {
  //   const doc = new jsPDF('l', 'mm', [500, 350]);
  //   (doc as any).autoTable({
  //     head: [head],
  //     body: body,
  //   })
  //   doc.save('Country subscribed users.pdf');
  // }

  exportCountrySubscribedUsersPdf() {
    const doc = new jsPDF('l', 'mm', [500, 350]);
    let head = [];
    let body = [];
    for (let index = 0; index < this.cols.length; index++) {
      const element = this.cols[index];
      head.push(element.header);
    }
    for (let index = 0; index < this.countrySubscribedUsers.length; index++) {
      const user = this.countrySubscribedUsers[index];
      var row = [];
      for (let index = 0; index < this.cols.length; index++) {
        const col = this.cols[index];
        if(col.has_relation){
          if(user[col.field]){
            row.push(user[col.field][col.field2]);
          } else {
            row.push(null);
          }
        } else {
          row.push(user[col.field]);
        }
        if (!row[row.length-1]) {
          if(col.has_alternative_field){
            if(col.has_relation){
              if(user[col.alternative_field]){
                row[row.length-1] = user[col.alternative_field][col.field2];
              } else {
                row[row.length-1] = null;
              }
            } else {
              row[row.length-1] = user[col.alternative_field];
            }
          }
        }
        if(col.alternative_field) {
          console.log('/' + index + '/' + row[row.length-1]);
        }
      }
      body.push(row);
    }
    console.log(head);
    console.log(body);
    (doc as any).autoTable({
      head: [head],
      body: body,
    })
    doc.save('Country subscribed users.pdf');
  }

  exportCountrySubscribedUsersCSV() {
    this.userService.downloadCountrySubscribedUsersCSV(this.selectedCountryId).subscribe(
      (data) => {
        openFileForDownload(data);
      },
      (error_data: any) => { this.growlService.showError(error_data.error.msg_const); }
    );
  }

  exportAllCountrySubscribedUsersCSV() {
    this.userService.downloadCountrySubscribedUsersCSV(0).subscribe(
      (data) => {
        openFileForDownload(data);
      },
      (error_data: any) => { this.growlService.showError(error_data.error.msg_const); }
    );
  }

  exportGroupStatsCSV() {
    this.groupService.exportAccessibleGroupsCSV().subscribe(
      (data) => {
        openFileForDownload(data);
      },
      (error_data: any) => { this.growlService.showError(error_data.error.msg_const); }
    );
  }

  exportCountryAlertedUsersPdf() {
    this.userService.exportCountryAlertedUsersPdf(this.selectedCountryId).subscribe(
      (data) => {
        openFileForDownload(data);
      },
      (error_data: any) => { this.growlService.showError(error_data.error.msg_const); }
    );
  }

  getAllCountries() {
    this.countryService.getAll().subscribe(
      (data) => {
        this.countriesSI = []; 
        data.countries.forEach(c => {
          this.countriesSI.push({label: c.name, value: c.id});
        });
        this.vigiSelectedCountryService.setCountryId(this.selectedCountryId);
      }
    );
  }

  onCountryChange(){
    this.vigiSelectedCountryService.setCountryId(this.selectedCountryId);
    this.getCountrySubscribedUsers();
    this.getCountryAlertedUsersPage(1);
  }

  getCountrySubscribedUsers(){
    this.countryService.getCountrySubscribedUsers(this.selectedCountryId).subscribe(
      (data)=> {
        this.countrySubscribedUsers = data.users;
      }
    );
  }

  getDeviceVStats(){
    this.deviceService.getDeviceOsVStats().subscribe(
      (data)=> {
        this.os_v_stats = data.os_v_stats;
        this.d_t_stats = data.d_t_stats;
        this.os_app_v_stats = data.os_app_v_stats;
        this.os_v_app_v_stats = data.os_v_app_v_stats;
        this.os_osv_app_v_stats = data.os_osv_app_v_stats;
        this.app_v_stats = data.app_v_stats;
      }
    );
  }

  getAllAccessibleGroups() {
    this.groupsData = JSON.parse(JSON.stringify(emptyChartData));
    this.groupService.getAllAccessibleGroups().subscribe(
      (data) => {
        this.groups = data.groups;
        this.groupIdGroupMap = new Map<number, Group>();
        this.groupIds = [];
        if (this.groups) {
          this.groups.forEach(element => { this.groupIdGroupMap.set(element.id, element);});
          this.loadGroupsChartData();
        }
      }
    );
  }

  loadGroupsChartData() {
    const groupIds = this.groups.map(g => g.id);
      this.groupService.getCountOfUsersForGroups(groupIds).subscribe(
        (data) => {
          let countsOfGroupUsers = data.groups_counts;
          if (countsOfGroupUsers) {
            for(let i = 0; i < countsOfGroupUsers.length; i++) {
              this.groupsData.labels.push(this.groupIdGroupMap.get(countsOfGroupUsers[i].group_id).name_en);
              this.groupsData.datasets[0].data.push(countsOfGroupUsers[i].count);
              this.groupsData.datasets[0].backgroundColor.push(colors[i%10]);
              this.groupsData.datasets[0].hoverBackgroundColor.push(colors[i%10]);
              this.groupIds.push(countsOfGroupUsers[i].group_id);
            }
          }
          this.groupsData = {...this.groupsData};
          // this.groupsOptions = this.getOptions(this.groupsData);
        }
      );
  }

  loadPolesChartData() {
    const polesIds = this.poles.map(g => g.id);
      this.poleService.getCountOfUsersForPoles(polesIds).subscribe(
        (data) => {
          let countsOfPoleUsers = data.poles_counts;
          if (countsOfPoleUsers) {
            for(let i = 0; i < countsOfPoleUsers.length; i++) {
              this.polesData.labels.push(this.poleIdPoleMap.get(countsOfPoleUsers[i].pole_id).name_en);
              this.polesData.datasets[0].data.push(countsOfPoleUsers[i].count);
              this.polesData.datasets[0].backgroundColor.push(colors[i%10]);
              this.polesData.datasets[0].hoverBackgroundColor.push(colors[i%10]);
              this.poleIds.push(countsOfPoleUsers[i].pole_id);
            }
            this.polesData = {...this.polesData};
            // this.polesOptions = this.getOptions(this.polesData);
          }
        }
      );
  }

  loadDivisionsChartData() {
    const divisionsIds = this.divisions.map(d => d.id);
    this.divisionService.getCountOfUsersForDivisions(divisionsIds).subscribe(
      (data) => {
        let countsOfDivisionsUsers = data.divisions_counts;
        if (countsOfDivisionsUsers) {
          for(let i = 0; i < countsOfDivisionsUsers.length; i++) {
            this.divisionsData.labels.push(this.divisionIdDivisionMap.get(countsOfDivisionsUsers[i].division_id).name_en);
            this.divisionsData.datasets[0].data.push(countsOfDivisionsUsers[i].count);
            this.divisionsData.datasets[0].backgroundColor.push(colors[i%10]);
            this.divisionsData.datasets[0].hoverBackgroundColor.push(colors[i%10]);
            this.divisionIds.push(countsOfDivisionsUsers[i].division_id);
          }
          this.divisionsData = { ...this.divisionsData };
          // this.divisionsOptions = this.getOptions(this.divisionsData);
        }
      }
    );
  }

  groupChartDataSelect(event) {
    console.log('Selected group index: '  +  event.element.index);
    const selectedGroupId = this.groupIds[event.element.index];
    console.dir(this.groupIdGroupMap);
    this.polesData = JSON.parse(JSON.stringify(emptyChartData));
    this.divisionsData = JSON.parse(JSON.stringify(emptyChartData));
    this.poleService.getPolesForGroups([new Group(selectedGroupId)]).subscribe(
      (data: any) => {
        this.poles = data.poles;
        this.poleIdPoleMap = new Map<number, Pole>();
        this.poleIds = [];
        if(this.poles) {
          this.poles.forEach(element => { this.poleIdPoleMap.set(element.id, element);});
          this.loadPolesChartData();
        }
      }
    );
  }

  polesChartDataSelect(event) {
    console.log('Selected pole index: '  +  event.element.index);
    const selectedPoleId = this.poleIds[event.element.index];
    this.divisionsData = JSON.parse(JSON.stringify(emptyChartData));
    this.divisionService.getDivisionsForPoles([new Pole(selectedPoleId)]).subscribe(
      (data: any) => {
        this.divisions = data.divisions;
        this.divisionIdDivisionMap = new Map<number, Division>();
        this.divisionIds = [];
        if(this.divisions) {
          this.divisions.forEach(element => { this.divisionIdDivisionMap.set(element.id, element);});
          this.loadDivisionsChartData();
        }
      }
    );
  }

  divisionsChartDataSelect(event) {
    console.log('Selected division index: '  +  event.element.index);
  }

  getOptions(data) {
    if (!data) {return;}
    let pieOptions = {
      events: [],
      animation: {
        duration: 500,
        easing: "easeOutQuart",
        onComplete: function () {
          var ctx = this.ctx;
          var chart = this;
          ctx.font = `${Chart.defaults.font.family} normal ${Chart.defaults.font.size}px`;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'bottom';
    
          data.datasets.forEach((dataset, datasetIndex) => {
            let meta = chart.getDatasetMeta(datasetIndex);
    
            meta.data.forEach((element, index) => {
              let model = element;
              let total = dataset.data.reduce((sum, value) => sum + value, 0);
              let mid_radius = (model.innerRadius + model.outerRadius) / 2;
              let mid_angle = model.startAngle + (model.endAngle - model.startAngle) / 2;  
              var x = mid_radius * Math.cos(mid_angle);
              var y = mid_radius * Math.sin(mid_angle);
    
              ctx.fillStyle = '#fff';
              if (index == 3){ // Darker text color for lighter background
                ctx.fillStyle = '#444';
              }
              var percent = String(Math.round(dataset.data[index]/total*100)) + "%";      
              //Don't Display If Legend is hide or value is 0
              if(dataset.data[index] != 0) {
                ctx.fillText(dataset.data[index], model.x + x, model.y + y);
                // Display percent in another line, line break doesn't work for fillText
                ctx.fillText(percent, model.x + x, model.y + y + 15);
              }
            });
          });               
        }
      },
      
    };
    return pieOptions;
  }

  
  // =================== Country subscription users tab ====================
  // =======================================================================
  params = {
    page: 1, per_page: 10, order: '-date_created'
  };
  countryAlertedUsers: Observable<MobileUser[]>;
  count: 0;
  currentPage = 1;
  loading: boolean;

  loadCountryAlertedUsersLazy(event: LazyLoadEvent) {
    if (event.sortField) {
      this.params.order = ((event.sortOrder > 0) ? '-' : '') + event.sortField;
    }
    this.params.per_page = event.rows;
    this.getCountryAlertedUsersPage((event.first / event.rows) + 1);
  }

  getCountryAlertedUsersPage(page: number){
    this.params.page = page;
    if (this.selectedCountryId) {
      this.countryAlertedUsers = this.userService.getCountryAlertedWithParams(
        this.params, this.selectedCountryId).pipe(
        tap((data: any) => {
          this.count = data.count;
          this.loading = false;
          this.currentPage = page;
        }),
        map(data => {
          return data.users;
        })
      );
    }
  }

  downloadCountryAlertedUsersCSV() {
    this.userService.downloadCountryAlertedUsersCSV(this.selectedCountryId
    ).subscribe(
      (data) => {
        openFileForDownload(data);
      },
      (error_data: any) => { this.growlService.showError(error_data.error.msg_const); }
    );
  }

  showCountrySubscribedUsersInfo() {
    this.infoDialogservice.show("Country Subscribers", "In this tab are listed the users who have subscribed for a country alerts. If user is subscribed to particular country, but is not in that country, he will also receive alerts sent to that country.");
  }

  showCountryAlertedUsersInfo() {
    this.infoDialogservice.show("Country Alerted Users", "In this tab you can find all users, that will be alerted when you send alert to the selected country. Here are listed users subscribed to the selected country, and users localized in the selected country.");
  }


}
