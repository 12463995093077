export const HOURS_6_MS = 3600000 * 6;
export const HOURS_12_MS = 3600000 * 12;
export const HOURS_48_MS = 3600000 * 48;

export const GEOLOCATION_TIME_TYPES = [
  { type: 'LESS_THAN_6_HOURS', description: 'Geolocation is less than 6 hours old' },
  { type: 'BETWEEN_6_AND_12_HOURS', description: 'Geolocation is between 6 and 12 hours old' },
  { type: 'BETWEEN_12_AND_48_HOURS', description: 'Geolocation is between 12 to 48 hours old' },
  { type: 'MORE_THAN_48_HOURS', description: 'Geolocation is more than 48 hours old' }
];

export const GEOLOCATION_TYPES = {
  I_AM_FINE: { name: 'I_AM_FINE', imageTypeName: 'mapiamfineicon' },
  SOS_GEOLOCATION: { name: 'SOS_GEOLOCATION', imageTypeName: 'mapsosicon' },
  PANIC_GEOLOCATION: { name: 'PANIC_GEOLOCATION', imageTypeName: 'mappanicicon' },
  MANUAL_CHECK_IN: { name: 'MANUAL_CHECK_IN', imageTypeName: 'manual' },
  BACKGROUND_CHECK_IN: { name: 'BACKGROUND_CHECK_IN', imageTypeName: 'mapbackgroundlocationicon' },
  COUNTRY_GEOFENCING_CHECKIN: { name: 'COUNTRY_GEOFENCING_CHECKIN', imageTypeName: 'mapbackgroundlocationicon' },
  TRAVEL_ARRIVAL_CHECK_IN: { name: 'TRAVEL_ARRIVAL_CHECK_IN', imageTypeName: 'mapcheckinicon' },
  COUNTRY_CHANGE_DETECTION: { name: 'COUNTRY_CHANGE_DETECTION', imageTypeName: 'mapcheckinicon' },
};
