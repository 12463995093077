import { environment } from '../environments/environment';
import { Injectable } from "@angular/core";

@Injectable()
export class AppConfig {
    public readonly production = environment.production;
    public readonly apiUrl = environment.apiUrl;
    public readonly siteUrl = environment.siteUrl;
    public readonly mobileUrl = environment.mobileUrl;
}

