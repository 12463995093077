<div style="position: fixed; bottom: 15px; z-index: 1; right: 15px;" fxLayout="row" fxLayoutGap="5px">
    <button mat-fab (click)="showGroupDialogToAdd()" 
        class="create_buttons"
        pTooltip="Create new group"
        *ngxPermissionsOnly="['GROUP_CRUD']"
    >
      <mat-icon style="font-size: 50px;" svgIcon="g_plus"></mat-icon>
    </button>
    <button mat-fab (click)="showPoleDialogToAdd()"
        class="create_buttons"
        pTooltip="Create new pole"
        *ngxPermissionsOnly="['POLE_CRUD']"
    >
      <mat-icon style="font-size: 50px;" svgIcon="p_plus"></mat-icon>
    </button>
    <button mat-fab (click)="showDivisionDialogToAdd()"
        class="create_buttons"
        pTooltip="Create new division" 
        *ngxPermissionsOnly="['DIVISION_CRUD']"
    >
      <mat-icon style="font-size: 50px;" svgIcon="d_plus"></mat-icon>
    </button>
    <button 
        mat-fab (click)="downloadEntitiesCSV()"
        pTooltip="Download entities CSV" >
      <mat-icon>file_download</mat-icon>
    </button>
    <button *ngIf="selectedNode" 
      mat-fab (click)="editSelectedNode()" 
      pTooltip="Edit selected node">
      <mat-icon>edit</mat-icon>
    </button>
    <button *ngIf="selectedNode" 
      mat-fab (click)="removeSelectedNode()" 
      pTooltip="Edit selected node">
      <mat-icon>delete</mat-icon>
    </button>
</div>

<div #scrollMe>

<p-tree 
    selectionMode="single"
    [(selection)]="selectedNode"
    (onNodeSelect)="nodeSelect($event)" (onNodeUnselect)="nodeUnselect($event)" 
    [value]="trees" layout="horizontal" >
</p-tree>

<p-dialog 
    appendTo="body" 
    header="Group details" 
    [(visible)]="groupDisplayDialog" 
      scrollable="true"  
    showEffect="fade" 
    [modal]="true" 
    [style]="{width: '400px'}"
    >
    <div class="p-grid p-fluid" *ngIf="group">
        <div class="p-col-12">
            <div class="p-col-4">
                <label for="group_name">Name</label>
            </div>
            <div class="p-col-8">
                <input pInputText id="group_name" [(ngModel)]="group.name_en" />
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-col-4"><label for="group_validation_email">Validation email</label></div>
            <div class="p-col-8">
                <input pInputText id="group_validation_email" 
                    [(ngModel)]="group.validation_email" required="required" />
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-col-4"><label for="group_domain">Domain</label></div> 
            <button class="self_width" type="button" pButton 
                icon="fa fa-plus" (click)="addGDomain()" label="Add">
            </button>
            <button class="self_width" type="button" pButton icon="fa fa-at" 
                (click)="uploadDomainsFile()" label="Upload">
            </button>
            <button class="self_width" type="button" pButton 
                icon="fa fa-download" 
                (click)="exportDomainsList()" 
                label="CSV"
            >
            </button>
            <!-- <button (click)="exportDomainsList()"
                style="color: red; border-radius: 5px; border-color: red; "
                pTooltip="Download domains as PDF" >
                <fa-icon [icon]="faDownload" style="font-size: 25px;"></fa-icon> 
                <b>&nbsp; PDF</b>
            </button> -->
            <div class="p-col-12" style="max-height: 200px; overflow: scroll;">
                <div *ngFor="let x of gdomains; let i = index; trackBy:trackByIndex;" >
                    <input [(ngModel)]="gdomains[i]" style="width: 290px;">
                    <button class="self_width" type="button" pButton icon="fa fa-minus" 
                        (click)="removeGDomain(i)">
                    </button>
                </div>
            </div>
        </div>
    </div>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix">
            <button type="button" pButton icon="fa fa-check" 
            (click)="saveGroup()" label="Save"></button>
        </div>
    </p-footer>
</p-dialog>

<p-dialog header="Pole details" [(visible)]="poleDisplayDialog"   scrollable="true"  
showEffect="fade" [modal]="false" [style]="{width: '400px'}">
    <div class="p-grid p-fluid" *ngIf="pole">
        <div class="p-col-12">
            <div class="p-col-4"><label for="pole_name">Name</label></div>
            <div class="p-col-8">
                <input pInputText id="pole_name" [(ngModel)]="pole.name_en" />
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-col-4"><label for="group">Group</label></div>
            <div class="p-col-8">
                <p-dropdown appendTo="body" [options]="groups" 
                    optionLabel="name_en"
                    [(ngModel)]="selectedGroup">
                </p-dropdown>
            </div>
        </div>
        
        <div class="p-col-12">
            <div class="p-col-4"><label 
                for="pole_auto_validating_mobile_users">
                Autovalidate sponsored users
            </label></div>
            <div class="p-col-8">
                <p-checkbox [(ngModel)]="pole.auto_validating_mobile_users" 
                binary="true"></p-checkbox>
            </div>
        </div>
    </div>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix">
            <button type="button" pButton icon="fa fa-check" (click)="savePole()" label="Save"></button>
        </div>
    </p-footer>
</p-dialog>

<p-dialog appendTo="body" header="Division details" [(visible)]="divisionDisplayDialog"   scrollable="true"  
showEffect="fade" [modal]="true" [style]="{width: '400px'}">
    <div class="p-grid p-fluid" *ngIf="division">
        <div class="p-col-12">
            <div class="p-col-4"><label for="vin">Name</label></div>
            <div class="p-col-8">
                <input pInputText id="vin" [(ngModel)]="division.name_en" maxlength="180"/>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-col-4"><label for="vin">Pole</label></div>
            <div class="p-col-8">
                <p-dropdown appendTo="body" [options]="poles" 
                    optionLabel="name_en"
                    [(ngModel)]="selectedPole">
                </p-dropdown>
            </div>
        </div>
    </div>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix">
            <button type="button" pButton icon="fa fa-check" (click)="saveDivision()" label="Save"></button>
        </div>
    </p-footer>
</p-dialog>

<p-dialog appendTo="body" header="Division edit" [(visible)]="editDivisionDisplayDialog"   scrollable="true"  
showEffect="fade" [modal]="true" [style]="{width: '400px'}">
    <div class="p-grid p-fluid" *ngIf="division">
        <div class="p-col-12">
            <div class="p-col-4"><label for="vin">Name</label></div>
            <div class="p-col-8">
                <input pInputText id="vin" [(ngModel)]="division.name_en" 
                maxlength="180"/>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-col-4"><label for="vin">Pole</label></div>
            <div class="p-col-8">
                <p-dropdown appendTo="body" [options]="editDivisionPoles" 
                    optionLabel="name_en"
                    [(ngModel)]="selectedPole">
                </p-dropdown>
            </div>
        </div>
    </div>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix">
            <button type="button" pButton icon="fa fa-check" (click)="saveDivision()" label="Save"></button>
        </div>
    </p-footer>
</p-dialog>

</div>
