import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ApplicationService } from '../../models_services/application.service';
import { AppText } from '../../models/app-text';
import { GrowlService } from '../../_module_dialog/growl_dialog/growl.service';
import { ConfirmDialogsService } from 'src/app/_module_dialog/confirm_dialog/confirm-dialog.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-appsettings',
    templateUrl: './appsettings.component.html',
    styleUrls: ['./appsettings.component.css'],
    standalone: false
})
export class AppsettingsComponent implements OnInit, AfterViewInit {
  smtpPassword: string;
  smtpEmail: string;
  tabIndex = 0;
  activeTab = 0;
  versionNumbers = [
    { name: 'Visible version', value: 1 },
    { name: 'Backup version', value: 0 }
  ];
  selectedVersionNumber: { name: string; value: number } | null = null;

  appTexts = [
    { header: 'FAQ', text: new AppText(), selected: true },
    { header: 'Support', text: new AppText(), selected: false },
    { header: 'Terms & Conditions', text: new AppText(), selected: false },
    { header: 'Use of Personal data', text: new AppText(), selected: false },
  ];

  constructor(
    private applicationService: ApplicationService,
    private growlService: GrowlService, 
    private confirmDialogsService: ConfirmDialogsService,
    private cdr: ChangeDetectorRef  // Add ChangeDetectorRef
  ) {
    
  }

  ngAfterViewInit() {}

  ngOnInit() {
    // Ensure selectedVersionNumber is initialized
    this.selectedVersionNumber = this.versionNumbers.find(v => v.value === 1) || this.versionNumbers[0];
  
    console.log("Initialized selectedVersionNumber:", this.selectedVersionNumber); // Debugging log
  
    this.applicationService.getSmtpSettings().subscribe((data: any) => {
      this.smtpPassword = data.password;
      this.smtpEmail = data.email;
    });
    this.getTexts();
  }
  

  getTexts() {
    if (!this.selectedVersionNumber) return;

    this.applicationService.getFAQ(this.selectedVersionNumber.value).subscribe(
      (data: any) => { 
        this.appTexts[0].text = { ...this.appTexts[0].text, ...data.app_text };
        this.cdr.detectChanges();
      }
    );
    this.applicationService.getSupport(this.selectedVersionNumber.value).subscribe(
      (data: any) => { 
        this.appTexts[1].text = { ...this.appTexts[1].text, ...data.app_text };
        this.cdr.detectChanges();
      }
    );
    this.applicationService.getTAndC(this.selectedVersionNumber.value).subscribe(
      (data: any) => { 
        this.appTexts[2].text = { ...this.appTexts[2].text, ...data.app_text };
        this.cdr.detectChanges();
      }
    );
    this.applicationService.getPersonalDataUse(this.selectedVersionNumber.value).subscribe(
      (data: any) => { 
        this.appTexts[3].text = { ...this.appTexts[3].text, ...data.app_text };
        this.cdr.detectChanges();
      }
    );
    console.log(this.appTexts);
  }

  onTabChange(event: any) {
    this.activeTab = event.index;
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 100); // Small delay to allow Quill to load
  }
  

  updateFAQ() {
    if (!this.selectedVersionNumber) {
      console.error("Error: selectedVersionNumber is undefined!");
      this.growlService.showErrorText('Error: Unable to update FAQ. Version number is missing.');
      return;
    }
  
    this.applicationService.updateFAQ(this.appTexts[0].text, this.selectedVersionNumber.value).subscribe({
      next: () => this.growlService.showInfoText('FAQ was updated'),
      error: (err) => {
        console.error("Failed to update FAQ:", err);
        this.growlService.showErrorText('Error updating FAQ. Please try again.');
      }
    });
  }
  
  updateSupport() {
    if (!this.selectedVersionNumber) {
      console.error("Error: selectedVersionNumber is undefined!");
      this.growlService.showErrorText('Error: Unable to update Support. Version number is missing.');
      return;
    }
  
    this.applicationService.updateSupport(
      this.appTexts[1].text, 
      this.selectedVersionNumber.value
    ).subscribe({
      next: (data) => {
        console.log(data);
        this.growlService.showInfoText('Support text was updated');
      },
      error: (err) => {
        console.error("Failed to update Support:", err);
        this.growlService.showErrorText('Error updating Support text. Please try again.');
      }
    });
  }
  
  updateTAndC() {
    if (!this.selectedVersionNumber) {
      console.error("Error: selectedVersionNumber is undefined!");
      this.growlService.showErrorText('Error: Unable to update Terms & Conditions. Version number is missing.');
      return;
    }
  
    this.applicationService.updateTAndC(this.appTexts[2].text, this.selectedVersionNumber.value).subscribe({
      next: () => this.growlService.showInfoText('Terms & Conditions text was updated'),
      error: (err) => {
        console.error("Failed to update Terms & Conditions:", err);
        this.growlService.showErrorText('Error updating Terms & Conditions. Please try again.');
      }
    });
  }
  
  updatePersonalData() {
    if (!this.selectedVersionNumber) {
      console.error("Error: selectedVersionNumber is undefined!");
      this.growlService.showErrorText('Error: Unable to update Personal Data. Version number is missing.');
      return;
    }
  
    this.applicationService.updatePersonalData(this.appTexts[3].text, this.selectedVersionNumber.value).subscribe({
      next: () => this.growlService.showInfoText('Use of Personal data was updated'),
      error: (err) => {
        console.error("Failed to update Personal Data:", err);
        this.growlService.showErrorText('Error updating Use of Personal Data. Please try again.');
      }
    });
  }
  
  updateEmailPassword() {
    this.applicationService.updateEmailPassword(this.smtpEmail, this.smtpPassword).subscribe({
      next: () => this.growlService.showInfoText('Sender email password was updated'),
      error: (err) => {
        console.error("Failed to update email password:", err);
        this.growlService.showErrorText('Error updating sender email password. Please try again.');
      }
    });
  }

  onVersionNumbers() {
    console.log('Selected version number:', this.selectedVersionNumber);
    this.getTexts();
  }

  restoreBackup() {
    this.confirmDialogsService.show(
      'Restore from backup',
      'This will replace the current visible mobile application texts with the backup texts. Do you confirm?',
      "Yes", "No"
    ).subscribe(res => {
      if (res) {
        this.applicationService.restoreFromBackup().subscribe(() => {
          this.growlService.showInfoText('Visible version was updated successfully');
          this.getTexts();
        });
      }
    });
  }
}
