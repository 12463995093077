import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { GrowlService } from '../../../_module_dialog/growl_dialog/growl.service';
import { Subscription } from 'rxjs';
import { BasicPobInfo } from '../../../models/bpi';
import { BackendUser } from '../../../models/backend-user';
import { BPIService } from '../../../models_services/bpi.service';
import dayjs from 'dayjs';  // Correct

import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TimeFilterInterface } from '../time-filter/time-filter.component';
import { MobileUserService } from 'src/app/models_services/mobile-users.service';

@Component({
    selector: 'app-map-real-time',
    templateUrl: './map-real-time.component.html',
    styleUrls: [],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class MapRealTimeComponent implements OnInit, OnDestroy {
  displayMarkerInfoDialog = false;
  displayTimeFilterForm = false;
  displayLegend = false;
  showTimeFilterButtons = false;
  private subscription: Subscription;
  deviceId: string;
  mobileUserId: number;
  selectedPob: BackendUser;
  bpiList: BasicPobInfo[];
  selectedBPI: BasicPobInfo;
  markersInfoMap: Map<number, BasicPobInfo>;
  selectedPobLocation: Location;
  timeFilterForm: UntypedFormGroup = new UntypedFormGroup({});
  currentYear: number;
  minYear: number;
  startTime = null;
  endTime = null;
  
  minDate: Date;
  maxDate: Date;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private growlService: GrowlService,
    private mobileUserService: MobileUserService,
    private bpiService: BPIService,
  ) {
    Date.prototype.toJSON = function () { return dayjs(this).format(); };
    this.currentYear = dayjs().year();
    this.minYear = this.currentYear - 60;

    this.minDate = new Date();
    let prevYear = dayjs().year() - 1;
    this.minDate.setFullYear(prevYear);
    this.minDate.setHours(0);
    this.minDate.setMinutes(0);

    this.maxDate = new Date();
    let nextYear = dayjs().year();
    this.maxDate.setFullYear(nextYear);
    this.maxDate.setHours(0);
    this.maxDate.setMinutes(0);
    this.maxDate.setDate((new Date()).getDate() + 1);
  }

  ngOnInit() {
    this.showTimeFilterButtons = true;
    this.initForm();

    this.subscription = this.route.params.subscribe(
      (params: Params) => {
        if (params.hasOwnProperty('id')) {
          this.mobileUserId = params['id'];
          this.mobileUserService.getById(this.mobileUserId).subscribe(
            (data) => {
              this.selectedPob = data.user;
            }
          );
          // limiting to 1 would get only the very last location of the user
          this.getBasicPobInfoLocations(this.mobileUserId, 1 , 'desc', true );
        }
      }
    );
  }

  private initForm() {
    let groupObject = {};
    groupObject = {
      startDate: new UntypedFormControl(null, Validators.required),
      endDate: new UntypedFormControl(null, Validators.required),
    };
    this.timeFilterForm = new UntypedFormGroup(groupObject);
  }

  getBasicPobInfoLocations(mobileUserId, limit, timeSortOrder, maxOverTime){
    this.bpiService.getBasicPobLocationsWithMaxOverLocationTime(mobileUserId, null, 
      this.startTime, this.endTime, limit, timeSortOrder, maxOverTime).subscribe(
        (data: any) => {
          this.bpiList = data.bpis;
          if (! this.bpiList || this.bpiList.length == 0) {
            this.growlService.showInfo("No locations are found for the selected criteria");
          }
          this.displayTimeFilterForm = false;
        },
        (error_data) => {
          this.growlService.showError(error_data.error.msg_const);
        }
      );
  }

  // handleOverlayClick(event) if it comes from primeng with (onOverlayClick)="handleOverlayClick($event)"
  // event.overlay.labelContent
  // But here the event comes from the spiderfier oms and receives the marker as parameter
  handleOverlayClick(marker) {
    const label: string = marker.labelContent;
    this.selectedBPI = this.markersInfoMap.get(+(label.split('.')[0]));
    this.displayMarkerInfoDialog = true;
  }

  onTimeFilterButtonClick() {
    this.displayTimeFilterForm = !this.displayTimeFilterForm;
  }

  onLegendQuestionClick() {
    this.displayLegend = !this.displayLegend;
  }

  onFilter(event: TimeFilterInterface) {
    this.displayTimeFilterForm = false;
    this.startTime = event.startTime;
    this.endTime = event.endTime;
    this.getBasicPobInfoLocations(
      this.mobileUserId, 
      null, 
      'desc', 
      false
    );
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.unsubscribe();
  }

  navigateToUserEditPage(mobileUserId: number){
    this.router.navigate(['/home/register', this.mobileUserId, 'edit']);
  }


}
