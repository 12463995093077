import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Division } from '../models/division';

@Injectable({providedIn: 'root'})
export class DivisionService {
    constructor(private httpClient: HttpClient, private config: AppConfig) { }

    getAllDivisions() {
        return this.httpClient.get<any>(this.config.apiUrl + '/divisions/all');
    }

    getAllAccessibleDivisions(poleId: number, editedUserId: number) {
        editedUserId = editedUserId? editedUserId : 0;
        return this.httpClient.get<any>(this.config.apiUrl + '/divisions/all_accessible/' + poleId + '/edited_user/' + editedUserId);
    }

    getDivisionsForPoles(poles){
        return this.httpClient.post(this.config.apiUrl + '/divisions/get_by_poles', {'poles': poles});
    }

    getCountOfUsersForDivisions(divisionIds: number[]) {
        return this.httpClient.post<any>(this.config.apiUrl + '/divisions/stats_for_users', {'division_ids': divisionIds});
    }

    // Get by ID
    getDivisionById(_id: number) {
        return this.httpClient.get<any>(this.config.apiUrl + '/divisions/' + _id);
    }
    
    // DELETE
    deleteDivision(_id: number) {
        return this.httpClient.delete(this.config.apiUrl + '/divisions/' + _id);
    }

    // UPDATE
    updateDivision(division: Division) {
        return this.httpClient.put(this.config.apiUrl + '/divisions/' + division.id, division);
    }
    // CREATE
    createDivision(division: Division) {
        return this.httpClient.post(this.config.apiUrl + '/divisions', division);
    }
}