import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, AbstractControl } from '@angular/forms';
import dayjs from 'dayjs';  // Correct


export interface TimeFilterInterface {
  startTime: number;
  endTime: number;
}

@Component({
    selector: 'app-time-filter',
    templateUrl: './time-filter.component.html',
    styleUrls: ['./time-filter.component.css'],
    standalone: false
})
export class TimeFilterComponent implements OnInit {
  @Output() onFilter = new EventEmitter();
  @Input() startDateInput = null;
  @Input() endDateInput = null;
  timeFilterForm: UntypedFormGroup = new UntypedFormGroup({});
  currentYear: number;
  minYear: number;
  minDate: Date | undefined;
  maxDate: Date | undefined;

  constructor() {
    Date.prototype.toJSON = function () { return dayjs(this).format(); };
    this.currentYear = dayjs().year();
    this.minYear = this.currentYear - 60;

    this.minDate = new Date();
    let prevYear = dayjs().year() - 1;
    this.minDate.setFullYear(prevYear);
    this.minDate.setHours(0);
    this.minDate.setMinutes(0);

    this.maxDate = new Date();
    let nextYear = dayjs().year();
    this.maxDate.setFullYear(nextYear);
    this.maxDate.setHours(0);
    this.maxDate.setMinutes(0);
    this.maxDate.setDate((new Date()).getDate() + 1);
  }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    let groupObject = {};
    console.log(this.startDateInput, " ", this.endDateInput);
    groupObject = {
      dates: new UntypedFormGroup({
        startDate: new UntypedFormControl((this.startDateInput)? this.startDateInput: null, Validators.required),
        endDate: new UntypedFormControl((this.endDateInput)? this.endDateInput: null, Validators.required),
      }, this.dateBeforeDate),
    };
    this.timeFilterForm = new UntypedFormGroup(groupObject);
  }

  // FormGroups extend AbstractControl
  dateBeforeDate = (groupControl: AbstractControl): { [key: string]: boolean } => {
    const startDate = groupControl.get('startDate')?.value;
    const endDate = groupControl.get('endDate')?.value;
  
    if (!startDate || !endDate) {
      return null;
    }
  
    const startDateTime = dayjs(startDate).toDate().getTime();
    const endDateTime = dayjs(endDate).toDate().getTime();
  
    return startDateTime < endDateTime ? null : { timeMismatch: true };
  }

  onSubmitTimeFilterForm() {
    const filterValue = this.timeFilterForm.value;
    let startDate = filterValue.dates?.startDate;
    let endDate = filterValue.dates?.endDate;

    if (startDate) {
      startDate = dayjs(startDate).startOf('minute').toDate(); // Keep local time
    }
    if (endDate) {
      endDate = dayjs(endDate).startOf('minute').toDate(); // Keep local time
    }

    this.onFilter.emit({
      startTime: startDate ? startDate.getTime() : null,
      endTime: endDate ? endDate.getTime() : null,
    });
    // console.log("before conversion", startDate, endDate);
    // console.log(startDate.toUTCString());
    // console.log(dayjs(startDate).utcOffset(0, true).format())
    
    // if (startDate) {
    //   const startUtcDate = new Date(Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate(), startDate.getUTCHours(), startDate.getUTCMinutes(), startDate.getUTCSeconds()));
    //   console.log("after conversion", startUtcDate);
    //   startDate = startUtcDate.getTime();
    // }
    // if (endDate) {
    //   const endUtcDate = new Date(Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate(), endDate.getUTCHours(), endDate.getUTCMinutes(), endDate.getUTCSeconds()));
    //   console.log("after conversion", endUtcDate);
    //   endDate = endUtcDate.getTime();
    // }
    // console.log("after conversion", startDate, endDate);
  }

  onClearDates() {
    this.timeFilterForm.reset();
    let dateFormGroup = this.timeFilterForm.get('dates') as UntypedFormGroup;
    let startDateFormInput = dateFormGroup.get('startDate');
    let endDateFormInput = dateFormGroup.get('endDate');
    if(this.startDateInput){
      startDateFormInput.setValue(this.startDateInput);
    }
    if(this.endDateInput){
      endDateFormInput.setValue(this.endDateInput);
    }
    if(this.startDateInput || this.endDateInput) {
      const filterValue = this.timeFilterForm.value;
      let startTime = (filterValue.dates && filterValue.dates.startDate) ? filterValue.dates.startDate.getTime() : null;
      let endTime = (filterValue.dates && filterValue.dates.endDate) ? filterValue.dates.endDate.getTime() : null;
      this.onFilter.emit({
        startTime: startTime,
        endTime: endTime,
      });
    } else {
      this.onFilter.emit({
        startTime: null,
        endTime: null,
      });
    }
  }

}
