<h3>Risk level definitions</h3>
<div *ngIf="vigidescr">
  <p-tabs value="0"
    (onChange)="onTabChange()" [style]="{'max-width':'100%'}">
    <p-tablist>
      <p-tab value="0" headerClass="low-risk-tab">Low Risk</p-tab>
      <p-tab value="1" headerClass="moderate-risk-tab">Moderate Risk</p-tab>
      <p-tab value="2" headerClass="high-risk-tab">High Risk</p-tab>
      <p-tab value="3" headerClass="very-high-risk-tab">Very High Risk</p-tab>
      <p-tab value="4" headerClass="war-zone-tab">Extreme risk</p-tab>
    </p-tablist>
    <p-tabpanels>
      <p-tabpanel value="0" >
        <div fxLayout="column">
          <div fxLayout="column"><span>Description EN</span>
            <p-editor [(ngModel)]="vigidescr.b_descr_en" [style]="{'height':'200px'}"></p-editor>
          </div>
          <div fxLayout="column"><span>Description FR</span>
            <p-editor [(ngModel)]="vigidescr.b_descr_fr" [style]="{'height':'200px'}"></p-editor>
          </div>
        </div>
      </p-tabpanel>
      <p-tabpanel value="1" >
        <div fxLayout="column">
          <div fxLayout="column"><span>Description EN</span>
            <p-editor [(ngModel)]="vigidescr.y_descr_en" [style]="{'height':'200px'}"></p-editor>
          </div>
          <div fxLayout="column"><span>Description FR</span>
            <p-editor [(ngModel)]="vigidescr.y_descr_fr" [style]="{'height':'200px'}"></p-editor>
          </div>
        </div>
      </p-tabpanel>
      <p-tabpanel value="2" >
        <div fxLayout="column">
          <div fxLayout="column"><span>Description EN</span>
            <p-editor [(ngModel)]="vigidescr.o_descr_en" [style]="{'height':'200px'}"></p-editor>
          </div>
          <div fxLayout="column"><span>Description FR</span>
            <p-editor [(ngModel)]="vigidescr.o_descr_fr" [style]="{'height':'200px'}"></p-editor>
          </div>
        </div>
      </p-tabpanel>
      <p-tabpanel value="3" >
        <div fxLayout="column">
          <div fxLayout="column"><span>Description EN</span>
            <p-editor [(ngModel)]="vigidescr.r_descr_en" [style]="{'height':'200px'}"></p-editor>
          </div>
          <div fxLayout="column"><span>Description FR</span>
            <p-editor [(ngModel)]="vigidescr.r_descr_fr" [style]="{'height':'200px'}"></p-editor>
          </div>
        </div>
      </p-tabpanel>
      <p-tabpanel value="4" >
        <div fxLayout="column">
          <div fxLayout="column"><span>Description EN</span>
            <p-editor [(ngModel)]="vigidescr.k_descr_en" [style]="{'height':'200px'}"></p-editor>
          </div>
          <div fxLayout="column"><span>Description FR</span>
            <p-editor [(ngModel)]="vigidescr.k_descr_fr" [style]="{'height':'200px'}"></p-editor>
          </div>
        </div>
      </p-tabpanel>
    </p-tabpanels>

    
  </p-tabs>

  <p-button label="Save" type="button" (click)="saveDescr()"></p-button>
</div>
