import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class FactSheetTypeService {
    constructor(private httpClient: HttpClient, private config: AppConfig) { }

    getAllFactSheetTypes() {
        return this.httpClient.get<any>(this.config.apiUrl + '/fact_sheets_types/all');
    }

}
