import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-single-image-dialog',
    template: `
        <h3 mat-dialog-title>{{ title }}</h3>
        <p mat-dialog-content>
            <img  style="width:100%" [src]="imageUrl" alt="{{ title }}">
        </p>
        <div mat-dialog-actions>
            <button type="button" mat-button (click)="dialogRef.close()">Close</button>
        </div>
    `,
    standalone: false
})
/**
 * The dialog will be placed dead center in a container with an overlay.
 *
 * Note that SingleImageDialogComponent has been added to the entryComponents array.
 * You need to add any component that is dynamically
 * generated by the component factory resolver to this array.
 */
export class SingleImageDialogComponent {

    public title: string;
    public imageUrl: SafeUrl;

    constructor(public dialogRef: MatDialogRef<SingleImageDialogComponent>) {
    }

}
