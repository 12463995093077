import { Observable } from 'rxjs';
import { UploadFileDialogComponent } from './upload-dialog.component';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
// import { ImageInterface } from './image.interface';
import { IAlbum } from 'ngx-lightbox';

@Injectable()
/**
 * The service will act as a wrapper for MatDialog to
 * simplify the process of calling the dialog and subscribing to it.
 */
export class UploadDialogsService {

    constructor(private dialog: MatDialog) { }

    /**
     * maxSize in MBs
     */
    public show(title: string, message: string, 
        fileExtension: string, imageUrl?: string, images?: any[], maxSize?: number)
    : MatDialogRef<UploadFileDialogComponent> {
        let dialogRef: MatDialogRef<UploadFileDialogComponent>;
        dialogRef = this.dialog.open(UploadFileDialogComponent, {
            // width: '400px',
        });
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;
        dialogRef.componentInstance.fileExtension = fileExtension; // like '.csv,.csv'
        dialogRef.componentInstance.maxSize = maxSize;

        if (images) {
            const galleryImages: IAlbum[] = [];
            for (const i of images){
                galleryImages.push({
                    'src': i.servingUrlImage,
                    'thumb': i.servingUrlImage ? (i.servingUrlImage + '=s64-c') : '',
                });
            }
            dialogRef.componentInstance.images = galleryImages;
        } else {
            dialogRef.componentInstance.imageUrl = imageUrl;
        }
        return dialogRef;
    }
}
