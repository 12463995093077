
  <button pButton type="button" label="Corporate" *ngIf="!itIsSingleUserPage"
    [ngClass]="{'p-button-secondary': ((showCorporateUsersTable == 0)? true: false)}"
    (click)="showCorporateUsers()">
  </button>
  <button pButton type="button" label="Sponsored" *ngIf="!itIsSingleUserPage"
    [ngClass]="{'p-button-secondary': ((showCorporateUsersTable == 1)? true: false)}"
    (click)="showSponsoredUsers()">
  </button>


  <div class="row">
    <div class="medium-8 medium-offset-2 columns">
      <h2 class="subheader"></h2>

      <p-card header="User info" [style]="{width: '360px'}" [hidden]="!itIsSingleUserPage">
        <div>
          <label><b>Given name:</b></label>
           {{ user?.firstname }}
          <br />
          <label><b>Surname:</b></label>
           {{ user?.lastname }}
          <br />
          <label><b>Email:</b></label>
          {{ user?.email }}
        </div>
      </p-card>
      
      <p-table #dt [value]="asyncTravels | async" 
        [paginator]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        paginatorPosition="both" 
        [rows]="10" autoLayout="true" 
        [lazy]="true" currentPageReportTemplate="Showing {count} entries"
        [totalRecords]="count"
        (onLazyLoad)="loadTransactionsLazy($event)"   scrollable="true"  >
        <ng-template pTemplate="emptymessage" let-columns >
          <tr>
              <td [attr.colspan]="8"> No records found </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>{{ 'Date created'}}</th>
            <th>{{ 'Date of response'}}</th>

            <th [hidden]="itIsSingleUserPage" [pSortableColumn]="'firstname'">{{ 'Given name'}}
              <p-sortIcon [field]="'firstname'"></p-sortIcon>
            </th>
            <th [hidden]="itIsSingleUserPage" [pSortableColumn]="'lastname'">{{ 'Surname'}}
              <p-sortIcon [field]="'lastname'"></p-sortIcon>
            </th>
            <th [hidden]="itIsSingleUserPage" [pSortableColumn]="'email'">{{'Email'}}
              <p-sortIcon [field]="'email'"></p-sortIcon>
            </th>
            
            <th>{{ 'Alert type'}}</th>
            <!-- <th [pSortableColumn]="'departs_at'">{{ 'Date of departure'}}<p-sortIcon [field]="'departs_at'"></p-sortIcon></th> -->
            <th>{{ 'Feedback'}}</th>
            <th>{{ 'Latitude'}}</th>
            <th>{{ 'Longitude'}}</th>
          </tr>
          <tr>
            <!-- Date created -->
            <th></th>
            <!-- Date of response -->
            <th></th>

            <th [hidden]="itIsSingleUserPage">
              <input pInputText type="text" (input)="dt.filter($event.target.value, 'firstname', 'equals')">
            </th>
            <th [hidden]="itIsSingleUserPage">
              <input pInputText type="text" (input)="dt.filter($event.target.value, 'lastname', 'equals')">
            </th>
            <th [hidden]="itIsSingleUserPage">
              <input pInputText type="text" (input)="dt.filter($event.target.value, 'email', 'equals')">
            </th>
            
            <!-- Alert type -->
            <th></th>
            <!-- Feedback -->
            <th></th>
            <th></th><!-- Latitude -->
            <th></th><!-- Longitude -->
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">
            <td>
              <ng-template pHeader>Date created</ng-template>
              {{ rowData.alert_how_are_you.date_created }}
            </td>
            <td>
              <ng-template pHeader>Date of response</ng-template>
              {{ rowData.alert_how_are_you.date_of_response }}
            </td>

            <td [hidden]="itIsSingleUserPage">
              <ng-template pHeader>Given name</ng-template>
              {{ rowData?.mobile_user?.firstname }}
            </td>
            <td [hidden]="itIsSingleUserPage">
              <ng-template pHeader>Surname</ng-template>
              {{ rowData?.mobile_user?.lastname }}
            </td>
            <td [hidden]="itIsSingleUserPage">
              <ng-template pHeader>VTS Email</ng-template>
              {{ rowData?.mobile_user?.email }}
            </td>
            
            <td>
              <ng-template pHeader>Alert type</ng-template>
              {{ rowData.alert.type_str }}
            </td>
            <td>
              <ng-template pHeader>Feedback</ng-template>
              {{ rowData?.alert_how_are_you?.feedback_action_type_str }}
              <button *ngIf="rowData.alert_how_are_you.feedback_action_type_str == 'SOS'"
              mat-icon-button
              (click)="exportPanicInfo(rowData?.mobile_user, rowData?.location_details, rowData?.location)"
              style="color: red; border-radius: 5px; border-color: red; "
              pTooltip="Save Panic Info as PDF" >
                <fa-icon [icon]="faFilePdf" style="font-size: 25px;"></fa-icon> 
              </button>
            </td>
            <td>
              <ng-template pHeader>Latitude</ng-template>
              {{ rowData?.location?.latitude }}
            </td>
            <td>
              <ng-template pHeader>Longitude</ng-template>
              {{ rowData?.location?.longitude }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>


<p-dialog header="Location" [(visible)]="displayDialog"
  [style]="{width: '600px', height: '400px'}">
  <div id="googleMap" style="width: 600px; height: 400px;"></div>
</p-dialog>
