<button *ngxPermissionsOnly="['CREATE_AIRPORT']" 
  mat-fab (click)="addItem()" 
  style="position: fixed;bottom: 15px; right: 15px ; z-index: 1000"
  pTooltip="Create new airport">
  <mat-icon>add</mat-icon>
</button>

  <div class="row">
    <div class="medium-8 medium-offset-2 columns">
      <h2 class="subheader"></h2>
      
      <p-table #dt [value]="asyncAirports | async" [paginator]="true" 
        [rowsPerPageOptions]="[25, 50, 100, 250, 500]"
        paginatorPosition="both" [rows]="25" autoLayout="true" 
        [lazy]="true" currentPageReportTemplate="Showing {count} entries" 
        (onLazyLoad)="loadTransactionsLazy($event)"
        dataKey="id"
          scrollable="true" 
          [totalRecords]="count">
        
        <ng-template pTemplate="header">
          <tr>
            <th>
              {{'Actions' }}
            </th>
            <th>{{ 'VTS created'}}</th>
            <th>{{ 'ID'}}</th>
            <th [pSortableColumn]="'name'">{{ 'Name'}}
              <p-sortIcon [field]="'name'"></p-sortIcon>
            </th>
            <th>{{ 'City'}}</th>
            <th>{{ 'Country'}}</th>
            <th>{{ 'Latitude'}}</th>
            <th>{{ 'Longitude'}}</th>
            <th>{{ 'Timezone'}}</th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th>
              <input pInputText 
              type="text" 
              (input)="dt.filter($event.target.value, 'id', 'equals')">
            </th>
            <th>
              <input pInputText 
              type="text" 
              (input)="dt.filter($event.target.value, 'name', 'equals')">
            </th>
            <th>
              <input pInputText 
              type="text" 
              (input)="dt.filter($event.target.value, 'city_name', 'equals')">
            </th>
            <th>
              <input pInputText 
              type="text" 
              (input)="dt.filter($event.target.value, 'country', 'equals')">
            </th>
            <th></th>
            <th></th>
            <th>
              <input pInputText 
              type="text" 
              (input)="dt.filter($event.target.value, 'timezone', 'equals')">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">
            <td>
              <ng-template pHeader>Actions</ng-template>
              <button mat-icon-button pTooltip="Delete airport" 
                  (click)="removeItem(rowData)"
                  *ngIf="!rowData.deleted">
                  <i class="fa fa-trash" style="font-size: 24px;"></i>
              </button>
              <button mat-icon-button pTooltip="Edit airport" (click)="editItem(rowData)">
                <i class="fa fa-edit" style="font-size: 24px;"></i>
              </button>
            </td>
            <!-- VTS created -->
            <td>
              <ng-template pHeader>VTS created</ng-template>
              <span *ngIf="rowData.is_custom_created" style="color:green">
                <strong>Yes</strong>
              </span>
              <span *ngIf="!rowData.is_custom_created">No</span>
            </td>
            <!-- ID -->
            <td>
              <ng-template pHeader>ID</ng-template>
              {{ rowData.id }}
            </td>
            <!-- Name -->
            <td>
              <ng-template pHeader>Name</ng-template>
              {{ rowData.name }}
            </td>
            <!-- City -->
            <td>
              <ng-template pHeader>City</ng-template>
              {{ rowData.city_name }}
            </td>
            <!-- Country -->
            <td>
              <ng-template pHeader>Country</ng-template>
              {{ rowData.country }}
            </td>
            <!-- Latitude -->
            <td>
              <ng-template pHeader>Latitude</ng-template>
              {{ rowData.latitude }}
            </td>
            <!-- Longitude -->
            <td>
              <ng-template pHeader>Longitude</ng-template>
              {{ rowData.longitude }}
            </td>
            <!-- Timezone -->
            <td>
              <ng-template pHeader>Timezone</ng-template>
              {{ rowData.timezone }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>


