import { Injectable } from '@angular/core';

import { Country } from '../models/country';
import { AppConfig } from '../app.config';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class CountryService {
    constructor(private httpClient: HttpClient, private config: AppConfig) { }

    getAll() {
        return this.httpClient.get<any>(this.config.apiUrl + '/countries');
    }

    getAllWithParams(params: any) {
        let httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get<any>(this.config.apiUrl + '/countries', {
            params: httpParams,
            observe: 'body'
        });
    }

    getById(_id: number) {
        return this.httpClient.get<any>(this.config.apiUrl + '/countries/' + _id);
    }

    getCountrySubscribedUsers(countryId: number){
        return this.httpClient.get<any>(
            this.config.apiUrl + '/countries/' + countryId + '/subscribed_users');
    }
   
    create(country: Country) {
        return this.httpClient.post(this.config.apiUrl + '/countries', country);
    }

    update(entity: Country) {
        return this.httpClient.put(this.config.apiUrl + '/countries/' + entity.id, entity);
    }

    delete(_id: number) {
        return this.httpClient.delete(this.config.apiUrl + '/countries/' + _id);
    }

    saveImageForCountry(countryId: number, formData: FormData) {
        console.log(formData);
        return this.httpClient.post(this.config.apiUrl + '/countries/' + countryId + '/image', formData, {
            headers: new HttpHeaders().set('Content-Type', 'multipart/form-data')
        });
    }

}
