import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Injectable } from '@angular/core';

@Injectable()
/**
 * The service will act as a wrapper for MdDialog to
 * simplify the process of calling the dialog and subscribing to it.
 */
export class ConfirmDialogsService {

    constructor(private dialog: MatDialog) { }

    public show(title: string, message: string, ok?: string, cancel?: string): Observable<boolean> {
        let dialogRef: MatDialogRef<ConfirmDialogComponent>;
        dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
        });
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;
        if(ok) {
            dialogRef.componentInstance.ok = ok;
        }
        if(cancel) {
            dialogRef.componentInstance.cancel = cancel;
        }
        return dialogRef.afterClosed(); // emits event when the dialog is closed
    }
}
