


  <div class="row">
    <div class="medium-8 medium-offset-2 columns">
      <h2 class="subheader"></h2>
      
      <p-table #dt [value]="asyncLogDevicePanics | async" [paginator]="true" [rowsPerPageOptions]="[25, 50, 100, 250, 500]"
        paginatorPosition="both" [rows]="25" autoLayout="true" [lazy]="true" currentPageReportTemplate="Showing {count} entries" 
        (onLazyLoad)="loadTransactionsLazy($event)"
        [(selection)]="selectedSSOLogs" dataKey="id"
          scrollable="true" 
          [totalRecords]="count">

        <ng-template pTemplate="caption">
          Mobile users panics
          <button mat-icon-button 
            (click)="downloadPanicsLogsFromDevices()">
            <fa-icon [icon]="faFileCsv" style="font-size: 40px;"></fa-icon>
          </button>
        </ng-template>
        
        <ng-template pTemplate="header">
          <tr>
            <th [pSortableColumn]="'time'">
              {{ 'Date created (GMT))'}}
              <p-sortIcon [field]="'time'"></p-sortIcon>
            </th>
            <th>{{ 'Mobile user email'}}</th>
            <th>{{ 'Group'}}</th>
            <th>{{ 'Pole'}}</th>
            <th>{{ 'Responder was emailed'}}</th>
            <th>{{ 'Responder email error'}}</th>
            <th>{{ 'Responder emails'}}</th>
            <th>{{ 'Type of panic'}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
          <tr [pSelectableRow]="rowData">
            <td>
              <span class="p-column-title">
                {{ 'Date created (GMT)' }}
              </span>
              {{ rowData.date_created }}
            </td>
            <td>
              <span class="p-column-title">
                {{ 'Mobile user email' }}
              </span>
              {{ rowData.email }}
            </td>
            <td>
              <span class="p-column-title">
                {{ 'Group' }}
              </span>
              {{ rowData.mobileuser?.group?.name_en }}
            </td>
            <td>
              <span class="p-column-title">
                {{ 'Pole' }}
              </span>
              {{ rowData.mobileuser?.pole?.name_en }}
            </td>
            <td>
              <span class="p-column-title">
                {{ 'Responder was emailed' }}
              </span>
              <span *ngIf="rowData.responder_was_emailed">Yes</span>
              <span *ngIf="!rowData.responder_was_emailed">No</span>
            </td>
            <td>
              <span class="p-column-title">
                {{ 'Responder email error' }}
              </span>
              {{ rowData.responder_was_emailed_error }}
            </td>
            <td>
              <span class="p-column-title">
                {{ 'Responder emails' }}
              </span>
              {{ rowData.comma_separated_responders_emails }}
            </td>
            <td>
              <span class="p-column-title">
                {{ 'Responder emails' }}
              </span>
              {{ rowData.type_of_panic }}
            </td>

            
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <ul>
                <li *ngFor="let l of selectedSSOLogs" style="text-align: left">
                    Date {{l.date_created}} - SSO email: {{l.sso_email}}
                </li>
            </ul>
            <button *ngIf="selectedSSOLogs && selectedSSOLogs.length > 0" 
              (click)="bulkDeleteselectedSSOLogs(selectedSSOLogs)">Delete selected logs</button>
        </ng-template>
      </p-table>
    </div>
  </div>



<p-dialog header="Has password info" [(visible)]="showHasPasswordInfoDialog" [style]="{width: '50vw'}">
  <p>
    In almost all cases the value must be 'No'. 
    Possible reason for showing 'Yes', could be if the user is using an other device where the previous successful 
    SSO login authorization is not saved. 
    Other reason could be stolen SSO credentials, when the attacker uses same email as the SSO email, 
    while the email is different for the app.
    The password is autogenerated password and is used only on VTS side. It doesn't have connection with the password used on SSO.
  </p>
  <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" (click)="showHasPasswordInfoDialog=false" 
      label="Ok" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
