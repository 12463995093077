import { Component, OnInit } from '@angular/core';
import { GrowlService } from '../../_module_dialog/growl_dialog/growl.service';
import { Division } from '../../models/division';
import { Pole } from '../../models/pole';
import { Group } from '../../models/group';
import { PoleService } from '../../models_services/pole.service';
import { GroupService } from '../../models_services/group.service';
import { DivisionService } from '../../models_services/division.service';
import { BackendUser } from '../../models/backend-user';
import { TreeNode } from 'primeng/api';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmDialogsService } from '../../_module_dialog/confirm_dialog/confirm-dialog.service';
import { MyPermissionsService } from '../../_services/myx-permissions.service';
import { UploadDialogsService } from '../../_module_dialog/upload_dialog/upload-dialog.service';
import { UploadFileDialogComponent } from '../../_module_dialog/upload_dialog/upload-dialog.component';
import { DomainService } from '../../models_services/domain.service';
import { openFileForDownload } from '../../utils/file';
import { faFilePdf, faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { jsPDF } from "jspdf";
import 'jspdf-autotable'

import { saveAs } from 'file-saver';

@Component({
    selector: 'app-properties',
    templateUrl: './properties.component.html',
    styleUrls: ['./properties.component.css'],
    standalone: false
})
export class PropertiesComponent implements OnInit {
  faFilePdf = faFilePdf;
  currentUser: BackendUser;
  currentUserRole: string;
  trees: TreeNode[] = [];
  selectedNode: TreeNode;
  canCRUDGroup = false;
  canCRUDPole = false;
  canCRUDDivision = false;

  // division edit
  editDivisionDisplayDialog = false;
  editDivisionPoles: Pole[] = [];
  editDivisionGroup: Group;
  
  // divisions
  divisionDisplayDialog: boolean;
  division: Division = new Division();
  selectedDivision: Division;
  newDivision: boolean;
  divisions: Division[] = [];

  // pole
  poleDisplayDialog: boolean;
  pole: Pole = new Pole(0);
  selectedPole: Pole;
  newPole: boolean;
  poles: Pole[] = [];
  polesMap:Map<number, Pole> = new Map<number, Pole>();
  gdomains: string[] = [];

  // group
  groupDisplayDialog: boolean;
  group: Group = new Group(0);
  selectedGroup: Group;
  newGroup: boolean;
  groups: Group[] = [];

  uploadDialogRef: MatDialogRef<UploadFileDialogComponent>;


  constructor(
    private divisionService: DivisionService,
    private poleService: PoleService,
    private groupService: GroupService,
    private growlService: GrowlService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private confirmDialogsService: ConfirmDialogsService,
    private _permissionService: MyPermissionsService,
    private uploadDialogService: UploadDialogsService,
    private domainService: DomainService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('current_user'));
    this.currentUserRole = this.currentUser.role;

    iconRegistry.addSvgIcon('p_plus', sanitizer.bypassSecurityTrustResourceUrl('assets/img/actions_svg/p_plus1.svg'));
    iconRegistry.addSvgIcon('g_plus', sanitizer.bypassSecurityTrustResourceUrl('assets/img/actions_svg/g_plus1.svg'));
    iconRegistry.addSvgIcon('d_plus', sanitizer.bypassSecurityTrustResourceUrl('assets/img/actions_svg/d_plus1.svg'));
  }

  ngOnInit() {
    this._permissionService.checkForPermissions(
      ['GROUP_CRUD', 'POLE_CRUD', 'DIVISION_CRUD']
    ).then(
      results => {
        if(results[0]) { this.canCRUDGroup = true; }
        if(results[1]) { this.canCRUDPole = true; }
        if(results[2]) { this.canCRUDDivision = true; }
        this.loadTreeCollections();
      }
    );
  }

  uploadDomainsFile() {
    console.log('uploadDomainsFile()');
    this.uploadDialogRef = this.uploadDialogService.show(
      'Upload domains excel file', 'Select xlsx file', 'xlsx', '', null, 10);
    this.uploadDialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.uploadDialogRef.componentInstance.formData) {
          console.log('File is provided');
          this.domainService.extractDomainsFromXLSX(this.uploadDialogRef.componentInstance.formData).subscribe(
            (data: any) => {
              // TODO add the domains from data to the list of domains
              if (data.domains && data.domains.length > 0) {
                data.domains.forEach(value => {
                  if (this.gdomains.indexOf(value) < 0) this.gdomains.push(value);
                });
              }
              this.growlService.showInfo('Domains are inserted', 'Completed');
              if (data.not_all_imported) {
                this.growlService.showErrorText('Not all domains are imported, because some already belong to Pole');
              }
            },
            (error_data: any) => {
              if(error_data.error && error_data.error.msg_const) {
                this.growlService.showError(error_data.error.msg_const);
              } else {
                this.growlService.showErrorText('There was error during the insert of domains');
              }
            }
          );
        }
      }
    });
  }

  loadTreeCollections(){
    switch (this.currentUserRole) {
      case 'MASTER_ADMIN':
        this.groupService.getAllGroups().subscribe(
          (groups_data) => {
            this.groups = (groups_data.groups) ? groups_data.groups: [];
            this.poleService.getAllPoles().subscribe(
              (poles_data) => {
                this.poles = (poles_data.poles) ? poles_data.poles : [];
                if (this.poles) {
                  this.poles.forEach(element => { this.polesMap.set(element.id, element);});
                }
                this.divisionService.getAllDivisions().subscribe(
                  (divisions_data) => {
                    this.divisions = (divisions_data.divisions) ? divisions_data.divisions : [];
                    this.buildTree();
                  }
                );
              }
            );
          }
        );
        break;
      case 'GROUP_ADMIN':
        this.groupService.getGroupById(this.currentUser.group_id).subscribe(
          (group_data) => {
            this.groups = (group_data.group) ? [group_data.group]: [];
            this.poleService.getPolesForGroups(this.groups).subscribe(
              (poles_data: any) => {
                this.poles = (poles_data.poles) ? poles_data.poles : [];
                this.divisionService.getDivisionsForPoles(this.poles).subscribe(
                  (divisions_data: any) => {
                    this.divisions = (divisions_data.divisions) ? divisions_data.divisions : [];
                    this.buildTree();
                  }
                );
              }
            );
          }
        );
        break;
      case 'POLE_ADMIN':
        this.poleService.getPoleById(this.currentUser.pole_id).subscribe(
          (pole_data) => {
            this.poles = [pole_data.pole];
            this.groupService.getGroupById(pole_data.pole.group_id).subscribe(
              (group_data) => {
                this.groups = [group_data.group];
                this.divisionService.getDivisionsForPoles(this.poles).subscribe(
                  (divisions_data: any) => {
                    this.divisions = (divisions_data.divisions) ? divisions_data.divisions : [];
                    this.buildTree();
                  }
                );
              }
            );
          }
        );
        break;
      case 'DIVISION_ADMIN':
        this.divisionService.getDivisionById(this.currentUser.division_id).subscribe(
          (division_data) => {
            this.divisions = [division_data.division];
            this.poleService.getPoleById(division_data.division.pole_id).subscribe(
              (pole_data) => {
                this.poles = [pole_data.pole];
                this.groupService.getGroupById(pole_data.pole.group_id).subscribe(
                  (group_data) => {
                    this.groups = [group_data.group];
                    this.buildTree();
                  }
                );
              }
            );
          }
        );
        break;
      default:
        break;
    }
  }

  buildTree() {
    const groups_trees = [];
    this.groups.forEach(g => {
      const poles_trees = [];
      this.poles.forEach(p => {
        if(p.group_id == g.id) {
          const divisions_trees = [];
          this.divisions.forEach(d => {
            if (d.pole_id == p.id) {
              const division = {
                'label': `${d.name_en} ${(d.vinci_code)? '(' + d.vinci_code + ')': ''}`, 
                'selectable': (this.canCRUDDivision) ? true: false,
                'data': {'object': d, 'type': Division},
              };
              divisions_trees.push(division);
            }
          });
          const pole_tree = {
            'label': `${p.name_en} ${(p.vinci_code)? '(' + p.vinci_code + ')': '' }`, 
            'selectable': (this.canCRUDPole) ? true: false,
            'children': divisions_trees,
            'expanded': (divisions_trees.length > 0 && !this.canCRUDGroup) ? true: false,
            'data': {'object': p, 'type': Pole},
          };
          poles_trees.push(pole_tree);
        }
      });
      const group_tree = {
        'label': `${g.name_en} ${(g.last_sftp_was_success == -1)? '' : (g.last_sftp_was_success == 0)? '(Update '+ g.sftp_not_success_date +' error)':'(Update ' + g.sftp_date_updated + ' success)' }`, 
        'selectable': (this.canCRUDGroup) ? true: false,
        'children': poles_trees,
        'expanded': (poles_trees.length > 0) ? true: false,
        'data': {'object': g, 'type': Group},
      }
      groups_trees.push(group_tree);
    });

    const root_tree: TreeNode = {
      'label': 'Groups',
      'children': groups_trees,
      'selectable': false,
      'expanded': true
    };
    this._processTreeNodes(groups_trees, root_tree);

    this.trees.push(root_tree);

    console.log(this.trees);
  }

  private _processTreeNodes(treeNode: TreeNode[], parent: TreeNode) {
    for (const node of treeNode) {
        if (parent != null) {
            node.data.parent = parent;
        }
        if (node.children && node.children.length > 0) {
            this._processTreeNodes(node.children, node);
        }
     }
  }

  nodeSelect(event) {
    console.log(this.selectedNode);
    const data = this.selectedNode.data;
    if (data.type == Group) {
      this.selectedGroup = data.object;
      this.selectedPole = null;
      this.selectedDivision = null;
    } else if (data.type == Pole) {
      this.selectedPole = data.object;
      this.selectedDivision = null;
      this.selectedGroup = data.object.group;
    } else if (data.type == Division) {
      this.selectedDivision = data.object;
      this.selectedPole = data.object.pole;
      this.selectedGroup = data.object.pole.group;
      
      this.editDivisionGroup = data.object.pole.group;
      this.editDivisionPoles = this.poles.filter(pole => pole.group_id === this.editDivisionGroup.id);
    }
  }

  nodeUnselect(event) {
  }

  removeSelectedNode() {
    const data = this.selectedNode.data;
    if (data.type == Group) {
      this.confirmDialogsService.show('Group delete', 'Would you like to delete selected group ?')
      .subscribe(res => {
        const result = res;
        if (res) {
          this.deleteGroup();
        }
      });
    } else if (data.type == Pole) {
      this.confirmDialogsService.show('Pole delete', 'Would you like to delete selected pole ?')
      .subscribe(res => {
        const result = res;
        if (res) {
          this.deletePole();
        }
      });
    } else if (data.type == Division) {
      this.confirmDialogsService.show('Division delete', 'Would you like to delete selected division ?')
      .subscribe(res => {
        const result = res;
        if (res) {
          this.deleteDivision();
        }
      });
    }
  }

  editSelectedNode(){
    const data = this.selectedNode.data;
    if (data.type == Group) {
      this.newGroup = false;
      this.group = this.cloneGroup(data.object);
      // convert GDomain objects to list of strings
      this.gdomains = this.group.gdomains.map(item => item.gdomain);
      this.groupDisplayDialog = true;
    } else if (data.type == Pole) {
      this.newPole = false;
      this.pole = this.clonePole(data.object);
      this.selectedGroup = this.pole.group;
      this.poleDisplayDialog = true;
    } else if (data.type == Division) {
      this.newDivision = false;
      this.division = this.cloneDivision(data.object);
      this.selectedPole = this.polesMap.get(this.division.pole.id);
      this.selectedGroup = this.selectedPole.group;
      // this.divisionDisplayDialog = true;
      this.editDivisionDisplayDialog = true;
    }
  }

  loadAllDivisions() {
    this.divisionService.getAllDivisions().subscribe(data => this.divisions = (data.divisions) ? data.divisions : []);
  }
  loadAllPoles() {
    this.poleService.getAllPoles().subscribe(data => this.poles = (data.poles) ? data.poles : []);
  }
  loadAllGroups() {
    this.groupService.getAllGroups().subscribe(data => this.groups = (data.groups) ? data.groups: []);
  }
  showDivisionDialogToAdd() {
    if(!this.poles || this.poles.length == 0) {
      this.growlService.showInfoText('Please create pole first!')
      return;
    }
    if (!this.selectedPole) {
      this.selectedPole = this.poles[0];
    }
    this.selectedGroup = null;
    this.newDivision = true;
    this.division = new Division();
    this.divisionDisplayDialog = true;
  }
  showPoleDialogToAdd() {
    if(!this.groups || this.groups.length == 0) {
      this.growlService.showInfoText('Please create group first!')
      return;
    }
    this.selectedGroup = this.groups[0];
    this.newPole = true;
    this.gdomains = [];
    this.pole = new Pole(0);
    this.poleDisplayDialog = true;
  }
  showGroupDialogToAdd() {
    this.newGroup = true;
    this.group = new Group(0);
    this.groupDisplayDialog = true;
  }

  /**
   * Division
   */
  saveDivision() {
    const divisions = [...this.divisions];
    this.division.pole_id = this.selectedPole.id;
    if (this.newDivision) { // NEW
      this.divisionService.createDivision(this.division).subscribe(
        (data: any) => {
          divisions.push( data.division);
          this.divisions = divisions;
          this.division = null;
          this.divisionDisplayDialog = false;

          let parentNode = null;
          for (let i = 0; i < this.trees[0].children.length; i++) {
            if (this.trees[0].children[i].data.object.id == data.division.pole.group_id){
              parentNode = this.trees[0].children[i];
              break;
            }
          }
          for (let i = 0; i < parentNode.children.length; i++) {
            if (parentNode.children[i].data.object.id == data.division.pole_id){
              parentNode = parentNode.children[i];
              break;
            }
          }
          parentNode.children.push({
            'label': data.division.name_en,
            'selectable': true,
            'children': [],
            'expanded': false,
            'parent': parentNode,
            'data': {'object': data.division, 'type': Division, parent: parentNode},
          })
        },
        (error_data) => {
          this.growlService.showError(error_data.error.msg_const);
          this.division = null;
          this.divisionDisplayDialog = false;
        }
      );
    } else { // EDIT
      this.divisionService.updateDivision(this.division).subscribe(
        (data: any) => {
          // change the edited division inside the list of all divisions (instead of fetching the list again)
          divisions[this.divisions.indexOf(this.selectedDivision)] = data.division;
          // in case the Pole was changed the selectedNode must be null
          if (data.division.pole_id != this.selectedDivision.pole_id) {
            this.deleteSelectedNode();
            const parentPoleNode = this.selectedNode.data.parent;
            const parentGroupNode = parentPoleNode.data.parent;
            this.selectedNode = null;
            let newDivisionPoleParentNode = null;
            parentGroupNode.children.forEach( (poleChildNode) => { 
              if(data.division.pole_id == poleChildNode.data.object.id) {
                newDivisionPoleParentNode = poleChildNode;
              }
            });
            console.log(newDivisionPoleParentNode);
            this.selectedNode = {
              'label': data.division.name_en,
              'selectable': true,
              'children': [],
              'expanded': false,
              'parent': newDivisionPoleParentNode,
              'data': {'object': data.division, 'type': Division, parent: newDivisionPoleParentNode},
            }
            this.selectedDivision = data.division;
            newDivisionPoleParentNode.children.push(this.selectedNode);
          } else {
            this.selectedNode.label = this.division.name_en;
            this.selectedNode.data.object = this.division;
          }
          this.divisions = divisions;
          this.division = null;
          this.divisionDisplayDialog = false;
          this.editDivisionDisplayDialog = false;
          this.growlService.showInfoText('Division was updated.');
        },
        (error_data) => {
          this.growlService.showError(error_data.error.msg_const);
          this.division = null;
          this.divisionDisplayDialog = false;
          this.editDivisionDisplayDialog = false;
        }
      );
    }
  }

  /**
   * Pole
   */
  savePole() {
    const poles = [...this.poles];
    this.pole.group_id = this.selectedGroup.id;
    if (this.newPole) { // NEW
      this.poleService.createPole(this.pole).subscribe(
        (data: any) => {
          poles.push(data.pole);
          this.poles = poles;
          this.pole = null;
          this.poleDisplayDialog = false;
          let parentNode = null;
          for (let i = 0; i < this.trees[0].children.length; i++) {
            if (this.trees[0].children[i].data.object.id == data.pole.group_id){
              parentNode = this.trees[0].children[i];
            }
          }
          parentNode.children.push({
            'label': data.pole.name_en, 
            'selectable': true,
            'children': [],
            'expanded': false,
            'parent': parentNode,
            'data': {'object': data.pole, 'type': Pole, parent: parentNode},
          });
        },
        (error_data) => {
          this.growlService.showError(error_data.error.msg_const);
          this.growlService.showError(error_data.error.etext);
          this.pole = null;
          this.poleDisplayDialog = false;
        }
      );
    } else { // EDIT
      this.poleService.updatePole(this.pole).subscribe(
        (data: any) => {
          poles[this.poles.indexOf(this.selectedPole)] = data.pole;
          this.selectedNode.label = this.pole.name_en;
          this.selectedNode.data.object = this.pole;
          this.poles = poles;
          this.pole = null;
          this.poleDisplayDialog = false;
          this.growlService.showInfoText('Pole was updated.');
        },
        (error_data) => {
          this.growlService.showError(error_data.error.msg_const);
          this.pole = null;
          this.poleDisplayDialog = false;
        }
      );
    }
  }

  /**
   * Group
   */
  saveGroup() {
    const groups = [...this.groups];
    this.group.gdomains = this.gdomains.map(function(item) {return {id: 0, gdomain: item};} );
    if (this.newGroup) { // NEW
      this.groupService.createGroup(this.group).subscribe(
        (data: any) => {
          groups.push( data.group);
          this.groups = groups;
          this.group = null;
          this.groupDisplayDialog = false;
          this.trees[0].children.push({
            'label': data.group.name_en, 
            'selectable': true,
            'children': [],
            'expanded': false,
            'parent': this.trees[0],
            'data': {'object': data.group, 'type': Group, parent: this.trees[0]},
          })
        },
        (error_data) => {
          this.growlService.showError(error_data.error.msg_const);
          this.group = null;
          this.groupDisplayDialog = false;
        }
      );
    } else { // EDIT
      this.groupService.updateGroup(this.group).subscribe(
        (data: any) => {
          groups[this.groups.indexOf(this.selectedGroup)] = data.group;
          this.selectedNode.label = this.group.name_en;
          this.selectedNode.data.object = this.group;
          this.groups = groups;
          this.group = null;
          this.groupDisplayDialog = false;
          this.growlService.showInfoText('Group was updated.');
        },
        (error_data) => {
          this.growlService.showError(error_data.error.msg_const);
          this.group = null;
          this.groupDisplayDialog = false;
        }
      );
    }
  }

  /**
   * delete division
   */
  deleteDivision() {
    this.divisionService.deleteDivision(this.selectedDivision.id).subscribe(
      (data) => {
        this.growlService.showInfo('Division is deleted.');
        const index = this.divisions.indexOf(this.selectedDivision);
        this.divisions = this.divisions.filter((val, i) => i !== index);
        this.division = null;
        this.divisionDisplayDialog = false;
        this.deleteSelectedNode();
      },
      (error_data) => {
        this.growlService.showError(error_data.error.msg_const);
        this.division = null;
        this.divisionDisplayDialog = false;
      }
    );
  }

  /**
   * delete pole
   */
  deletePole() {
    this.poleService.deletePole(this.selectedPole.id).subscribe(
      (data) => {
        this.growlService.showInfo('Pole is deleted.');
        const index = this.poles.indexOf(this.selectedPole);
        this.poles = this.poles.filter((val, i) => i !== index);
        this.pole = null;
        this.poleDisplayDialog = false;
        this.deleteSelectedNode();
      },
      (error_data) => {
        this.growlService.showError(error_data.error.msg_const);
        this.pole = null;
        this.poleDisplayDialog = false;
      }
    );
  }

  /**
   * delete group
   */
  deleteGroup() {
    this.groupService.deleteGroup(this.selectedGroup.id).subscribe(
      (data) => {
        this.growlService.showInfo('Group is deleted.');
        const index = this.groups.indexOf(this.selectedGroup);
        this.groups = this.groups.filter((val, i) => i !== index);
        this.group = null;
        this.groupDisplayDialog = false;
        this.deleteSelectedNode();
      },
      (error_data) => {
        this.growlService.showError(error_data.error.msg_const);
        this.group = null;
        this.groupDisplayDialog = false;
      }
    );
  }

  deleteSelectedNode(){
    const parentNode = this.selectedNode.data.parent;
    if (parentNode.children) {
      parentNode.children.forEach(c => {
        if(c.data.object == this.selectedNode.data.object) {
          parentNode.children.splice(parentNode.children.indexOf(c), 1); 
        }
      });
    }
  }

  cloneDivision(b: Division): Division {
    const divisions = new Division();
    Object.keys(b).forEach(prop => divisions[prop] = b[prop]);
    return divisions;
  }
  clonePole(c: Pole): Pole {
    const pole = new Pole(0);
    Object.keys(c).forEach(prop => pole[prop] = c[prop]);
    return pole;
  }
  cloneGroup(t: Group): Group {
    const group = new Group(0);
    Object.keys(t).forEach(prop => group[prop] = t[prop]);
    return group;
  }

  addGDomain(){
    this.gdomains.push('');
    console.log(this.gdomains);
  }

  removeGDomain(index) {
    console.log(this.gdomains);
    console.log('Intex:' + index);
    this.gdomains.splice(index, 1);
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  downloadEntitiesCSV(){
    this.groupService.downloadUsersCSV().subscribe(
      (data) => {
        openFileForDownload(data);
      },
      (error_data: any) => { this.growlService.showError(error_data.error.msg_const); }
    );
  }

  exportDomainsList(){
    // console.log('Download domains list');
    // const doc = new jsPDF('p', 'mm', [210,279]);
    // let head = ['Domains list for "' + this.group.name_en + '"'];
    // let body = [];
    // let row = [];
    // for (let index = 0; index < this.gdomains.length; index++) {
    //   const domain = this.gdomains[index];
    //   row.push(domain);
    //   body.push(row);
    //   row = [];
    // }
    // (doc as any).autoTable({
    //   head: [head],
    //   body: body,
    // });
    // doc.save('Domains list.pdf');
    this.exportDomainsListCSV();
  }

  exportDomainsListCSV() {
    let csvArray = this.gdomains.join('\r\n');
    var blob = new Blob([csvArray], {type: 'text/csv' })
    saveAs(blob, "Domains list.csv");
}

}
