import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Component, Inject, ForwardRefFn, forwardRef } from '@angular/core';

import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
// the reactive forms module is used for the reactive driven approach
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Router } from '@angular/router';
import { AppRoutingModule, routedComponents } from './module_routing/routing.module';
import { AuthService } from './_services/auth.service';
import { AuthGuard } from './_services/auth-guard.service';
// interceptors are implemented into the core module because are generally used in every app

import { AppComponent } from './app.component';
// MODULES
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // needed for the material module

import { NgxPermissionsModule } from 'ngx-permissions';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import Material from '@primeng/themes/material';
import { providePrimeNG } from 'primeng/config';
import Aura from '@primeng/themes/aura';
import Lara  from '@primeng/themes/lara';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

// MY MODULES
// import { SimpleSpeedDialModule } from './_simple_speed_dial/simple_speed_dial.module'
import { CoreModule } from './_module_core/core.module';
import { DialogsModule } from './_module_dialog/dialog.module';

import { MyMaterialModule } from './_module_my_material/material.module';
// imports for translator
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

// COMPONENTS
import { AlertComponent } from './_module_dialog/alert/alert.component';
import { HomeComponent } from './home/home.component';
// MAIN SERVICES
import { AppConfig } from './app.config';
// COMPONENT SERVICES
import { AlertService } from './_module_dialog/alert/alert.service';
import { MyPermissionsService } from './_services/myx-permissions.service';
// RESOLVERS
import { CountriesResolve } from './models_resolvers/countries.resolve';

import { ZipService } from './utils/jszip_service';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AccordionModule} from 'primeng/accordion';
import { MultiSelectModule } from 'primeng/multiselect';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { FileUploadModule } from 'primeng/fileupload';
import { CalendarModule} from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { ProgressBarModule } from 'primeng/progressbar';
import { PanelModule } from 'primeng/panel';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { TabsModule } from 'primeng/tabs';

import { TableModule } from 'primeng/table';  // replacement of DataTableModule
import { ToastModule } from 'primeng/toast';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TreeModule } from 'primeng/tree';
import { } from 'primeng/';

import { GoogleMapsModule } from '@angular/google-maps';
import { LightboxModule } from 'ngx-lightbox';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MessageService } from 'primeng/api';



// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
// export function createTranslateLoader(http: HttpClient) {
//         return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// }

@NgModule({ declarations: [
        AppComponent, routedComponents,
        AlertComponent, HomeComponent,
    ],
    bootstrap: [AppComponent], 
    imports: [
      BrowserModule, FormsModule, BrowserAnimationsModule,
        ReactiveFormsModule,
        FlexLayoutModule, LightboxModule, GoogleMapsModule,
        FontAwesomeModule,
        // primeng modules
        // CalendarModule, GalleriaModule, LightboxModule, AutoCompleteModule,
        AccordionModule, MultiSelectModule, CalendarModule, TableModule,
        TabsModule, ScrollPanelModule, FileUploadModule, CardModule,
        DropdownModule, DialogModule, ProgressBarModule,
        TooltipModule, ButtonModule, PanelModule, ChartModule, EditorModule,
        CheckboxModule, RadioButtonModule, OverlayPanelModule, InputSwitchModule,
        TreeModule,
        MyMaterialModule, MatFormFieldModule, MatInputModule, MatMenuModule,
        // SimpleSpeedDialModule.forRoot(), 
        CoreModule, AppRoutingModule, DialogsModule, NgxPermissionsModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })], providers: [
          MessageService,
          AuthService, AuthGuard, AlertService, AppConfig,
          MyPermissionsService,
          // Resolvers
          CountriesResolve,
          // Dialogs
          // util services
          ZipService,
          provideHttpClient(withInterceptorsFromDi()),

          provideAnimationsAsync(),
          providePrimeNG({
              theme: {
                  preset: Lara
              }
          }), 
          
        ] })
export class AppModule {
}
