
  <div class="row">
    <div class="medium-8 medium-offset-2 columns">
      <h2 class="subheader"></h2>
      
      <p-table #dt [value]="asyncAdminLogs | async"
      [paginator]="true" [rowsPerPageOptions]="[25, 50, 100, 250, 500]" paginatorPosition="both"
      [rows]="25" autoLayout="true" 
      [lazy]="true" currentPageReportTemplate="Showing {count} entries" (onLazyLoad)="loadTransactionsLazy($event)" 
        scrollable="true" 
        [totalRecords]="count">
        
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
              <td [attr.colspan]="12"> No records found </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>Action ID</th>
            <th>Admin ID</th>
            <th>Firstname</th>
            <th>Surname</th>
            <th>Email</th>
            <th>Date created</th>
            <th>IP Address</th>
            <th>HTTP Method</th>
            <th>Path</th>
            <th>Endpoint</th>
            <th>Json info</th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th>
              <input pInputText type="text" (input)="dt.filter($event.target.value, 'firstname', 'equals')">
            </th>
            <th>
              <input pInputText type="text" (input)="dt.filter($event.target.value, 'lastname', 'equals')">
            </th>
            <th>
              <input pInputText type="text" (input)="dt.filter($event.target.value, 'email', 'equals')">
            </th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">
            <td>
              <ng-template pHeader>Action ID</ng-template>
                {{ rowData.id }}
            </td>
            <td>
              <ng-template pHeader>Admin ID</ng-template>
                {{ rowData.admin_id }}
            </td>
            <td>
              <ng-template pHeader>Firstname</ng-template>
                {{ rowData.firstname }}
            </td>
            <td>
              <ng-template pHeader>Surname</ng-template>
                {{ rowData.lastname }}
            </td>
            <td>
              <ng-template pHeader>Email</ng-template>
                {{ rowData.email }}
            </td>
            <td>
              <ng-template pHeader>Date created</ng-template>
                {{ rowData.date_created }}
            </td>
            <td>
              <ng-template pHeader>IP Address</ng-template>
                {{ rowData.ip_address }}
            </td>
            <td>
              <ng-template pHeader>HTTP Method</ng-template>
                {{ rowData.method }}
            </td>
            <td>
              <ng-template pHeader>Path</ng-template>
                {{ rowData.path }}
            </td>
            <td>
              <ng-template pHeader>Args</ng-template>
                {{ rowData.endpoint }}
            </td>
            <td>
              <ng-template pHeader>Json info</ng-template>
              <button mat-icon-button pTooltip="Show alerted users" 
                (click)="showAdminLogInfo(rowData)">
                <i class="fa fa-info-circle" style="font-size: 24px;"></i>
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>



<p-dialog header="Json info" [(visible)]="displayAdminLogJSONInfoDialog" >

  <div [ngSwitch]="true" *ngIf="selectedAdminLog">
    <div>
      <h3>Request JSON:</h3>
      <div>
        <pre>
          {{ selectedAdminLog.json_data | json}}
        </pre>
      </div>
    </div>
  </div>
</p-dialog>
