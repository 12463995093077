import { Component, OnInit } from '@angular/core';
import { BackendUser } from '../../models/backend-user';
import { BackendUserService } from '../../models_services/backend_user.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogsService } from '../../_module_dialog/confirm_dialog/confirm-dialog.service';
import { Router } from '@angular/router';

import { AppConfig } from '../../app.config';
import { GrowlService } from '../../_module_dialog/growl_dialog/growl.service';
import { LazyLoadEvent } from 'primeng/api';
import { Observable, pipe, combineLatest, concat, of } from 'rxjs';
import { tap, map, bufferCount } from 'rxjs/operators';

@Component({
    selector: 'app-backend-users',
    templateUrl: 'backend-users.component.html',
    styles: [],
    standalone: false
})
export class BackendUsersComponent implements OnInit {
  current_user: BackendUser;
  asyncUsers: Observable<BackendUser[]>;
  count: 0;
  currentPage = 1;
  loading: boolean;

  // server side filter
  sortProperties: any[];
  sortBy: string;
  orderAsc: string;
  params = {
    page: 1, per_page: 10, order: '-date_created',
    'email': { operator: '%', value: '' },
    'firstname': { operator: '%', value: '' },
    'lastname': { operator: '%', value: '' }
  };

  constructor(private userService: BackendUserService,
    private config: AppConfig,
    private confirmDService: ConfirmDialogsService,
    private router: Router,
    private growlService: GrowlService) {
    this.current_user = JSON.parse(localStorage.getItem('current_user'));
  }
  ngOnInit() {
    this.sortProperties = [
      { field: 'id', name: 'ID' },
      { field: 'email', name: 'Email' },
      { field: 'firstname', name: 'Firstname' },
      { field: 'lastname', name: 'Lastname' }
    ];
    this.sortBy = 'id';
    this.orderAsc = '';
    this.loadAllUsers();
  }
  private loadAllUsers() {
    this.getPage(1);
  }

  getPage(page: number) {
    this.params.page = page;
    this.asyncUsers = this.userService.getAllWithParams(this.params).pipe(
      tap((data: any) => {
        this.count = data.count;
        this.loading = false;
        this.currentPage = page;
      }),
      map(data => {
        return data.users;
      })
    )
  }

  loadTransactionsLazy(event: LazyLoadEvent) {
    this.params.email.value = event.filters['email'] ? event.filters['email'].value : '';
    this.params.firstname.value = event.filters['firstname'] ? event.filters['firstname'].value : '';
    this.params.lastname.value = event.filters['lastname'] ? event.filters['lastname'].value : '';
    if (event.sortField) {
      this.params.order = ((event.sortOrder > 0) ? '-' : '') + event.sortField;
    }
    console.log(this.params.email);
    console.log(this.params.firstname);
    console.log(this.params.lastname);
    console.log(event.rows);
    console.log(event.first);
    console.log(event.first / event.rows);
    this.params.per_page = event.rows;
    this.getPage((event.first / event.rows) + 1);
  }

  customSort(event) {
    console.log('Custom sort');
    console.log(event);
  }

  // CRUD methods
  removeItem(user: BackendUser) {
    // call confirmation dialog
    this.confirmDService.show('User delete', 'Would you like to delete this user ?')
      .subscribe(res => {
        const result = res;
        if (res) {
          console.log('deleting user confirmed: ' + JSON.stringify(user));
          this.userService.delete(user.id).subscribe(
            () => {
              this.loadAllUsers();
              this.growlService.showInfo('User is deleted.');
            },
            (error_data: any) => { this.growlService.showError(error_data.error.msg_const); });
        }
      });
  }
  addItem() {
    console.log('addItem');
    this.router.navigate(['/home', 'backend_user', 'new']);
  }

  editItem(editedUser: BackendUser) {
    console.log('editItem: ' + editedUser.id + ' ' + JSON.stringify(editedUser));
    console.log(editedUser);
    this.router.navigate(['/home', 'backend_user', editedUser.id, 'edit']);
  }
}
